import { useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { PageProps } from "src/runtime/pages";
import StaticWithBlock from "../layouts/StaticWithBlock.react";
import AuthenticationStepper from './Authentication.react';

export default function AuthenticationPage({ meta }: PageProps) {
    let match = useRouteMatch();
    const sourceURL = useRef(meta.redirect_source);
    return (
        <StaticWithBlock>
            <AuthenticationStepper step={ match.path.replace("/", "") } source_url={ sourceURL.current } />
        </StaticWithBlock>
    )
}