import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { ResultWithError } from "../errors/errors";
import { put } from "../rest/api";
import { SessionRaw, SessionRuntime } from "./model";

export type SignInRequest = {
    email:      string
    password:   string
}

export async function SignInAPI(body:SignInRequest):Promise<ResultWithError<SessionRaw>> {
    const res = await put({url: "session", body });
    
    return CheckResultRuntime(SessionRuntime, res);
}