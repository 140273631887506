import { css, CreateScheet } from "aphrodite";
import { GetQueryParams } from "packages/history/query_string";
import Spinner from "packages/spinners/spinner.react";
import { Fragment } from "react";
import { Primary } from "src/themes/colors";
import { Purple } from "src/themes/spinner";

export default function Pending() {
    const [ error ] = GetQueryParams(["error"])
    
    return(
        <article className={css(Styles.main)}>
            {error === null ? 
                <Fragment>
                    <div className={css(Styles.label)}>Loading</div>
                    <Spinner {...{
                        theme: Purple,
                        height: 150,
                    }}/>
                </Fragment>
            : 
                <div className={css(Styles.label)}>Somethings is went wrong. Please try again later</div>
            }
            
        </article>
    );
} 

const Styles = CreateScheet({
    main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: 50,
    },
    label: {
        fontSize: 20,
        fontWeight: "bold",
        color: Primary.default,
    }
})