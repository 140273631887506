import * as r from "runtypes";

export const NotificationBaseRuntype = r.Record({
    id:         r.Number,
    created_on: r.Number,
    is_read:    r.Boolean,
})






