import { CreateScheet, css } from "aphrodite";
import moment from 'moment';
import { MarkAsRead } from "packages/feed/API/MarkAsRead";
import { Notification } from "packages/feed/types";
import { CreateAnimation } from "packages/motion/CreateAnimation";
import { session } from "packages/session/session";
import Tooltip from "packages/tooltip/Tootip.react";
import { useEffect, useRef, useState } from "react";
import { Primary } from "src/themes/colors";
import { DarkTooltip } from "src/themes/tooltip";

export default function NotificationContainer({
    image,
    children,
    rest,
    notification,
}:React.PropsWithChildren<{
    image?:       React.ReactNode,
    rest?:        React.ReactNode,
    notification: Notification,
}>) {
    const RerenderState = useState(false)
    const SetRerender = RerenderState[1]

    const Notification = useRef<Notification>(notification)
    function onMouseEnter() {
        if (!Notification.current.is_read) {
            MarkAsRead(Notification.current.id)
            Notification.current.is_read = true
            session.ChangeUnreadNotificationsCount(session.extra.unread_notifications_count - 1)
        }
    }

    const UnreadCount = session.extra.unread_notifications_count;
    useEffect(() => {
        if (UnreadCount === 0 && !Notification.current.is_read) {
            Notification.current.is_read = true
            SetRerender(r => !r)
        }
    }, [ UnreadCount, SetRerender ])
    
    return (
        <div className={css(Styles.wrapper)} onMouseEnter={onMouseEnter}>
            <div className={css(Styles.notification)}>
                {!Notification.current.is_read && 
                    <aside className={css(Styles.indicator, CreateAnimation(["pulsate"], {
                        animationDuration: 500,
                        animationIterationCount: "infinite",
                    }))}/>
                }
                <div className={css(Styles.header)}>
                    {image !== undefined && <span className={css(Styles.image)}>{image}</span>}
                    <span className={css(Styles.title)}>{children}</span>
                    <Tooltip {...{
                        theme: DarkTooltip,
                        content: moment.unix(Notification.current.created_on).format("h:mm A DD MMM, YYYY")
                    }}>
                        <span className={css(Styles.time)}>
                            {moment.unix(Notification.current.created_on).fromNow()}
                        </span>
                    </Tooltip>
                </div>
                <div className={css(Styles.rest)}>
                    {rest}
                </div>
            </div>
        </div>
    );
}

const Styles = CreateScheet({
    wrapper: {
        paddingBottom: 10,
        paddingRight: 15,
        paddingLeft: 15,
    },
    notification: {
        position:     "relative",
        background:   "#F8F8FD",
        borderRadius: 6,
        padding:      "20px 15px 15px 15px",
        border:       "2px solid transparent",
        transition:   "border-color 0.1s ease-in-out",
        cursor:       "pointer",
        ":hover":     {
            borderColor: Primary.default,
        }
    },
    header: {
        display:    "flex",
        alignItems: "flex-start",
        fontSize:   14,
    },
    image: {
        width:      40,
        height:     40,
        flexShrink: 0,
        marginRight: 10,
    },
    title: {
        flexGrow: 1,
        fontWeight: "bold",
        marginRight: 10,
    },
    time: {
        position: 'absolute',
        top: 4,
        right: 5,
        fontSize: 12,
        color: "#A5A4C0",
    },
    rest: {
        marginTop: 10,
        fontSize: 14,
    },
    indicator: {
        width:        8,
        height:       8,
        borderRadius: "100%",
        position:     "absolute",
        left:         3,
        top:          3,
        background:   Primary.default,
    }
})