import { ErrorData, ResultWithError } from "packages/errors/errors";
import { useReducer, useState, Reducer, useEffect, useRef } from "react";

interface State<R, E> {
    executionCount: number
	isLoading:      boolean
    isReLoading:	boolean
    error:          ErrorData<E> | null
    payload:        R | null
}

interface Action<R, E> {
    type:       string,
    error?:     ErrorData<E> | null
    payload?:   R | null
}

function APIHookWithFunctionReducer<R, E>(state:State<R, E>, action:Action<R, E>):State<R, E> {
	switch (action.type) {
		case 'start':
			return {
				payload:        state.payload,
                executionCount: state.executionCount || 0,
                isReLoading:    state.isReLoading,
				isLoading:      true,
				error:          null,
			};
		
		case 'reload':
			return {
                payload:        state.payload,
                executionCount: 0,
				isReLoading:    true,
				isLoading:      true,
				error:          null,
			};

		case 'success':
            return {
                payload:        action.payload || null,
                executionCount: state.executionCount + 1,
                isReLoading:    true,
                isLoading:      false,
                error:          null,
            };

		case 'error':
			return {
				payload:        action.payload || null,
				executionCount: state.executionCount + 1,
				isReLoading:    false,
				isLoading:      false,
				error:          action.error || null,
			};

		default:
			throw new Error();
	}
}

type func<R, E, P extends any[]> = (...args: P) => Promise<ResultWithError<R, E>>

function useAPI<R, E, P extends any[]>(func: func<R, E, P>, initLoad: boolean, ...initialParameters: P):[State<R, E>, (...args: P) => void] {
    const rq = useRef(func);
    const initialCall = useRef<boolean>(true);

    rq.current = func;

	const [ Parameters, SetParameters ] = useState(initialParameters);
	const [ Response, dispatch ] = useReducer<Reducer<State<R, E>, Action<R, E>>>(
		APIHookWithFunctionReducer, 
		{
            payload:        null,
            executionCount: 0,
            isReLoading:    false,
            isLoading:      true,
            error:          null,
		}
	);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'start' });

			const result = await rq.current(...Parameters);
			if (result[0] !== null) {
				dispatch({ type: 'success', payload: result[0] });
			} else {
				dispatch({ type: 'error', payload: result[0], error: result[1] });
			}
        };
        
        if (initialCall.current && !initLoad) {
            initialCall.current = false;
            return
        }

		fetchData();
	}, [ Parameters, initLoad ]);

	const executeFunction = useRef((...args: P) => {
		SetParameters(args);
	});

	return [ Response, executeFunction.current ];
}

export function makeAPIHook<R, E, P extends any[]>(func: func<R, E, P>) {
    return function(initLoad:boolean, ...args: P) {
        return useAPI(func, initLoad, ...args)
    }
}