import { Fragment, useState } from "react";
import { history, OpenPage } from "packages/history/history";
import { TopRightLine } from "../layouts/Backgrounds.react";
import Cross from "./components/Cross.react";
import { useLoadProjectInviteAPI } from "packages/projects/LoadProjectInviteAPI";
import { FormStyles } from "src/themes/form";
import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import WithSpinner from "packages/spinners/SmartSpinner.react";
import { Purple } from "src/themes/spinner";
import { SmoothSteper, SmoothSteperItem } from "packages/motion/SmoothStepper.react";
import { OnboardingTheme } from './Onboarding.react';
import { MakeInput } from "src/themes/input";
import { LoadButton } from "src/themes/button";
import SignInReact from "../authentication/modules/SignIn.react";
import SmoothLine from "packages/motion/SmoothLine.react";
import ConfirmInviteAPI from 'packages/projects/ConfirmInviteAPI';
import { HiddenSignIn } from "packages/session/session";

export default function Invites({ guid, token, theme }:{ guid: string, token: string, theme: OnboardingTheme}) {
    const [ response, reload  ] = useLoadProjectInviteAPI(true, guid, token);
    const [ Password, SetPassowrd ] = useState("");
    const [ FullName, SetFullName ] = useState("");
    const [ Loading, SetLoading ] = useState(false);
    const [ Err, SetErr ] = useState<string | undefined>(undefined);

    function OnSignIn() {
        reload(guid, token);
    }

    async function OnSubmitCreate(e: React.FormEvent) {
        e.preventDefault();
        e.stopPropagation();

        if (Password.length < 5) {
            SetErr("Minimum password length is 6 symbols")
        } else {
            SetLoading(true);
            const err = await ConfirmInviteAPI(guid , token, { password: Password, full_name: FullName });
            if (err !== null) {
                SetErr(err.text);
                SetLoading(false);
            } else {
                await HiddenSignIn();
                history.push(`/projects/${guid}`);
                SetLoading(false);
            }
        }
    }

    async function OnSubmitConfirm(e: React.FormEvent) {
        e.preventDefault();
        e.stopPropagation();

        SetLoading(true);
        const err = await ConfirmInviteAPI(guid , token);
        SetLoading(false);
        if (err !== null) {
            SetErr(err.text);
        } else {
            history.push(`/projects/${guid}`);
        }
    }

    let ActiveStep = -1;
    if (response.payload) {
        if (response.payload.policies.must_verify_user) {
            ActiveStep = 0;
        } else if (response.payload.policies.must_sign_in) {
            ActiveStep = 1;
        } else if (response.payload.policies.can_confirm_client_invite) {
            ActiveStep = 2;
        } else if (response.payload.policies.can_confirm_developer_invite) {
            ActiveStep = 2;
        }
    }

    return (
        <Fragment>
            <Cross onClick={ OpenPage("") } />
            <TopRightLine />
            <section className={ css(FormStyles.body, Styles.body) }>
                <h1 className={ css(FormStyles.title, Styles.title) }>Confirm invitation</h1>
                <WithSpinner {...{ theme: Purple, suspense: response.isLoading }} >
                    { response.payload && response.payload.policies.can_confirm_invite && ActiveStep >= 0 ? (
                        <SmoothSteper {...{ 
                            active:     ActiveStep,
                            duration:   300,
                            motion:     ["fade"],
                            grow:       true,
                            className:  css(Styles.form),
                        }}>
                            <SmoothSteperItem>
                                <form className={ css(FormStyles.form) } onSubmit={ OnSubmitCreate }>
                                    <div className={ css(FormStyles.note, Styles.note) }>
                                        Your were invited to join <strong>{ response.payload.title }</strong> project<br/>
                                        To confirm invitation please set up your password and provide full name.
                                    </div>
                                    <label className={ css(FormStyles.label) }>Email</label>
                                    <input {...{
                                        id:             "invites_email",
                                        name:           "invites_email",
                                        type:           "text",
                                        autoComplete:   "off",
                                        value:          response.payload.email,
                                        disabled:       true,
                                        className:      css(MakeInput(theme.input, {
                                            fontSize:       16,
                                            borderRadius:   6,
                                            padding:        "8px 12px",
                                            width:          "100%",
                                        }), Styles.input)
                                    }} />
                                    <SmoothLine className={ css(FormStyles.label, FormStyles.error) } motion={[ "fade", "swipe-right" ]}>
                                        { Err }
                                    </SmoothLine>
                                    <label className={ css(FormStyles.label) }>Password</label>
                                    <input {...{
                                        id:             "invites_pw",
                                        name:           "invites_pw",
                                        type:           "password",
                                        autoComplete:   "off",
                                        autoFocus:      true,
                                        value:          Password,
                                        onChange:       e => { SetPassowrd(e.target.value); SetErr(undefined); },
                                        className:      css(MakeInput(theme.input, {
                                            fontSize:       16,
                                            borderRadius:   6,
                                            padding:        "8px 12px",
                                            width:          "100%",
                                            error:          Boolean(Err),
                                        }), Styles.input)
                                    }} />
                                    <label className={ css(FormStyles.label) }>Full name</label>
                                    <input {...{
                                        id:             "invite_fn",
                                        name:           "invite_fn",
                                        type:           "text",
                                        autoComplete:   "off",
                                        value:          FullName,
                                        onChange:       e => SetFullName(e.target.value),
                                        className:      css(MakeInput(theme.input, {
                                            fontSize:       16,
                                            borderRadius:   6,
                                            padding:        "8px 12px",
                                            width:          "100%",
                                        }), Styles.input)
                                    }} />
                                    <LoadButton {...{
                                        type:       "submit",
                                        loading:    Loading,
                                        className:  css(Styles.submit),
                                        theme:      theme.action,
                                        layout:     {
                                            width:          "100%",
                                            fontSize:       16,
                                            borderRadius:   6,
                                            padding:        "10px 12px",
                                        },
                                    }}>
                                        Continue
                                    </LoadButton>
                                </form>
                            </SmoothSteperItem>
                            <SmoothSteperItem>
                                <div className={ css(FormStyles.note, Styles.note) }>
                                    Your were invited to join <strong>{ response.payload.title }</strong> project<br/>
                                    Please sign in to continue.
                                </div>
                                <SignInReact {...{ theme: theme.sign_in, OnSignIn, email: response.payload.email }} />
                            </SmoothSteperItem>
                            <SmoothSteperItem>
                                <form className={ css(FormStyles.form) } onSubmit={ OnSubmitConfirm }>
                                    <div className={ css(FormStyles.note, Styles.note) }>
                                        Your were invited to join <strong>{ response.payload.title }</strong> project<br/>
                                    </div>
                                    <LoadButton {...{
                                        type:       "submit",
                                        loading:    Loading,
                                        className:  css(Styles.submit),
                                        theme:      theme.action,
                                        layout:     {
                                            width:          "100%",
                                            fontSize:       16,
                                            borderRadius:   6,
                                            padding:        "10px 12px",
                                        },
                                    }}>
                                        Continue
                                    </LoadButton>
                                </form>
                            </SmoothSteperItem>
                        </SmoothSteper>
                    ) : (
                        <div className={ css(Styles.error) }>
                            Oops, looks like you can't perform this action. Please contact project administartor.
                        </div>
                    ) }
                </WithSpinner>
            </section>
        </Fragment>
    )
}

const Styles = CreateScheet({
    body: {
        flexGrow:       1,
    },
    title: {
        padding:        "0 20px",
    },
    form: {
        padding:        "0 20px 20px",
    },
    note: {
        marginBottom:   10,
    },
    input: {
        marginBottom:   15,
    },
    submit: {
        marginTop:      20,
    },
    error: {
        minHeight:      200,
        fontSize:       20,
        padding:        "20px 0",
    }
});