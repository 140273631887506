import { CreateScheet, css } from "aphrodite";
import { WorklogSubmittedNotification } from "packages/feed/worklogs";
import { SecondsToStrDuration } from "packages/helpers/Duration";
import { session } from "packages/session/session";
import { Primary } from "src/themes/colors";
import NotificationContainer from "../NotificaionContainer.react";

export default function WorklogSubmitted({
    notification
}:{
    notification: WorklogSubmittedNotification
}) {
    const ActiveProjectGUID = session.extra.active_project_guid;
    const content = notification.content;

    return (
        <NotificationContainer {...{
            notification,
            rest: (
                <div className={css(Styles.bottomLine)}>
                    {SecondsToStrDuration(content.worklog.tracked_time, session.GetActiveTeamTimeTrackingSettings(), true)} on&nbsp;
                    <a {...{
                        href:      `/projects/${ActiveProjectGUID}/tasks/${content.task.id}`,
                        target:    "_blank",
                        rel:       "noopener noreferrer",
                        className: css(Styles.taskLabel),
                    }}>
                        {content.task.title}
                    </a>
                </div>
            )
        }}>
            <span>
                {content.member.user_guid === session.user.guid ? "You" : content.member.name} submitted a worklog
            </span>
        </NotificationContainer>
    )
}

const Styles = CreateScheet({
    titleHref: {
        color: "black",
    },
    bottomLine: {
        display:    "flex",
        alignItems: "center",
        marginTop:  10,
        fontSize:   14,
    },
    taskLabel: {
        width:           100,
        height:          24,
        fontSize:        14,
        backgroundColor: `${Primary.active}2e`,
        border:          `1px solid ${Primary.active}`,
        borderRadius:    3,
        color:           Primary.active,
        fontWeight:      500,
        display:         "flex",
        justifyContent:  "center",
        alignItems:      "center",
        textDecoration:  "none",
        transition:      "box-shadow 0.1s ease-in-out",
        ":hover":        {
            boxShadow: 'rgba(94, 80, 238, 0.3) 0px 1px 2px 0px, rgba(94, 80, 238, 0.15) 0px 2px 6px 2px',
        }
    }
})