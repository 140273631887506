import * as r from "runtypes";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { Notification, NotificationRuntype } from "../types";

export async function LoadFeed(project_guid:string, before_id?:number) {
    const res = await get<{
        notifications: Notification[],
        has_more:      boolean,
    }>({
        url: `feed`,
        query: {
            page_size: 20,
            project_guid,
            before_id,
        },
    });

    return CheckResultRuntime(r.Record({
        notifications: r.Array(NotificationRuntype) ,
        has_more:      r.Boolean,
    }), res);
}