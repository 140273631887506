import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { ResultWithError } from "../errors/errors";
import { post } from "../rest/api";
import { SessionRaw, SessionRuntime } from "./model";

export type CreateUserRequest = {
    email:      string
    password:   string
    full_name:  string
}

export async function CreateUserAPI({ email, password, ...rest }:CreateUserRequest):Promise<ResultWithError<SessionRaw>> {
    const res = await post({url: "session", body: {
        new_email:      email,
        new_password:   password,
        ...rest,
    }});
    
    return CheckResultRuntime(SessionRuntime, res);
}