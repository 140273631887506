import { lazy, Suspense } from 'react';
import { css, CreateScheet } from 'aphrodite';
import { Meta, MetaRuntime } from './playground.model';

export { MetaRuntime };
export type { Meta };

const Playground = lazy(() => import('./playground.react'));

export default function LazyPlayground() {
    return (
        <Suspense {...{
            fallback: (
                <div className={ css(Styles.fallback) } />
            )
        }}>
            <Playground />
        </Suspense>
    )
}

const Styles = CreateScheet({
    fallback: {
        flexGrow: 1,
        display: "flex",
    }
})