import * as r from "runtypes";
import { NotificationBaseRuntype } from "./models";
import { UserObjectRuntype } from "./users";

export const InvoiceObectRuntype = r.Record({
    id:          r.Number,
    guid:        r.String,
})
export type InvoiceObject = r.Static<typeof InvoiceObectRuntype>


export const InvoiceCreatedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("invoice_created"),
    content: r.Record({
        invoice: InvoiceObectRuntype,
        user:    UserObjectRuntype,
    }) 
})
export type InvoiceCreatedNotification = r.Static<typeof InvoiceCreatedNotificationRuntype>;

export const InvoiceChangedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("invoice_changed"),
    content: r.Record({
        invoice: InvoiceObectRuntype,
        user:    UserObjectRuntype,
    }) 
})
export type InvoiceChangedNotification = r.Static<typeof InvoiceChangedNotificationRuntype>;

export const InvoiceDeletedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("invoice_deleted"),
    content: r.Record({
        invoice: InvoiceObectRuntype,
        user:    UserObjectRuntype,
    }) 
})
export type InvoiceDeletedNotification = r.Static<typeof InvoiceDeletedNotificationRuntype>;
