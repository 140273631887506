import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { ProjectInviteRuntime, ProjectInvitePoliciesRuntime } from "./model";
import * as runtypes from 'runtypes';

const LoadProjectInviteResponse = runtypes.Record({
    ...ProjectInviteRuntime.fields,
    policies:       ProjectInvitePoliciesRuntime,
});

export type LoadProjectResponse = runtypes.Static<typeof LoadProjectInviteResponse>;

export async function LoadProjectInviteAPI(guid: string, token: string):Promise<ResultWithError<LoadProjectResponse>> {
    const res = await get({ url: `projects/${guid}/invites/${token}` });
    return CheckResultRuntime(LoadProjectInviteResponse, res);
}

export const useLoadProjectInviteAPI = makeAPIHook(LoadProjectInviteAPI);