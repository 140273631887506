import * as runtypes from 'runtypes';
import CreatePolicy from 'packages/policies/CreatePolicy';
import { Project, ProjectTeam, ProjectTeamTimeTrackingSettings, ProjectWithPolicies, ProjectWithPoliciesRuntime } from 'packages/projects/model';
import moment from 'moment';

export const SessionUserRuntime = runtypes.Record({
    guid:                   runtypes.String,
    primary_email:          runtypes.String.Or(runtypes.Null),
    requested_email:        runtypes.String.Or(runtypes.Null),
    primary_phone:          runtypes.String.Or(runtypes.Null),
    requested_phone:        runtypes.String.Or(runtypes.Null),
    full_name:              runtypes.String,
});

export const SessionExtraRuntime = runtypes.Record({
    projects:                   runtypes.Array(ProjectWithPoliciesRuntime),
    active_project_guid:        runtypes.String,
    active_team_id:             runtypes.Number,
    selected_range_start:       runtypes.Number.Or(runtypes.Null),
    selected_range_end:         runtypes.Number.Or(runtypes.Null),
    unread_notifications_count: runtypes.Number,
    disapproved_worklogs_count: runtypes.Number,
});

export const SessionPoliceisRuntime = CreatePolicy(
    "can_sign_in", 
    "can_sign_up", 
    "can_sign_out", 
    "can_create_project", 
    "can_view_dashboard",
);

export const SessionRuntime = runtypes.Record({
    user:       SessionUserRuntime,
    extra:      SessionExtraRuntime,
    policies:   SessionPoliceisRuntime,
})

export type SessionUser = runtypes.Static<typeof SessionUserRuntime>
export type SessionExtra = runtypes.Static<typeof SessionExtraRuntime>
export type SessionPolicies = runtypes.Static<typeof SessionPoliceisRuntime>
export type SessionRaw = runtypes.Static<typeof SessionRuntime>
export type Session = runtypes.Static<typeof SessionRuntime> & {
    date_range:                        { start: moment.Moment, end: moment.Moment },
    SetDateRange:                      (start: moment.Moment, end: moment.Moment) => void,
    SetActiveProject:                  (guid: string, update_url: boolean) => Promise<void>,
    SetActiveTeam:                     (team_id: number) => Promise<void>,
    GetActiveProject:                  () => ProjectWithPolicies,
    GetActiveTeam:                     () => ProjectTeam | null,
    GetActiveTeamTimeTrackingSettings: () => ProjectTeamTimeTrackingSettings,
    GetActiveProjectIndex:             () => number,
    Reload:                            () => void,
    RemoveActiveProjectTeam:           (team_id: number) => void,
    AddTeamToActiveProject:            (team: ProjectTeam) => void,
    ChangeUnreadNotificationsCount:    (count:number) => void,
    ChangeDisapprovedWorklogsCount:    (count:number) => void,
    ChangeProjectData:                 (data:Partial<Project>) => void,
    UpdateUserFields:                  (data:Partial<SessionUser>) => void,
    ChangeTeamData:                    (id:number, data:Partial<ProjectTeam>) => void,
}