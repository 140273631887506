import { LogoTheme } from "src/jsx/logo/Logo.theme";
import { Dark, White } from "./colors";

export const DarkLogo:LogoTheme = {
    color:      Dark.default,
    hoverColor: `${Dark.hover}99`,
}

export const LightLogo:LogoTheme = {
    color:      White.default,
    hoverColor: `${White.hover}99`,
}