import { CreateScheet, css } from "aphrodite";
import useOutsideClick from "packages/hooks/useOutsideClick";
import { CreateTransitionAnimation } from "packages/motion/CreateAnimation";
import Spinner from "packages/spinners/spinner.react";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import Feed from "src/jsx/feed/Feed.react";
import { Purple } from "src/themes/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Dark } from "src/themes/colors";

export interface FeedSliderFunctions {
    toggle: () => void,
    isOpened: () => boolean,
}

const FeedSlider: React.ForwardRefRenderFunction<FeedSliderFunctions, any> = (_, forwardedRef) => {
    const [ Show, SetShow ] = useState(false)
    const [ Entered, SetEntered ] = useState(false)
    const transitionRef = useRef<HTMLDivElement>(null);
    
    useImperativeHandle(forwardedRef, () => ({
        toggle: () => {
            SetShow(s => !s)
        },
        isOpened: () => {
            return Show;
        }
    }));

    useEffect(() => {
        const Document = document.documentElement;
        if (Document) {
            Document.style.position = Entered ? "fixed" : "";
            Document.style.overflowY = Entered ? "scroll" : "auto";
        }
    }, [ Entered ])

    function onKeyDown(e:KeyboardEvent) {
        if (e.key === "Escape") {
            SetShow(false)
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown)
        return () => {
            window.removeEventListener("keydown", onKeyDown)
        }   
    })

    useOutsideClick(transitionRef, () => {
        if (Show && Entered) {
            SetShow(false)
        }
    })

    return(
        <CSSTransition {...{
            timeout:       150,
            in:            Show,
            nodeRef:       transitionRef,
            unmountOnExit: true,
            classNames:    `sl-animation`,
            onEntered: () => {
                SetEntered(true)
            },
            onExited: () => {
                SetEntered(false)
            }
        }}>
            <section {...{
                className: css(CreateTransitionAnimation(["slide-left"], {
                    animationDuration: 150,
                }), Styles.container, !Entered && Styles.loadingStyles),
                ref: transitionRef,
            }}>
                <div className={css(Styles.close)} onClick={() => { SetShow(false) }}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </div>
                {Entered ? <Feed layout={{ paddingTop: 35, paddingBottom: 20 }}/> : <Spinner theme={Purple} height={50}/>}
            </section>
        </CSSTransition>
    );
}

const Styles = CreateScheet({
    container: {
        background: "white",
        height:     "100vh",
        position:   "fixed",
        top:        0,
        left:       "100%",
        width:      400,
        maxWidth:   "100%",
        zIndex:     100,
        transform:  "translateX(-100%)",
        boxShadow:  "-2px 0 12px 0 rgba(3,7,16,0.08)",
    },
    close: {
        padding:  10,
        cursor:   "pointer",
        left:     5,
        top:      5,
        color:    Dark.default,
        position: "absolute",
        zIndex:   10,
    },
    loadingStyles: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    overlay: {
        inset:      0,
        position:   "fixed",
        background: "rgba(0,0,0,0.5)",
        zIndex:     100,
    }
})

export default forwardRef(FeedSlider);