import { CreateScheet, css } from "packages/aphrodite/aphrodite"
import { Gray } from "src/themes/colors";

export function TopRightLine() {
    return (
        <aside className={ css(Styles.bg) }>
            <svg viewBox='0 0 100 100' preserveAspectRatio='none' width="100%" height="100%">
                <path d='M 55,0 C 100 10, 150 360, 100 0' className={ css(Styles.bg_path) } />
            </svg>
        </aside>
    )
}

const Styles = CreateScheet({
    bg: {
        position:           "fixed",
        height:             500,
        width:              "100%",
        zIndex:             0,
        top:                0,
        right:              0,
        overflow:           "hidden",
    },
    bg_path: {
        fill:               Gray.default,
    },
});