type Color = {
    default:    string
    hover:      string
    active:     string
    focus:      string
    disabled:   string
}

export const Primary:Color = {
    default:    "#7367F0",
    hover:      "#7367F0",
    active:     "#5E50EE",
    focus:      "#5E50EE",
    disabled:   "rgba(115, 103, 240, 0.65)",
}

export const Secondary:Color = {
    default:    "#82868B",
    hover:      "#82868B",
    active:     "#75797E",
    focus:      "#75797E",
    disabled:   "rgba(130, 134, 139, 0.65)",
}

export const Success:Color = {
    default:    "#58F59E",
    hover:      "#58F59E",
    active:     "#24B263",
    focus:      "#24B263",
    disabled:   "rgba(40, 199, 111, 0.65)",
}

export const Danger:Color = {
    default:    "#EA5455",
    hover:      "#EA5455",
    active:     "#E73D3E",
    focus:      "#E73D3E",
    disabled:   "rgba(234, 84, 85, 0.65)",
}

export const Warning:Color = {
    default:    "#FF9F43",
    hover:      "#FF9F43",
    active:     "#FF922A",
    focus:      "#FF922A",
    disabled:   "rgba(255, 159, 67, 0.65)",
}

export const Yellow:Color = {
    default:    "#ffca29",
    hover:      "#ffca29",
    active:     "#ffca29",
    focus:      "#ffca29",
    disabled:   "#ffca29",
}

export const Info:Color = {
    default:    "#4D89EE",
    hover:      "#4D89EE",
    active:     "#00B8CF",
    focus:      "#00B8CF",
    disabled:   "rgba(0, 207, 232, 0.65)",
}

export const Dark:Color = {
    default:    "#4B4B4B",
    hover:      "#4B4B4B",
    active:     "#343434",
    focus:      "#343434",
    disabled:   "rgba(75, 75, 75, 0.65)",
}

export const White:Color = {
    default:    "#FFFFFF",
    hover:      "#FFFFFF",
    active:     "#FFFFFF",
    focus:      "#FFFFFF",
    disabled:   "rgba(75, 75, 75, 0.65)",
}

export const Gray:Color = {
    default:    "#E9E9F1",
    hover:      "#E9E9F1",
    active:     "#E9E9F1",
    focus:      "#E9E9F1",
    disabled:   "rgba(75, 75, 75, 0.65)",
}