import { CreateScheetWithTheme, css, useTheme } from "packages/aphrodite/aphrodite";
import { MakeBlock } from "packages/blocks/block.react";
import { CheckEmail } from "packages/helpers/CheckEmail";
import CreateGUID from "packages/helpers/CreateGUID";
import SafeUpdate from "packages/helpers/SafeUpdate";
import { history } from "packages/history/history";
import SmoothLine from "packages/motion/SmoothLine.react";
import { SmoothList, SmoothListItem } from "packages/motion/SmoothList.react";
import VerifyProjectAPI, { VerifyDeveloperProjectRequest } from "packages/projects/VerifyProjectAPI";
import { session } from "packages/session/session";
import Tooltip from "packages/tooltip/Tootip.react";
import { useState } from "react";
import { ReactComponent as RemoveIcon } from 'src/icons/account-remove.svg';
import { ReactComponent as PlusIcon } from 'src/icons/plus.svg';
import { LightBlock } from "src/themes/block";
import { LoadButton, MakeButton } from "src/themes/button";
import { FormStyles } from "src/themes/form";
import { MakeInput } from "src/themes/input";
import { DarkTooltip } from "src/themes/tooltip";
import { SetUpProjectTheme } from "./SetUpProject.theme";

export default function InviteDevelopers({ theme, guid }:{ theme: SetUpProjectTheme, guid: string }) {
    const Styles = useTheme(theme, StylesWithTheme);
    const [ Data, SetData ] = useState<VerifyDeveloperProjectRequest>({ developers: [{
        guid:  CreateGUID(),
        email: "",
        name:  "",
    }] });

    const [ Loading, SetLoading ] = useState(false);
    const [ Errors, SetErrors ] = useState<Array<string | undefined>>([]);

    async function OnSubmit(e: React.FormEvent) {
        e.preventDefault();
        e.stopPropagation();

        let Errors:string[] = [];
        Data.developers.forEach((developer, i) => {
            if (developer.email.length > 0 && developer.name.length === 0) {
                Errors = SafeUpdate(Errors, { [i]: { $set: "Team name is required" }});
            } else if (developer.name.length > 0 && developer.email.length === 0) {
                Errors = SafeUpdate(Errors, { [i]: { $set: "Team email is required" }});
            } else if (Data.developers.length === 1 && developer.email.length === 0) {
                Errors = SafeUpdate(Errors, { [i]: { $set: "Add at least one developer team" }});
            } else if (developer.email.length > 0 && !CheckEmail(developer.email)) {
                Errors = SafeUpdate(Errors, { [i]: { $set: "Email is not correct" }});
            } else if (developer.email.toLocaleLowerCase() === session.user.primary_email?.toLocaleLowerCase()) {
                Errors = SafeUpdate(Errors, { [i]: { $set: "This user already in this project" }});
            }
        });

        if (Errors.length > 0) {
            SetErrors(Errors);
        }

        if (Errors.length === 0) {
            SetLoading(true);
            const [, err] = await VerifyProjectAPI(guid, Data);
            
            SetLoading(false);
            if (err === null) {
                history.push(`/projects/${guid}`);
            } else if (err !== null) {
                SetErrors(SafeUpdate(Errors, { 0: { $set: err.text }}));
            }
        }
    }

    function AddDeveloper(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        SetData({...Data, developers: [...Data.developers, { email: "", name: "", guid: CreateGUID() }]})
    }

    const RemoveDeveloper = (i:number) => (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        SetData(SafeUpdate(Data, {"developers": { $set: Data.developers.filter((v,j) => i !== j) }}));
    }

    const SetDeveloperEmail = (i:number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        SetData(SafeUpdate(Data, {"developers": { [i]: { "email": { "$set":  e.target.value} }}}));
        SetErrors(SafeUpdate(Errors, { [i]: { $set: undefined }}));
    }
    
    const SetDeveloperName = (i:number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        SetData(SafeUpdate(Data, {"developers": { [i]: { "name": { "$set":  e.target.value} }}}));
        SetErrors(SafeUpdate(Errors, { [i]: { $set: undefined }}));
    }

    return (
        <form {...{
            method:     "POST",
            className:  css(FormStyles.form),
            onSubmit:   OnSubmit,
        }}>
            <div className={ css(FormStyles.note, Styles.hint) }>
                add developers to your projecct and connect task managers or source code repositores to them to be able to track thair progress, set deadlines etc
            </div>
            <SmoothList {...{
                duration:   500,
                grow:       true,
                motion:     ["fade", "swipe-right"],
            }} >
                { Data.developers.map((develoepr, i) => (
                    <SmoothListItem {...{
                        grow:       i !== 0,
                        key:        develoepr.guid,
                        className:  css(
                            MakeBlock(LightBlock, { borderRadius: 10 }),
                            Styles.block,
                            Styles.block_margin,
                        ),
                    }}>
                        <SmoothLine className={ css(FormStyles.error, Styles.error) } motion={[ "fade" ]}>
                            { Errors[i] }
                        </SmoothLine>
                        <label className={ css(FormStyles.label) }>team name</label>
                        <div className={ css(Styles.add_developer) }>
                            <input {...{
                                id:             "developer_name",
                                name:           "developer_name",
                                type:           "text",
                                autoComplete:   "off",
                                placeholder:    "",
                                autoFocus:      true,
                                value:          develoepr.name,
                                onChange:       SetDeveloperName(i),
                                className:      css(MakeInput(theme.input, {
                                    fontSize:       16,
                                    borderRadius:   6,
                                    padding:        "8px 12px",
                                    error:          typeof Errors[i] !== "undefined",
                                }), Styles.input)
                            }} />
                            { i === Data.developers.length - 1 && (Data.developers.length === 1 || develoepr.name.length !== 0) ? (
                                <Tooltip {...{
                                    theme:      DarkTooltip,
                                    content:    "add another developer",
                                    disabled:   develoepr.name.length === 0,
                                }}>
                                    <button {...{
                                        type:       "button",
                                        onClick:    AddDeveloper,
                                        disabled:   develoepr.name.length === 0,
                                        className:  css(MakeButton(theme.add_button, {
                                            height:     40,
                                            width:      50,
                                            padding:    0,
                                        }), Styles.add_dev_button)
                                    }}>
                                        <PlusIcon />
                                    </button>
                                </Tooltip>
                            ) : (
                                <Tooltip theme={ DarkTooltip } content={" delete developer "}>
                                    <button {...{
                                        type:       "button",
                                        onClick:    RemoveDeveloper(i),
                                        className: css(MakeButton(theme.del_button, {
                                            height:     40,
                                            width:      50,
                                            padding:    0,
                                        }), Styles.add_dev_button)
                                    }}>
                                        <RemoveIcon />
                                    </button>
                                </Tooltip>
                            )}
                        </div>
                        <label className={ css(FormStyles.label, Styles.email_label) }>email</label>
                        <div className={ css(Styles.add_developer) }>
                            <input {...{
                                id:             "developer_email",
                                name:           "developer_email",
                                type:           "text",
                                autoComplete:   "off",
                                placeholder:    "email address",
                                autoFocus:      true,
                                value:          develoepr.email,
                                onChange:       SetDeveloperEmail(i),
                                className:      css(MakeInput(theme.input, {
                                    width:        "100%",
                                    fontSize:     16,
                                    borderRadius: 6,
                                    padding:      "8px 12px",
                                    error:        typeof Errors[i] !== "undefined",
                                }))
                            }} />
                        </div>
                    </SmoothListItem>
                )) }
            </SmoothList>
            <LoadButton {...{
                type:       "submit",
                loading:    Loading,
                className:  css(Styles.submit),
                theme:      theme.action,
                layout:     {
                    width:          "100%",
                    fontSize:       16,
                    borderRadius:   6,
                    padding:        "10px 12px",
                },
            }}>
                Continue
            </LoadButton>
        </form>
    )
}

const StylesWithTheme = CreateScheetWithTheme((theme?: SetUpProjectTheme) => { return {
    label: {
        color:          theme?.label_color,
    },
    block: {
        padding:        "20px 20px 30px 20px",
    },
    block_margin: {
        marginTop:      20,
    },
    error: {
        margin:        "0 0 5px 0",
        textAlign:      "left",
        fontWeight:     "bold",
    },
    input: {
        display:        "block",
        flexGrow:       1,
        marginRight:    10,
    },
    hint: {
        fontSize:       14,
    },
    add_developer: {
        display:        "flex",
        height:         40,
        width:          "100%",
    },
    email_label: {
        marginTop: 14,
    },
    add_dev_button: {
        display:        "flex",
        alignItems:     "center",
        justifyContent: "center",
    },
    picker: {
        display:        "flex",
    },
    x_devider: {
        flexGrow:       1,
    },
    submit: {
        marginTop:      40,
        display:        "block",
    }
}})