import { BlockLayout, BlockTheme } from 'packages/blocks/block.theme';

export const DefaultLayout:BlockLayout = {
    borderRadius:   4,
}

export const LightBlock:BlockTheme = {
    backgroundColor:    "white",
    boxShadow:          "0 3px 8px 0 rgba(3,3,13,0.12)",
}
