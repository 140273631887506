import { AccessGroup } from "packages/projects/model";
import * as r from "runtypes";
import { NotificationBaseRuntype } from "./models";
import { UserObjectRuntype } from "./users";

export const ClientMemberObjectRuntype = r.Record({
	member_id:       r.Number,
	access_group_id: r.Number,
	role:            r.String,
	is_verified:     r.Boolean,
	email:           r.String.Or(r.Null),
	name:            r.String,
})
export type ClientMemberObject = Omit<r.Static<typeof ClientMemberObjectRuntype>, "access_group_id"> & {
    access_group_id: AccessGroup
};

export const ClientMemberAddedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("client_member_added"),
    content: r.Record({
        member: ClientMemberObjectRuntype,
        user:   UserObjectRuntype,
    }) 
})
export type ClientMemberAddedNotification = r.Static<typeof ClientMemberAddedNotificationRuntype>;