import { CreateScheet } from 'aphrodite';
import { Danger, Secondary } from './colors';

export const FormStyles = CreateScheet({
    body: {
        position:       "relative",
        padding:        "50px 20px 30px",
        '@media (max-width: 600px)': {
            paddingTop:     20,
        }
    },
    form: {
        display:    "block",
        margin:     '0 0 15px 0',
        fontSize:   13,  
    },
    title: {
        margin:         0,
        fontSize:       24,
        fontWeight:     500,
        marginBottom:   20,
    },
    divider: {
        marginBottom:   12,
        border:         "none",
        outline:        "none",
    },
    divider_with_line: {
        marginBottom:   12,
        backgroundColor:Secondary.default,
        height:         1,
        border:         "none",
        outline:        "none",
        opacity:        0.3,
    },
    label: {
        display:        "block",
        paddingBottom:  7,
        fontWeight:     500,
        fontSize:       14,
    },
    label_error: {
        float:          "right",
        color:          Danger.default,
        fontSize:       13,
    },
    error: {
        color:          Danger.default,
    },
    note: {
        fontSize:       13,
        margin:         0,
    }
})
