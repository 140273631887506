import * as r from "runtypes";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { patch } from "packages/rest/api";

export default async function VerifyRequestedEmail(code:string) {
    const res = await patch<true>({ 
        url: "users/emails", 
        body: {
            code,
        }
    });
    return CheckResultRuntime(r.Literal(true), res);
}