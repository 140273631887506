import { CreateScheet, css } from "aphrodite";
import moment from 'moment';
import LoadButton from 'packages/buttons/LoadButton.react';
import { LoadFeed } from "packages/feed/API/LoadFeed";
import { MarkAsRead } from 'packages/feed/API/MarkAsRead';
import { Notification } from "packages/feed/types";
import Virtualized from "packages/lists/Virtualized.react";
import { VirtualizedLayout } from "packages/lists/Virtualized.theme";
import { session } from "packages/session/session";
import { useCallback, useEffect, useRef, useState } from "react";
import { DefaultLayout, WhiteFilled } from 'src/themes/button';
import { GetNotificationComponent } from './NotificationsTypes.react';

export default function Feed({
    layout,
}:{
    layout?: VirtualizedLayout,
}) {
    const ActiveProjectGUID = session.extra.active_project_guid;
    const ProjectGUIDRef = useRef<string>(ActiveProjectGUID)
    const [ Loading, SetLoading ] = useState(false)
    const [ Notifications, SetNotifications ] = useState<Notification[]>([])
    const [ HasMore, SetHasMore ] = useState(false)
    const [ Clearing, SetClearing ] = useState(false)

    const Load = useCallback(async (before_id?:number) => {
        let reset = false;
        if (ActiveProjectGUID !== ProjectGUIDRef.current) {
            ProjectGUIDRef.current = ActiveProjectGUID
            reset = true
        }

        SetLoading(true)
        const [res, err] = await LoadFeed(ActiveProjectGUID, before_id)
        if (res && err == null) {
            SetNotifications(n => reset ? res.notifications : n.concat(res.notifications))
            SetHasMore(res.has_more)
            SetLoading(false)
        }
    }, [ ActiveProjectGUID ])

    useEffect(() => {
        Load()
    }, [ Load ])

    function getFormattedTime(timestamp:number):string {
        return moment.unix(timestamp).format('DD-MM-YYYY')
    } 

    function getItems() {
        let curr:Notification;
        let items:React.ReactNode[] = [];

        Notifications.forEach(notification => {
            if (!curr || getFormattedTime(notification.created_on) !== getFormattedTime(curr.created_on)) {
                items.push(
                    <time className={css(Styles.timeLabel)}>
                        {moment.unix(notification.created_on).format('DD MMM YYYY')}
                    </time>
                )
            }

            items.push(GetNotificationComponent(notification))
            curr = notification 
        });

        return items;
    }

    async function MarkAllAsRead() {
        SetClearing(true)
        const res = await MarkAsRead()
        if (res[1] === null) {
            SetClearing(false)
            session.ChangeUnreadNotificationsCount(0)
        }
    }

    return(
        <section className={css(Styles.main)}>
            {session.extra.unread_notifications_count > 0 &&
                <span className={css(Styles.clearButton)}>
                    <LoadButton {...{
                        loading: Clearing,
                        theme: WhiteFilled,
                        layout: {...DefaultLayout, padding: "3px 5px"},
                        onClick: MarkAllAsRead,
                    }}>
                        Mark all as read
                    </LoadButton>
                </span>
            }
            <Virtualized {...{
                hasMore:         HasMore,
                loadMore:        async () => { 
                    await Load(Notifications[Notifications.length - 1].id)
                    return
                },
                isMoreLoading:   Loading,
                items:           getItems(),
                layout: layout  || {
                    paddingTop:    30,
                    paddingBottom: 20,
                },
                hideScrollbar: true,
            }}/>
        </section>
    );
}

const Styles = CreateScheet({
    main: {
        height: "100%",
    },
    timeLabel:{
        fontSize:   14,
        background: "white",
        padding:    "10px 20px",
        display:    "block",
        color:      "#A5A4C0",
    },
    clearButton: {
        position: "absolute",
        top: 5,
        right: 20, 
        zIndex: 1,
    }
})