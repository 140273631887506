import CreateProject from "./modules/CreateProject.react";
import { Fragment } from "react";
import { OpenPage } from "packages/history/history";
import SetUpProject from "./modules/SetUpProject.react";
import { SmoothSteper, SmoothSteperItem } from "packages/motion/SmoothStepper.react";
import { TopRightLine } from "../layouts/Backgrounds.react";
import { CSSProperties } from "aphrodite";
import { ButtonTheme } from "packages/buttons/button.theme";
import { InputTheme } from "packages/forms/Input";
import Cross from './components/Cross.react';
import { SignInTheme } from "../authentication/modules/SignIn.theme";

const Steps:Record<string, number> = {
    "create":  0,
    "setup":   1,
}

export type OnboardingTheme = {
    input:          InputTheme
    add_button:     ButtonTheme
    del_button:     ButtonTheme
    action:         ButtonTheme
    label_color:    CSSProperties["color"]
    sign_in:        SignInTheme
}

export default function Onboadring({ step, guid, theme }:{ step: keyof typeof Steps, guid: string, theme: OnboardingTheme}) {
    return (
        <Fragment>
            <Cross onClick={ OpenPage("") } />
            <TopRightLine />
            <SmoothSteper {...{ active: Steps[step] || 0, duration: 300, motion: ["fade"], grow: true }}>
                <SmoothSteperItem>
                    <CreateProject {...{ theme: theme }} />
                </SmoothSteperItem>
                <SmoothSteperItem>
                    <SetUpProject {...{ theme: theme, guid }} />
                </SmoothSteperItem>
            </SmoothSteper>
        </Fragment>
    )
}