import { css, CSSProperties, StyleDeclarationValue } from "aphrodite";
import { CreateScheet } from "packages/aphrodite/aphrodite";
import useIsMobileView from "packages/hooks/useIsMobileView";
import WithSpinner from "packages/spinners/SmartSpinner.react";
import { DashboardSizes } from "src/themes/layouts";
import { Purple } from "src/themes/spinner";

export default function PageWrapper({
    children,
    loading,
    showContentOnLoading = false,
    className,
    contentMinWidth,
}:React.PropsWithChildren<{
    title?:                string,
    loading?:              boolean
    showContentOnLoading?: boolean,
    className?:            StyleDeclarationValue,
    contentMinWidth?:      CSSProperties["minWidth"],
}>) {
    const IsMobileView = useIsMobileView()
    const Styles = MakeStyles(IsMobileView, contentMinWidth);
    return (
        <article className={css(Styles.article, className && className)}>
            <div className={css(Styles.innerContent)}>
                <WithSpinner {...{
                    grow:        false,
                    suspense:    loading || false,
                    className:   Styles.spinner,
                    theme:       Purple,
                    minDuration: 400,
                    showContentOnLoading,
                }}>
                    {children}
                </WithSpinner>
            </div>
        </article>
    );
}

const MakeStyles = (isMobView:boolean, contentMinWidth?:CSSProperties["minWidth"]) => CreateScheet({
    innerContent: {
        minWidth: contentMinWidth ? contentMinWidth : 900,
    },
    article: {
        padding:            `${DashboardSizes.header}px 0 0 ${isMobView ? 0 : DashboardSizes.sidebar}px`,
        minHeight:          "100vh",
        background:         "#f8f8fd",
        backgroundImage:    "radial-gradient(#e9e9f0 12%, transparent 12%)",
        backgroundPosition: "0 0, 50px 50px",
        backgroundSize:     "15px 15px",
        minWidth: contentMinWidth ? contentMinWidth : 900,
    },
    spinner: {
        position: "fixed",
        top: 106,
        bottom: 0,
        left: 0,
        right: 0,
        background: "rgba(255,255,255,0.5)",
        zIndex: 20,
        height: "auto",
        alignItems: "center",
    }
})