import { css, CreateScheet } from "packages/aphrodite/aphrodite";
import { Dark } from "src/themes/colors";
import { DarkFooter } from "src/themes/footer";
import { DarkLogo } from "src/themes/logo";
import { MaxHalfFullWidth } from "src/themes/sizes";
import PageFooter from "../footers/PageFooter.react";
import Logo from "../logo/Logo.react";

export default function StaticEmpty({ children, noHeader }: React.PropsWithChildren<{ noHeader?: boolean }>) {
    return (
        <article className={ css(Styles.page) }>
            { !noHeader && (
                <header className={ css(Styles.header) }>
                    <Logo theme={ DarkLogo } />
                </header>
            ) }
            <section className={ css(Styles.block) } >
                { children }
            </section>
            <footer className={ css(Styles.footer) }>
                <PageFooter theme={ DarkFooter } />
            </footer>
        </article>
    )
}

const Styles = CreateScheet({
    page: {
        color:              Dark.default,
        minHeight:          "100vh",
        overflowX:          "hidden",
        display:            "flex",
        flexDirection:      "column",
        width:              MaxHalfFullWidth,
        margin:             "0 auto",
        [`@media (max-width: ${MaxHalfFullWidth}px)`]: {
            width:          "100%",
            padding:        "0 20px",
        }
    },
    header: {
        padding:            "30px 0 20px 0px",
        transition:         "padding .1s",
        width:              "100%",
        '@media (max-width: 600px)': {
            paddingTop:     20,
        }
    },
    block: {
        flexGrow:           1,
        width:              "100%",
        display:            "flex",
        flexDirection:      "column",
    },
    footer: {
        display:            "flex",
        justifyContent:     "center",
        alignItems:         "flex-end",
        paddingBottom:      10,
        width:              "100%",
    }
});