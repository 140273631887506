import {CreateScheet, css} from 'packages/aphrodite/aphrodite';
import DatesPicker from 'packages/dates-picker/DatesPicker.react';
import Dropdown from 'packages/dropdowns/dropdownBase.react';
import useIsMobileView from 'packages/hooks/useIsMobileView';
import useMaxWidth from 'packages/hooks/useMaxWidth';
import {showLightbox} from 'packages/lightbox/lightbox';
import {SynchronizeTasks} from 'packages/tasks/API/SynchronizeTasks';
import ProfileSettings from 'packages/profile/Settings.react';
import {session, SignOut} from 'packages/session/session';
import {ReactComponent as BellIcon} from 'src/icons/bell.svg';
import {ReactComponent as Autorenew} from 'src/icons/autorenew.svg';
import {ReactComponent as CalendarIcon} from 'src/icons/calendar-month-outline.svg';
import {ReactComponent as DropIcon} from 'src/icons/down-arrow.svg';
import {PageID} from 'src/runtime/pages';
import {MakeButton, PrimaryFilled} from 'src/themes/button';
import {Dark, Gray, Primary, Secondary, White} from 'src/themes/colors';
import {DashboardSizes} from 'src/themes/layouts';
import {LightTheme} from 'src/themes/lightbox';
import SearchInput from './SearchInput.react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faCog, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'packages/tooltip/Tootip.react';
import {DarkTooltip} from 'src/themes/tooltip';
import {Fragment} from 'react';

export default function Header({
    toggleFeed,
    showSidebar,
    currentPage,
}: {
    toggleFeed?: () => void;
    showSidebar?: () => void;
    currentPage?: PageID;
}) {
    const IsMobileView = useIsMobileView();
    const isLess560 = useMaxWidth(560);
    const isLess1050 = useMaxWidth(1050);
    const Styles = MakeStyles(IsMobileView);
    const hideFeed = useMaxWidth(DashboardSizes.moveFeedAfter);

    const project = session.GetActiveProject();
    const ActiveTeam = project.project.teams?.find(t => t.id === session.extra.active_team_id);

    function ChangeTeam(team_id: number) {
        session.SetActiveTeam(team_id);
    }

    function showProfileSettings() {
        showLightbox({
            selector: 'profile-settings',
            Component: ProfileSettings,
            theme: LightTheme,
            content: {},
        });
    }

    function OpenSidebar() {
        if (typeof showSidebar === 'function') {
            showSidebar();
        }
    }

    function getDateFormat() {
        if (isLess1050) {
            return 'MM/DD/YY';
        }

        return 'D MMM YYYY';
    }

    async function sync() {
        if (project.project.guid) {
            await SynchronizeTasks(project.project.guid);
        }
    }

    return (
        <header className={css(Styles.header)}>
            {IsMobileView && (
                <div
                    className={css(Styles.menu)}
                    onClick={OpenSidebar}
                >
                    <FontAwesomeIcon icon={faBars} />
                </div>
            )}
            <div className={css(Styles.search)}>
                <SearchInput />
            </div>
            {project.project.guid && (
                <div
                    className={css(Styles.autorenew)}
                    onClick={sync}
                >
                    <Tooltip
                        {...{
                            theme: DarkTooltip,
                            content: 'Synchronize worklog',
                        }}
                    >
                        <Autorenew className={css(Styles.autorenewIcon)} />
                    </Tooltip>
                </div>
            )}
            {project.policies.can_choose_team && (
                <Dropdown
                    {...{
                        animation: 'fade',
                        header: () => (
                            <div className={css(Styles.picker)}>
                                {ActiveTeam ? (
                                    <span className={css(Styles.teamTitle)}>
                                        <span
                                            className={css(Styles.teamIndicator)}
                                            style={{background: ActiveTeam.color}}
                                        />
                                        {!isLess560 && (
                                            <span className={css(Styles.teamTitleText)}>{ActiveTeam.title}</span>
                                        )}
                                    </span>
                                ) : (
                                    <Fragment>{isLess560 ? 'All' : 'All teams'}</Fragment>
                                )}
                                <DropIcon className={css(Styles.drop_icon)} />
                            </div>
                        ),
                        dropElement: () => (
                            <ul className={css(Styles.teams_list)}>
                                <li
                                    {...{
                                        className: css(Styles.team_item),
                                        onClick: () => {
                                            ChangeTeam(0);
                                        },
                                    }}
                                >
                                    <span className={css(Styles.teamTitle)}>
                                        <span
                                            className={css(Styles.teamIndicator)}
                                            style={{background: 'transparent'}}
                                        />
                                        <span className={css(Styles.teamTitleText)}>All teams</span>
                                    </span>
                                </li>
                                {project.project.teams?.map(team => (
                                    <li
                                        {...{
                                            key: team.id,
                                            className: css(Styles.team_item),
                                            onClick: () => {
                                                ChangeTeam(team.id);
                                            },
                                        }}
                                    >
                                        <span className={css(Styles.teamTitle)}>
                                            <span
                                                className={css(Styles.teamIndicator)}
                                                style={{background: team.color}}
                                            />
                                            <span className={css(Styles.teamTitleText)}>{team.title}</span>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        ),
                    }}
                />
            )}
            <div className={css(Styles.dates)}>
                <DatesPicker
                    {...{
                        startDate: session.date_range.start,
                        endDate: session.date_range.end,
                        onApply: (start, end) => {
                            session.SetDateRange(start, end.endOf('day'));
                        },
                        dropElementClass: Styles.datesPickerDropElement,
                        ApplyElement: (
                            <button
                                {...{
                                    className: css(
                                        MakeButton(PrimaryFilled, {
                                            padding: '6px 10px',
                                            fontSize: 13,
                                        })
                                    ),
                                }}
                            >
                                Apply
                            </button>
                        ),
                    }}
                >
                    <div className={css(Styles.picker)}>
                        <CalendarIcon className={css(Styles.calendar_icon)} />
                        {!isLess560 && (
                            <Fragment>
                                <div className={css(Styles.dateRange)}>
                                    {session.date_range.start.format(getDateFormat())} -{' '}
                                    {session.date_range.end.format(getDateFormat())}
                                </div>
                                <DropIcon className={css(Styles.drop_icon)} />
                            </Fragment>
                        )}
                    </div>
                </DatesPicker>
            </div>
            {(hideFeed || currentPage !== 'project_landing') && (
                <div
                    {...{
                        className: css(Styles.bell),
                        onClick: () => {
                            if (typeof toggleFeed === 'function') {
                                toggleFeed();
                            }
                        },
                    }}
                >
                    {session.extra.unread_notifications_count > 0 && (
                        <div className={css(Styles.notificationsCount)}>
                            {session.extra.unread_notifications_count > 9
                                ? '9+'
                                : session.extra.unread_notifications_count}
                        </div>
                    )}
                    <BellIcon className={css(Styles.bell_icon)} />
                </div>
            )}
            <Dropdown
                {...{
                    animation: 'fade',
                    header: () => <div className={css(Styles.profile)}>{session.user.full_name}</div>,
                    dropElement: () => (
                        <ul className={css(Styles.profile_actions)}>
                            <li
                                {...{
                                    className: css(Styles.profile_action),
                                    onClick: showProfileSettings,
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCog}
                                    fixedWidth
                                />
                                <span className={css(Styles.profile_action_text)}>Settings</span>
                            </li>
                            <li
                                {...{
                                    className: css(Styles.profile_action),
                                    onClick: () => {
                                        SignOut();
                                    },
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faSignOutAlt}
                                    fixedWidth
                                />
                                <span className={css(Styles.profile_action_text)}>Sign out</span>
                            </li>
                        </ul>
                    ),
                }}
            />
        </header>
    );
}

const MakeStyles = (isMobView: boolean) =>
    CreateScheet({
        menu: {
            padding: '10px',
            color: Dark.default,
            cursor: 'pointer',
            marginRight: 10,
        },
        header: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
        },
        search: {
            flexGrow: 1,
        },
        choose_team: {},
        dates: {
            marginRight: 10,
        },
        datesPickerDropElement: {
            '@media (max-width: 430px)': {
                marginRight: -50,
            },
        },
        picker: {
            border: '1px solid #A5A4C0',
            borderRadius: 4,
            backgroundColor: '#F8F8FD',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 16px',
            fontSize: 13,
            fontWeight: 500,
            height: 38,
            userSelect: 'none',
            marginLeft: 10,
        },
        teamTitle: {
            display: 'flex',
            alignItems: 'center',
        },
        teamIndicator: {
            height: 10,
            width: 10,
            borderRadius: '100%',
        },
        teamTitleText: {
            marginLeft: 10,
        },
        calendar_icon: {
            display: 'block',
            height: 20,
            width: 20,
        },
        dateRange: {
            marginLeft: 10,
        },
        drop_icon: {
            display: 'block',
            height: 12,
            width: 12,
            marginLeft: 10,
        },
        bell: {
            margin: '0 10px',
            height: 20,
            position: 'relative',
            cursor: 'pointer',
            userSelect: 'none',
        },
        notificationsCount: {
            textAlign: 'center',
            color: 'white',
            background: Primary.default,
            borderRadius: 8,
            position: 'absolute',
            fontSize: 11,
            height: 17,
            minWidth: 17,
            left: 10,
            top: -7,
            letterSpacing: 1,
            lineHeight: '17px',
            padding: '0 4px',
        },
        autorenew: {
            margin: '0 10px',
            height: 20,
            position: 'relative',
            cursor: 'pointer',
            userSelect: 'none',
        },
        autorenewIcon: {
            cursor: 'pointer',
            display: 'block',
            width: 22,
            height: 22,
            fill: Dark.default,
            outline: 0,
            transform: 'transform(0deg)',
            transition: 'transform 0.25s',
            ':active': {
                transform: 'rotate(360deg)',
            },
        },
        bell_icon: {
            cursor: 'pointer',
            display: 'block',
            width: 22,
            height: 22,
            fill: Secondary.default,
        },
        teams_list: {
            listStyle: 'none',
            padding: '10px 0',
            margin: 0,
            position: 'absolute',
            top: '100%',
            left: 0,
            minWidth: '100%',
            background: White.default,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            fontSize: 13,
            boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
            userSelect: 'none',
        },
        team_item: {
            padding: '5px 10px',
            ':hover': {
                backgroundColor: Gray.default,
            },
        },
        profile: {
            marginLeft: 10,
            fontWeight: 500,
            color: Dark.active,
            cursor: 'pointer',
            '@media (max-width: 780px)': {
                display: 'none',
            },
        },
        profile_actions: {
            listStyle: 'none',
            padding: '10px 0',
            margin: 0,
            position: 'absolute',
            top: '100%',
            left: 0,
            minWidth: '100%',
            background: White.default,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            fontSize: 14,
            boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
            userSelect: 'none',
            color: Dark.default,
        },
        profile_action: {
            padding: '5px 10px',
            ':hover': {
                backgroundColor: Gray.default,
            },
        },
        profile_action_text: {
            marginLeft: 5,
        },
    });
