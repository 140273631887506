import { Notification } from "packages/feed/types";
import DeadlineCreated from "./deadlines/DeadlineCreated.react";
import DeadlineChanged from "./deadlines/DeadlineChanged.react";
import DeadlineDeleted from "./deadlines/DeadlineDeleted.react";
import ReleaseCreated from "./releases/ReleaseCreated.react";
import ReleaseChanged from "./releases/ReleaseChanged.react";
import ReleaseDeleted from "./releases/ReleaseDeleted.react";
import ClientMemberAdded from "./client_members/ClientMemberAdded.react";
import WorklogSubmitted from "./worklogs/WorklogSubmitted.react";
import WorklogApproved from "./worklogs/WorklogApproved.react";
import InvoiceCreated from "./invoices/InvoiceCreated.react";
import InvoiceChanged from "./invoices/InvoiceChanged.react";
import InvoiceDeleted from "./invoices/InvoiceDeleted.react";
import BudgetCreated from "./budgets/BudgetCreated.react";
import BudgetChanged from "./budgets/BudgetChanged.react";
import BudgetDeleted from "./budgets/BudgetDeleted.react";
import DevTeamInvited from "./dev_teams/DevTeamInvited.react";
import DevTeamChanged from "./dev_teams/DevTeamChanged.react";
import DeveloperMemberChanged from "./developer_members/DeveloperMemberChanged.react";

export function GetNotificationComponent(notification:Notification):React.ReactNode {
    switch (notification.marker) {
        // deadlines
        case "deadline_created": return <DeadlineCreated {...{ notification }}/>
        case "deadline_changed": return <DeadlineChanged {...{ notification }}/>
        case "deadline_deleted": return <DeadlineDeleted {...{ notification }}/>
        // releases
        case "release_created":  return <ReleaseCreated {...{ notification }} />
        case "release_changed":  return <ReleaseChanged {...{ notification }} />
        case "release_deleted":  return <ReleaseDeleted {...{ notification }} />
        // invoices
        case "invoice_created":  return <InvoiceCreated {...{ notification }} />
        case "invoice_changed":  return <InvoiceChanged {...{ notification }} />
        case "invoice_deleted":  return <InvoiceDeleted {...{ notification }} />
        // budgets
        case "budget_created":  return <BudgetCreated {...{ notification }} />
        case "budget_changed":  return <BudgetChanged {...{ notification }} />
        case "budget_deleted":  return <BudgetDeleted {...{ notification }} />
        // dev teams
        case "dev_team_invited":  return <DevTeamInvited {...{ notification }} />
        case "dev_team_changed":  return <DevTeamChanged {...{ notification }} />
        // client members
        case "client_member_added":  return <ClientMemberAdded {...{ notification }} />
        // developer members 
        case "developer_member_changed":  return <DeveloperMemberChanged {...{ notification }} />
        // worklogs
        case "worklog_submitted": return <WorklogSubmitted {...{ notification }} />
        case "worklog_approved": return <WorklogApproved {...{ notification }} />
    }

    return null
}