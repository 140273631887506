import * as r from "runtypes";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { put } from "packages/rest/api";

export async function MarkAsRead(notification_id?:number) {
    const res = await put<true>({
        url: `feed/statuses`,
        body: {
            notification_id,
        },
    });

    return CheckResultRuntime(r.Literal(true), res);
}