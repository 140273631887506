import { css, CSSProperties, CreateScheet } from 'aphrodite';

export type LineProgressTheme = {
    color:          CSSProperties["backgroundColor"] | Array<{offset: number, color: string | undefined}>
}

export default function LineProgress({
    theme,
    height,
    progress,
}:{
    theme:      LineProgressTheme,
    progress:   number,
    height?:    CSSProperties["height"],
}) {
    const styles = Styles(theme, height || 4);
    progress = progress > 100 ? 100 : progress;

    return (
        <aside className={ css(styles.container) }>
            <div className={ css(styles.line) } style={{
                transform:      `translateX(${progress - 100}%)`,
            }} />
        </aside>
    )
}

const Styles = (theme: LineProgressTheme, height: CSSProperties["height"]) => {
    let bg:string = "";
    if (Array.isArray(theme.color)) {
        bg = `linear-gradient(to left, ${theme.color.map(({ color, offset }) => `${color} ${offset}%`).join(",")});`
    } else if (theme.color) {
        bg = theme.color;
    }
   
    return CreateScheet({
        container: {
            width:          "100%",
            overflow:       "hidden",
        },
        line: {
            background:                 bg,
            height:                     height,
            borderTopRightRadius:       typeof height === "number" ? height/2 : 0,
            borderBottomRightRadius:    typeof height === "number" ? height/2 : 0,
            transition:                 "transform 0.05s",
            willChange:                 "transform",
        }
    })
};
