import EventEmitter from 'eventemitter3';
import moment from 'moment';
import { ErrorData } from 'packages/errors/errors';
import SafeUpdate from 'packages/helpers/SafeUpdate';
import { history } from 'packages/history/history';
import { Project, ProjectTeam } from 'packages/projects/model';
import { ShowDefaultToast } from 'src/themes/toasts';
import { CreateUserAPI, CreateUserRequest } from './CreateUserAPI';
import { LoadSessionAPI } from './LoadSessionAPI';
import { Session, SessionRaw, SessionUser } from './model';
import { PatchSessionAPI } from './PatchSessionAPI';
import { RequestResetPasswordAPI, RequestResetPasswordRequest } from './RequestResetPasswordAPI';
import { ResendEmailVerificationAPI } from './ResendEmailVerificationAPI';
import { SetNewPasswordFromResetAPI, SetNewPasswordFromResetRequest } from './SetNewPasswordFromResetAPI';
import { SignInAPI, SignInRequest } from './SignInAPI';
import { SignOutAPI } from './SignOutAPI';
import { VerifyRequestedEmailAPI } from './VerifyRequestedEmailAPI';

export const Events = new EventEmitter<{
    "sign-in":         () => void,
    "sign-out":        () => void,
    "session-changes": () => void,
}>();

const SessionEmpty:Session = {
    user: {
        guid:               "",
        primary_email:      null,
        requested_email:    null,
        primary_phone:      null,
        requested_phone:    null,
        full_name:          "",
    },
    extra: {
        projects:                   [],
        active_project_guid:        "",
        active_team_id:             0,
        unread_notifications_count: 0,
        disapproved_worklogs_count: 0,
        selected_range_start:       0,
        selected_range_end:         0,
    },
    policies: {
        can_sign_in:                  false,
        can_sign_up:                  false,
        can_sign_out:                 false,
        can_create_project:           false,
        can_view_dashboard:           false,
    },
    date_range: {
        start: moment().startOf("month"),
        end:   moment().endOf("month"),
    },
    SetDateRange: function(this: Session, start: moment.Moment, end: moment.Moment) {
        this.date_range = { start, end };
        Events.emit("session-changes");
        PatchSessionAPI({
            selected_range_start: start.unix(),
            selected_range_end:   end.unix(),
        });
    },
    SetActiveProject: async function(this: Session, GUID: string, update_url: boolean) {
        update_url && history.push(`/projects/${GUID}`)
        this.extra.active_project_guid = GUID;
        const project = this.extra.projects.find(p => p.project.guid === GUID);
        if (project?.project.teams) {
            this.extra.active_team_id = project.project.teams[0]?.id || 0;
        }
        // async part
        await PatchSessionAPI({ active_project_guid: GUID, active_team_id: this.extra.active_team_id });
        this.Reload()
    },
    RemoveActiveProjectTeam: async function(this: Session, teamID: number) {
        const ActiveProject = this.GetActiveProject()
        const NewTeams = ActiveProject.project.teams?.filter(t => t.id !== teamID)
        const ActiveProjectIndex = this.GetActiveProjectIndex()
        
        if (NewTeams) {
            this.extra.projects[ActiveProjectIndex].project.teams = NewTeams
        }
    },
    AddTeamToActiveProject: async function(this: Session, team: ProjectTeam) {
        const ActiveProject = this.GetActiveProject()
        if (!ActiveProject.project.teams) {
            ActiveProject.project.teams = [];
        }
        ActiveProject.project.teams.push(team)
        const ActiveProjectIndex = this.GetActiveProjectIndex()
        
        this.extra.projects[ActiveProjectIndex].project.teams = ActiveProject.project.teams
        Events.emit("session-changes");
    },
    SetActiveTeam: async function(this: Session, team_id: number) {
        // TODO chanhge url
        this.extra.active_team_id = team_id;
        // async part
        await PatchSessionAPI({ active_team_id: team_id });
        this.Reload()
    },
    GetActiveProject: function(this: Session) {
        if (this.extra.projects.length === 0) {
            throw new Error("no active project")
        }

        const ActiveProject = this.extra.projects.find(p => p.project.guid === this.extra.active_project_guid);
        return ActiveProject || this.extra.projects[0];
    },
    GetActiveTeam: function(this: Session) {
        const ActiveProject = this.GetActiveProject()
        if (ActiveProject.project.teams.length > 0) {
            return ActiveProject.project.teams.find(t => t.id === this.extra.active_team_id) || null
        }

        return null
    },
    GetActiveTeamTimeTrackingSettings: function(this: Session) {
        const ActiveTeam = this.GetActiveTeam()
        return ActiveTeam?.atlassian_tracking_settings || {
            working_days_per_week: 5,
            working_hours_per_day: 8,
        }
    },
    GetActiveProjectIndex: function(this: Session) {
        if (this.extra.projects.length === 0) {
            throw new Error("no active project")
        }

        const ActiveProjectIndex = this.extra.projects.findIndex(p => p.project.guid === this.extra.active_project_guid);
        return ActiveProjectIndex >= 0 ? ActiveProjectIndex :  0;
    },
    Reload: async function(this: Session) {
        await LoadSession()
        Events.emit("session-changes");
    },
    ChangeUnreadNotificationsCount: function(this: Session, newCount:number) {
        this.extra.unread_notifications_count = newCount;
        Events.emit("session-changes")
    },
    ChangeDisapprovedWorklogsCount: function(this: Session, newCount:number) {
        this.extra.disapproved_worklogs_count = newCount;
        Events.emit("session-changes")
    },
    ChangeProjectData: function(this: Session, data:Partial<Project>) {
        const ActiveProject = this.GetActiveProject()
        const ActiveProjectIDX = this.GetActiveProjectIndex()
        if (ActiveProject && ActiveProjectIDX > -1) {
            this.extra.projects[ActiveProjectIDX].project = {...ActiveProject.project, ...data}
        }

        Events.emit("session-changes")
    },
    UpdateUserFields: function(this: Session, data:Partial<SessionUser>) {
        this.user = {...this.user, ...data}
        Events.emit("session-changes")
    },
    ChangeTeamData: function(this: Session, id:number, data:Partial<ProjectTeam>) {
        const ActiveProject = this.GetActiveProject()
        const ActiveProjectIDX = this.GetActiveProjectIndex()
        if (ActiveProject && ActiveProjectIDX > -1) {
            const ActiveTeamIDX = id > 0 ? ActiveProject.project.teams?.findIndex(t => t.id === id) : 0
            if (ActiveTeamIDX !== undefined && ActiveTeamIDX > -1) {
                if (this.extra.projects[ActiveProjectIDX].project.teams) {
                    const team = this.extra.projects[ActiveProjectIDX].project.teams![ActiveTeamIDX]
                    this.extra.projects[ActiveProjectIDX].project.teams![ActiveTeamIDX] = {...team, ...data}
                }
            }
        }
        Events.emit("session-changes")
    }
}

let session:Session = {...SessionEmpty};

function SetSession(newSession: SessionRaw) {
    session = {...session, ...newSession};
    if (newSession.extra.selected_range_start && newSession.extra.selected_range_end) {
        session = SafeUpdate(session, {
            date_range: {
                start: { $set: moment.unix(newSession.extra.selected_range_start) },
                end:   { $set: moment.unix(newSession.extra.selected_range_end) },
            }
        })
    }
}

// usually such functions should not be located here
// but session is a bit different case
export async function LoadSession():Promise<Session> {
    const res = await LoadSessionAPI();
    if (res[1] !== null) {
        console.error("session error: ", res[1].text);
    } else {
        SetSession(res[0]);
    }

    return session;
}

export async function SignUp(rq: CreateUserRequest):Promise<ErrorData | null> {
    const res = await CreateUserAPI(rq);
    if (res[1] === null) {
        SetSession(res[0]);
    } else {
        return res[1]
    }

    if (res[0].policies.can_sign_out) {
        ShowDefaultToast("Successfully signed up");
    }

    Events.emit("session-changes");
    return null;
}

export async function VerifyRequestedEmail(code: string):Promise<ErrorData | null> {
    const res = await VerifyRequestedEmailAPI(code);
    if (res[1] === null) {
        SetSession(res[0]);
    } else {
        return res[1]
    }

    if (res[0].policies.can_sign_out) {
        ShowDefaultToast("Successfully signed in");
    }

    Events.emit("sign-in");
    return null;
}

export async function SignIn(rq: SignInRequest):Promise<ErrorData | null> {
    const res = await SignInAPI(rq);
    if (res[1] === null) {
        SetSession(res[0]);
    } else {
        return res[1]
    }

    if (res[0].policies.can_sign_out) {
        ShowDefaultToast("Successfully signed in");
    }

    Events.emit("sign-in");
    return null;
}

export async function HiddenSignIn():Promise<ErrorData | null> {
    await LoadSession();

    if (session.policies.can_sign_out) {
        ShowDefaultToast("Successfully signed in");
    }

    Events.emit("sign-in");
    return null;
}

export async function SignOut() {
    const res = await SignOutAPI();
    if (res[1] !== null) {
        console.error("session error: ", res[1].text);
    } else {
        window.location.href = "/"
        SetSession(res[0]);
    }
    ShowDefaultToast("Successfully signed out");
    Events.emit("sign-out");
}

export async function RequestResetPassword(rq: RequestResetPasswordRequest) {
    return await RequestResetPasswordAPI(rq);
}

export async function SetNewPasswordFromReset(rq: SetNewPasswordFromResetRequest) {
    return await SetNewPasswordFromResetAPI(rq);
}

export async function ResendEmailVerification():Promise<ErrorData | null> {
    return await ResendEmailVerificationAPI();
}

export function listenOnSignIn(func:() => void) {
    Events.addListener("sign-in", func)
}

export function unlistenOnSignIn(func:() => void) {
    Events.removeListener("sign-in", func)
}

export function listenOnSignOut(func:() => void) {
    Events.addListener("sign-out", func)
}

export function unlistenOnSignOut(func:() => void) {
    Events.removeListener("sign-out", func)
}

export function listenOnSessionChanges(func:() => void) {
    Events.addListener("session-changes", func)
}

export function unlistenOnSessionChanges(func:() => void) {
    Events.removeListener("session-changes", func)
}

export { session };
