
/* Use lazy loading for huge pages please, just wrap your page into React.Suspense */
/* keep in mind that pages w/ lazy loading may brake page switch animation */
/* maybe we need a flap on page level to which would be use to enable page switch animation */
import LazyLanding from 'src/jsx/landing/Landing.lazy.react';
import LazyPlayground from 'src/jsx/playground/playground.lazy.react';
import Authentication from 'src/jsx/authentication/Authentication.page.react';
import Onboadring from 'src/jsx/onboarding/Onboarding.page.react';
import ProjectInvites from 'src/jsx/onboarding/Invites.page.react';
import ProjectPage from 'src/jsx/projects/Project.page.lazy';
import ProjectLanding from 'src/jsx/projects/pages/Landing.react.lazy';
import ProjectDeadlines from 'src/jsx/projects/pages/Deadlines.lazy.react';
import ProjectBudgets from 'src/jsx/projects/pages/Budgets.lazy.react';
import ProjectReleases from 'src/jsx/projects/pages/Releases.lazy.react';
import ProjectRelease from 'src/jsx/projects/pages/Release.lazy.react';

import ProjectsSearch from 'src/jsx/projects/pages/Search.lazy.react';
import ProjectInvoices from 'src/jsx/projects/pages/Invoices.lazy.react';
import ProjectSettings from 'src/jsx/projects/pages/Settings.lazy.react';
import ProjectTasks from 'src/jsx/projects/pages/Tasks.lazy.react';
import ProjectTask from 'src/jsx/projects/pages/Task.lazy.react';
import ProjectInvoice from 'src/jsx/projects/pages/Invoice.lazy.react';
import ProjectDeadline from 'src/jsx/projects/pages/Deadline.lazy.react';
import ProjectBudget from 'src/jsx/projects/pages/Budget.lazy.react';
import LazyInvoice from 'src/jsx/invoices/pages/Invoice.lazy.react';
import LazyPrivacy from 'src/jsx/privacy/privacy.lazy.react';
import LazyContact from 'src/jsx/contact/Contact.lazy.react';
import ProjectMembers from 'src/jsx/projects/pages/Members.lazy.react';
import Page404React from 'src/jsx/errors/404page.react';
import Pending from 'src/jsx/oauth/Pending.react';
import Worklogs from 'src/jsx/projects/pages/Worklogs.lazy.react';
import { ComponentType } from 'react';
import SafeUpdate from 'packages/helpers/SafeUpdate';
import { matchPath, RedirectProps } from 'react-router-dom';
import * as runtypes from 'runtypes';

export const EmptyMeta = runtypes.Null;

export type PageProps<T = null> = {
    id:                     string,
    childRoutes:            Page[],
    meta:                   {
        is_loading:         boolean
        is_error:           boolean
        payload:            T
        redirect_source?:   string
    },
}

export const pages:Page<any>[] = [];
export const redirects:RedirectProps[] =[];

export const Page404:Page = {id: "404error", path: "", exact: true, Component: Page404React, meta: EmptyMeta};

/* KEEP IN MIND THAT PAGE ADDED JUST HERE W/O Go HANDLER WILL BE SOFT 404 */
/* meta should be used to pass only very important and small amount of data e.g. access error otherwise page loading will be slow */
export const RoutesObject = {
    landing:                 { path: "", exact: true, Component: LazyLanding, meta: EmptyMeta },
    auth:                    { path: ["signin", "join", "verification", "password_reset"], exact: true, Component: Authentication, meta: EmptyMeta },
    projects_onboarding:     { path: ["projects/create", "projects/:guid/setup"], exact: true, Component: Onboadring, meta: EmptyMeta },
    projects_invite_confirm: { path: "projects/:guid/confirm/:token", exact: true, Component: ProjectInvites, meta: EmptyMeta },
    projects_search:         { path: ["projects/search", "projects/:guid/search"], exact: true, Component: ProjectsSearch, meta: EmptyMeta },
    projects_page:           { path: "projects/:guid", exact: false, Component: ProjectPage, meta: EmptyMeta, subRoutes: {
        project_landing:   { path: "", exact: true, Component: ProjectLanding, meta: EmptyMeta},
        project_tasks:     { path: "tasks", exact: true, Component: ProjectTasks, meta: EmptyMeta},
        project_task:      { path: "tasks/:id", exact: true, Component: ProjectTask, meta: EmptyMeta},
        project_deadlines: { path: "deadlines", exact: true, Component: ProjectDeadlines, meta: EmptyMeta},
        project_release:   { path: "releases/:release_guid", exact: true, Component: ProjectRelease, meta: EmptyMeta },
        project_deadline:  { path: "deadlines/:deadline_guid", exact: true, Component: ProjectDeadline, meta: EmptyMeta},
        project_budgets:   { path: "budgets", exact: true, Component: ProjectBudgets, meta: EmptyMeta},
        project_budget:    { path: "budgets/:budget_guid", exact: true, Component: ProjectBudget, meta: EmptyMeta},
        project_releases:  { path: "releases", exact: true, Component: ProjectReleases, meta: EmptyMeta},
        project_invoices:  { path: "invoices", exact: true, Component: ProjectInvoices, meta: EmptyMeta},
        project_invoice:   { path: "invoices/:invoice_guid", exact: true, Component: ProjectInvoice, meta: EmptyMeta},
        project_settings:  { path: "settings", exact: true, Component: ProjectSettings, meta: EmptyMeta},
        project_members:   { path: "members", exact: true, Component: ProjectMembers, meta: EmptyMeta},
        project_worklogs:  { path: "worklog", exact: true, Component: Worklogs, meta: EmptyMeta},
    }},
    playground:    { path: "playground", exact: true, Component: LazyPlayground, meta: EmptyMeta},
    privacy:       { path: "privacy", exact: true, Component: LazyPrivacy, meta: EmptyMeta},
    contact:       { path: "contact", exact: true, Component: LazyContact, meta: EmptyMeta},
    invoices:      { path: "invoices/:guid", exact: true, Component: LazyInvoice, meta: EmptyMeta},
    oauth_pending: { path: "oauth", exact:true, Component: Pending, meta: EmptyMeta},
}

type PageEntry = Omit<Page, "id" | "subRoutes"> & {
    subRoutes?: Record<string, PageEntry>
}

function MakePage(key: PageID, obj:PageEntry):Page {
    let page:Page = {...obj, id: key, subRoutes: []}
    if (obj.subRoutes) {
        page.subRoutes =  Object.entries(obj.subRoutes).map(entry => MakePage(entry[0] as PageID, entry[1]))
    }

    return page
}

const Routes:Page[] = Object.entries(RoutesObject).map(entry => MakePage(entry[0] as PageID, entry[1]))
AddRoutes(Routes);

export type PageID =  "404error" | 
    keyof typeof RoutesObject | 
    keyof typeof RoutesObject.projects_page.subRoutes;

export interface Page<T = null> {
    id:         PageID
    parent_id?: string
    path:       string | string[]
    exact:      boolean
    Component:  ComponentType<PageProps<T>>
    meta:       runtypes.Runtype
    subRoutes?: Page<T>[]
}

export function AddRoutes<T = null>(routes:Page<T>[], parent?:Page<T>) {
    routes.forEach(route => {
        if (parent) {
            route = SafeUpdate(route, {parent_id: {$set: parent.id}})
            route = SafeUpdate(route, {path: {$set: `${parent.path}/${route.path}`}})
        }

        pages.push(route)
        if (route.subRoutes?.length) {
            AddRoutes<T>(route.subRoutes, route)
        }
    })
}

export function AddRedirect(redirect:RedirectProps) {
    redirects.push(redirect);
}

export function FindPage(Pages:Page[], path: string) {
    return Pages.find(page => {
        return matchPath(path, {
            path:   typeof page.path === "string" ? `/${page.path}` : page.path.map(p => `/${p}`),
            exact:  page.exact,
        })
    });
}