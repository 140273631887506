import { useGrid, Response } from 'packages/grid/grid';

export const useSimplePage = ():Response<"header" | "content" | "footer"> => useGrid([   
        ["",        "1fr"],
        ["50px",    "header"],
        ["1fr",     "content"],
        ["60px",    "footer"],
]);

export const useSidebarPage = ():Response<"header" | "content" | "sidebar" | "footer"> => useGrid([
    ["",        "150px",    "1fr"],
    ["50px",    "header",   "header"],
    ["1fr",     "sidebar",  "content"],
    ["60px",    "footer",   "footer"],
], {
    600: [
        ["",        "1fr"],
        ["50px",    "header"],
        ["50px",    "sidebar"],
        ["1fr",     "content"],
        ["60px",    "footer"],
    ]
});

export const DashboardSizes = {
    full:               1500,
    sidebar:            240,
    header:             88,
    contentPadding:     32,
    blockWidth:         280,
    blockMargin:        32,
    blockHeight:        200,
    feedWidth:          400,
    moveFeedAfter:      1610,
    hideSidebarAfter:   1160,
}

export const useDashboard = ():Response<"sidebar" | "header" | "content"> => useGrid([
    ["",                        DashboardSizes.sidebar, "1fr"],
    [DashboardSizes.header,    "sidebar",               "header"],
    ["1fr",                    "sidebar",               "content"],
], {
    [ DashboardSizes.hideSidebarAfter ]: [
        ["",                       "1fr"],
        [DashboardSizes.header,    "header"],
        ["1fr",                    "content"],
    ]
});