import { css, CreateScheet } from "aphrodite";
import LineProgress from "packages/progress/Line.react";
import { Fragment, useEffect, useState } from "react";
import { bindOnPersonal, unbindOnPersonal } from "src/runtime/pubsub/personal";
import { Messages } from "src/runtime/pubsub/personal.list";
import { LineRed } from "src/themes/progress";

export default function SyncingToast({
    guid,
    close,
}:{
    guid:  string,
    close: () => void,
}) {
    const [ CanClose, SetCanClose ] = useState(false)
    const [ Percent, SetPercent ] = useState(0)

    function progressChanged(payload:Parameters<Messages["sync_progress_changed"]>[0]) {
        if (payload.guid === guid) {
            SetPercent(Math.round(payload.processed*100/payload.total))
        }
    }

    useEffect(() => {
        setTimeout(() => {
            SetCanClose(true)
        }, 3000)
    }, [])
    
    useEffect(() => {
        if (Percent >= 100 && CanClose) {
            close()
        }
    }, [ Percent, close, CanClose ])

    useEffect(() => {
        bindOnPersonal("sync_progress_changed", progressChanged);

        return () => {
            unbindOnPersonal("sync_progress_changed", progressChanged);
        }
    });

    return(
        <Fragment>
            <div className={css(Styles.title)}>Tasks syncing...</div>
            <LineProgress {...{
                theme:    LineRed,
                progress: Percent,
                height:   10,
            }}/>
        </Fragment>
    );
}

const Styles = CreateScheet({
    title: {
        marginBottom: 10,
        fontWeight: "bold",
    }
})