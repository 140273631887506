import { css } from "aphrodite";
import { createContext, PropsWithChildren, useContext, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CreateTransitionAnimation, Motion } from "./CreateAnimation";
import SmoothAppear from "./SmoothAppear.react";

type Settings = {
    className?:     string
    duration:       number | { enter?: number; exit?: number }
    motion?:        Motion
    grow?:          boolean
}

const context = createContext<Settings>({
    duration:   300,
    motion:     ["fade"],
});

export function SmoothList({ className, children, ...rest }: PropsWithChildren<Settings>) {
    return (
        <context.Provider {...{ value: rest }}>
            <TransitionGroup className={ className || "" }>
                { children }
            </TransitionGroup>
        </context.Provider>
    )
}

export function SmoothListItem({ children, className, onClick, grow, ...rest }: React.PropsWithChildren<{
    className?: string,
    onClick?:   (e: React.MouseEvent) => void
    in?:        boolean,
    grow?:      boolean,
}>) {
    const ctx = useContext(context);
    const ref = useRef<HTMLDivElement>(null);
    return (
        <SmoothAppear {...{
            height:     ctx.grow ? rest.in ? "auto" : 0 : "auto",
            ignore:     ctx.grow === false || grow === false,
            duration:   typeof ctx.duration === "number" ? ctx.duration : ctx.duration.enter || 0,
        }}>
            <CSSTransition {...{
                nodeRef:        ref,
                timeout:        ctx.duration,
                classNames:     `sl-animation`,
                ...rest,
            }}>
                <div {...{
                    className:  `${css(
                        CreateTransitionAnimation(ctx.motion, {animationDuration: ctx.duration}),
                    )} ${className || ""}`,
                    onClick:    onClick,
                    ref,
                }} >
                    { children}
                </div>
            </CSSTransition>
        </SmoothAppear>
    )
}