import * as r from "runtypes";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { put } from "packages/rest/api";

type ChangePasswordData = {
    old_password: string,
    new_password: string,
}

export default async function ChangePassword(body:ChangePasswordData) {
    const res = await put<true>({ 
        url: "users/passwords", 
        body,
    });
    return CheckResultRuntime(r.Literal(true), res);
}