import { CreateError, ResultWithError } from "packages/errors/errors";
import { get } from "packages/rest/api";
import { history } from "./history";

type ExtraTag = {
    Tag:        string
    Attributes: null
    Content:    string
}

type Response<T> = {
    tags:       ExtraTag[]
    meta:       T
    is_404:     boolean
    redirect:   string
}

export async function GetPageMeta<T>(path:string):Promise<ResultWithError<T>> {
    const response = await get<Response<T>>({
        url: `system/meta${path && path === "/" ? `/` : `/${path}`}`
    });

    if (response[1]) {
        return response;
    } else if (response[0].is_404) {
        return [ null, CreateError(404, "Bad Request") ]
    } else if (response[0].redirect.length > 0) {
        history.push(response[0].redirect);
        return [ null, CreateError(307, "Redirect") ];
    }

    if (response[0]) {
        response[0].tags.forEach(tag => {
            if (tag.Tag === "title") {
                document.title = tag.Content
            }
        });
    }

    return [ response[0].meta, null];
}