import { MakeAnimation } from "packages/aphrodite/aphrodite";

const blur = MakeAnimation({
    '0%': {
        filter:     "blur(2px)",
    },
    '100%': {
        filter:     "blur(0px)",
    }
}, {
    '0%': {
        filter:     "blur(0px)",
    },
    '100%': {
        filter:     "blur(2px)",
    }
});

export default blur;