import { ErrorData } from "../errors/errors";
import { post } from "../rest/api";

export type RequestResetPasswordRequest = {
    email:      string
}

export async function RequestResetPasswordAPI(body:RequestResetPasswordRequest):Promise<ErrorData | null> {
    const res = await post({url: "session/passwords", body });
    return res[1];
}