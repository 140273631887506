import { css, CreateScheetWithTheme, useTheme } from 'aphrodite';
import { Fragment, ReactNode } from 'react';
import { Theme, Layout, InternalTheme} from './dropdownSelect.model';
import { ReactComponent as ArrowIcon } from 'src/icons/down-arrow.svg';
import DropdownBase from './dropdownBase.react';
export type { Theme, Layout };

export default function Dropdown({
    layout,
    theme,
    active,
    header,
    disabled,
    items,
    onSelect,
}: {
    layout:       Layout,
    theme:        Theme,
    active:       number,
    header?:      ReactNode,
    disabled?:    boolean,
    items?:       ReactNode[],
    onSelect?:    (index:number) => void,
}) {
    return (
        <DropdownBase {...{
            layout: layout,
            disabled,
            animation: "fade",
            header: () => {
                return <Header {...{
                    header,
                    layout,
                    theme,
                    items,
                    active,
                }}/>
            },
            dropElement: (functions) => {
                return <DropElement {...{
                    items,
                    layout,
                    theme,
                    onSelect,
                    toggleDropElement: functions.toggleDropElement,
                }}/>
            }
        }}/>
    );
}

function Header({ 
    header,
    layout,
    theme,
    items,
    active,
}:{
    header?: ReactNode,
    layout:  Layout,
    theme:   Theme,
    items?:   ReactNode[],
    active:  number,
}) {
    const Styles = useTheme({ styles: theme, layout }, StylesWithTheme);

    return (
        <div className={ css(Styles.header) }>
            {header ? header : (
                <Fragment>
                    <span className={ css(Styles.header_elm) }>
                        {items ? items[active] : null}
                    </span>
                    <ArrowIcon width="10" className={css(Styles.arrowDown)}/>
                </Fragment>
            )}
        </div>
    )
}

function DropElement({
    items,
    layout,
    theme,
    onSelect,
    toggleDropElement,
}:{
    items?:            ReactNode[],
    layout:            Layout,
    theme:             Theme,
    onSelect?:         (index:number) => void,
    toggleDropElement: () => void,
}) {
    const Styles = useTheme({ styles: theme, layout }, StylesWithTheme);
    
    const handleItemClick = (index:number) => (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        
        onSelect && onSelect(index);
        toggleDropElement();
    }

    return (
        <ul className={css(Styles.dropElement)}>
            {items ? items.map((item, index) => (
                <li {...{
                    key:       index,
                    className: css(Styles.dropItem),
                    onClick:   handleItemClick(index),
                }}>
                    {item}
                </li>
            )) : null}
        </ul> 
    )
}

const StylesWithTheme = CreateScheetWithTheme((theme?: InternalTheme) => { return {
    header: {
        padding:      theme?.layout.header?.padding ? theme.layout.header.padding : "9px 21px",
        border:       theme?.styles.header.border,
        color:        theme?.styles.header.color,
        background:   theme?.styles.header.background,
        borderRadius: theme?.layout.header?.borderRadius ? theme?.layout.header.borderRadius : 6,
        userSelect:   "none",
        display:      "flex",
        alignItems:   "center",
    },
    header_elm: {
        flexGrow: 1,
    },
    arrowDown: {
        marginLeft: 10,
        fill: theme?.styles.header.arrowIcon.fill,
    },
    dropElement: {
        position:     "absolute",
        top:          0,
        left:         0,
        background:   theme?.styles.dropElement.background,
        boxShadow:    theme?.styles.dropElement.boxShadow,
        borderRadius: theme?.layout.dropElement?.borderRadius ? theme?.layout.header?.borderRadius : 6,
        margin:       theme?.layout.dropElement?.margin ? theme?.layout.dropElement?.margin : 0,
        padding:      theme?.layout.dropElement?.padding ? theme?.layout.dropElement?.padding : 0,
        overflow:     "hidden",
        zIndex:       theme?.layout.dropElement?.zIndex ? theme?.layout.dropElement?.zIndex : 10,
    },
    dropItem: {
        cursor: "pointer",
        padding:  theme?.layout.dropItem?.padding ? theme.layout.dropItem.padding : "9px 21px",
        minWidth: theme?.layout.dropItem?.minWidth ? theme?.layout.dropItem.minWidth : 150,
        background: theme?.styles.dropItem.plain.background,
        color: theme?.styles.dropItem.plain.color,
        ":hover": {
            background: theme?.styles.dropItem.hover.background,
            color: theme?.styles.dropItem.hover.color,
        }
    }
}});