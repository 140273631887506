import { RefCallback, useEffect } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';

export default function useSwipe(onSwiped:(event:SwipeEventData) => void) {
    const { ref } = useSwipeable({
        onSwiped,
    }) as { ref: RefCallback<Document> };
        
    useEffect(() => {
        ref(document);
    });
}