import { DefaultLayout, LightDropdown } from 'src/themes/dropdowns';
import Dropdown from "packages/dropdowns/dropdownSelect.react";
import { session } from 'packages/session/session';
import { CreateScheet, css } from 'packages/aphrodite/aphrodite';
import { Primary } from 'src/themes/colors';

export default function ProjectPicker() {
    return (
        <Dropdown {...{
            layout:   {...DefaultLayout, header: { padding: "0px" } },
            theme:    {...LightDropdown, header: {
                ...LightDropdown.header, border: "none"
            }},
            active:   session.GetActiveProjectIndex(),
            items:    session.extra.projects.map(p => {
                return (
                    <div className={ css(Styles.project) }>
                        { p.project.title }
                    </div>
                )
            }),
            onSelect: (index:number) => {
                if (session.GetActiveProjectIndex() !== index) {
                    session.SetActiveProject(session.extra.projects[index].project.guid, true);    
                }
            }
        }}/>
    )
}

const Styles = CreateScheet({
    project: {
        fontSize:   18,
        fontWeight: 600,
        color:      Primary.default,
    }
});