import { SignInTheme } from "src/jsx/authentication/modules/SignIn.theme";
import { SignUpTheme } from "src/jsx/authentication/modules/SignUp.theme";
import { VerificationTheme } from "src/jsx/authentication/modules/Verification.theme";
import { ResetPasswordTheme } from "src/jsx/authentication/modules/ResetPassword.theme";
import { PrimaryFilled, PrimaryTransparent } from "./button";
import { Primary, Dark } from "./colors";
import { PrimaryInput } from "./input";

export const LightSignIn:SignInTheme = {
    input:          PrimaryInput,
    button:         PrimaryFilled,
    label_color:    Dark.default,
    reset_color:    Primary.default,
}

export const LightSignUp:SignUpTheme = {
    input:          PrimaryInput,
    button:         PrimaryFilled,
    label_color:    Dark.default,
}

export const LightVerification:VerificationTheme = {
    input:          PrimaryInput,
    button:         PrimaryFilled,
    resend_buttom:  PrimaryTransparent,
    label_color:    Dark.default,
}

export const LightResetPassword:ResetPasswordTheme = {
    input:          PrimaryInput,
    button:         PrimaryFilled,
    label_color:    Dark.default,
}