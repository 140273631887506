import { CreateScheet, css } from "aphrodite";
import { DeadlineDeletedNotification } from "packages/feed/deadlines";
import { session } from "packages/session/session";
import NotificationContainer from "../NotificaionContainer.react";

export default function DeadlineDeleted({
    notification
}:{
    notification: DeadlineDeletedNotification
}) {
    const content = notification.content;

    return (
        <NotificationContainer {...{
            notification,
            rest: (
                <span {...{
                    className: css(Styles.titleHref),
                }}>
                    {notification.content.deadline.title}
                </span>
            )
        }}>
            <span>
                {content.user.guid === session.user.guid ? "You" : content.user.name} deleted the deadline
            </span>
        </NotificationContainer>
    )
}

const Styles = CreateScheet({
    titleHref: {
        color:    "black",
        fontSize: 14,
    }
})