import { css, CreateScheet } from "packages/aphrodite/aphrodite";
import Block from "packages/blocks/block.react";
import { DefaultLayout, LightBlock } from "src/themes/block";
import { Dark } from "src/themes/colors";
import { DarkFooter } from "src/themes/footer";
import { DarkLogo } from "src/themes/logo";
import { MaxHalfFullWidth } from "src/themes/sizes";
import PageFooter from "../footers/PageFooter.react";
import Logo from "../logo/Logo.react";

export default function StaticWithBlock({ children }: React.PropsWithChildren<{}>) {
    return (
        <article className={ css(Styles.page) }>
            <aside className={ css(Styles.page_bg) }>
                <svg viewBox='0 0 100 100' preserveAspectRatio='none' width="100%" height="100%">
                    <path d='M 0 100 L 100 40, 100 100' className={ css(Styles.page_bg_path) } />
                    <path d='M -1 101 C 0 70, 100 60, 100 40' className={ css(Styles.page_bg_path) } />
                </svg>
            </aside>
            <section className={ css(Styles.form_outer) }>
                <section className={ css(Styles.form) }>
                    <div className={ css(Styles.logo) }>
                        <Logo theme={ DarkLogo } />
                    </div>
                    <div className={ css(Styles.form_block) } >
                        <Block {...{ theme: LightBlock, layout: DefaultLayout }} className={ css(Styles.block) }>
                            { children }
                        </Block>
                    </div>
                    <footer className={ css(Styles.footer) }>
                        <PageFooter theme={ DarkFooter } />
                    </footer>
                </section>
            </section>
        </article>
    )
}

const Styles = CreateScheet({
    page: {
        color:              Dark.default,
        backgroundColor:    "#f7fafc",
        minHeight:          "100vh",
        overflowX:          "hidden",
        transition:         "padding .1s",
    },
    page_bg: {
        position:           'absolute',
        top:                0,
        bottom:             0,
        width:              "100%",
        zIndex:             0,
        overflow:           "hidden",
    },
    page_bg_path: {
        fill:               "white",
    },
    form_outer: {
        maxWidth:       "90%",
        margin:         "auto",
    },
    form: {
        maxWidth:       MaxHalfFullWidth,
        margin:         "auto",
        position:       "relative",
        paddingTop:     40,
        minHeight:      "100vh",
        display:        "flex",
        flexDirection:  "column",
        '@media (max-width: 600px)': {
            paddingTop:     30,
        }
    },
    block: {
        width:          "100%",
        marginBottom:   20,
        padding:        "48px 48px",
        '@media (max-width: 600px)': {
            padding:    "26px 18px",
        }
    },
    logo: {
        padding:    "0 0 20px 0px",
        transition: "padding .1s",
        '@media (max-width: 600px)': {
            paddingBottom:      20,
        }
    },
    form_block: {
        display:        "flex",
    },
    footer: {
        display:        "flex",
        justifyContent: "center",
        flexGrow:       1,
        alignItems:     "flex-end",
        paddingBottom:  10,
    }
});