import { css } from "packages/aphrodite/aphrodite";
import { SetUpProjectTheme } from "./SetUpProject.theme";
import { Fragment } from "react";
import { useLoadProjectAPI } from "packages/projects/LoadProjectAPI";
import { Purple } from "src/themes/spinner";
import WithSpinner from "packages/spinners/SmartSpinner.react";
import { FormStyles } from "src/themes/form";
import InviteClient from "./InviteClient.react";
import InviteDevelopers from "./InviteDevelopers.react";

export default function SetUpProject({ theme, guid }:{ theme: SetUpProjectTheme, guid: string }) {
    const [ response ] = useLoadProjectAPI(true, guid);

    return (
        <div className={ css(FormStyles.body) }>
            <h1 className={ css(FormStyles.title) }>
                { response?.payload?.policies.can_invite_developer ? "Invite development teams" : "Setup your project" }
            </h1>
            <div {...{
                className:  css(FormStyles.form)
            }}>
                <WithSpinner {...{ theme: Purple, suspense: response.isLoading, grow: true }} >
                    { response.payload && (
                        <Fragment>
                            { response.payload.policies.can_invite_client && (
                                <InviteClient {...{
                                    theme,
                                    guid:   response.payload.project.guid,
                                }} />
                            ) }
                            { response.payload.policies.can_invite_developer && (
                                <InviteDevelopers {...{
                                    theme,
                                    guid:   response.payload.project.guid,
                                }} />
                            ) }
                        </Fragment>
                    ) }
                </WithSpinner>
            </div>
        </div>
    )
}