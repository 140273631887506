import { css, CreateScheetWithTheme, useTheme } from 'aphrodite';
import { session, SignOut } from 'packages/session/session';
import { MakeButton } from 'src/themes/button';
import { PageFooterTheme } from './PageFooter.theme';

export default function PageFooter({ theme }: { theme: PageFooterTheme }) {
    const Styles = useTheme(theme, StylesTheme)

    return (
        <div className={ css(Styles.footer) }>
            <nav className={ css(Styles.nav) }>
                <button className={ css(MakeButton(theme.nav_buttons, { fontSize: 13 })) } >Products</button>
                <button className={ css(MakeButton(theme.nav_buttons, { fontSize: 13 })) } >Company</button>
                { session.policies.can_sign_out && (
                    <button {...{
                        className:  css(MakeButton(theme.nav_buttons, { fontSize: 13 })),
                        onClick:    SignOut,
                    }} >
                        Sign out
                    </button>
                ) }
                <button className={ css(MakeButton(theme.nav_buttons, { fontSize: 13 })) } >8th-byte.com © 2021</button>
            </nav>
        </div>
    );
}

const StylesTheme = CreateScheetWithTheme((theme?: PageFooterTheme) => { return {
    footer: {
        display:    "flex",
        alignItems: "center",
        height:     "100%",
    },
    nav: {
        flexGrow:   1,
    }
}});