import * as r from "runtypes";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { post } from "packages/rest/api";

export async function SynchronizeTasks(project_guid: string) {
    const res = await post<true>({
        url: `projects/${project_guid}/tasks/sync`,
    });

    return CheckResultRuntime(r.Literal(true), res);
}