import { SpinnerTheme } from 'packages/spinners/spinner.theme';
import { Danger, Primary } from './colors';

export const Purple:SpinnerTheme = {
    color:      Primary.default,
    pathColor:  undefined,
}

export const PurpleithPath:SpinnerTheme = {
    color:      Primary.default,
    pathColor:  "rgba(0,0,0,0.1)",
}

export const Red:SpinnerTheme = {
    color:      Danger.default,
    pathColor:  undefined,
}

export const RedWithPath:SpinnerTheme = {
    color:      Danger.default,
    pathColor:  "rgba(0,0,0,0.1)",
}