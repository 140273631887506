import { ToastLayout, ToastTheme, options, ToastContent, ShowToast } from 'packages/toasts/ShowToast';
import { Danger, Dark, Gray, Primary, Secondary, White } from './colors';

const DefaultLayout:ToastLayout = {
    padding:        16,
    borderRadius:   4,
    boxShadow:      "0 2px 20px 0 rgb(34 41 47 / 8%)",
    fontSize:       15,
}

const DefaultToastTheme:ToastTheme = {
    backgroundColor:    Gray.default,
    prgoressColor:      Primary.disabled,
    crossColor:         Secondary.default,
    color:              Dark.default,
}

const WarningDangerTheme:ToastTheme = {
    backgroundColor:    Danger.default,
    prgoressColor:      Danger.active,
    crossColor:         White.default,
    color:              White.default,
}

export function ShowDefaultToast(Content:ToastContent, options?: options) {
    return ShowToast(Content, DefaultToastTheme, DefaultLayout, options)
}

export function ShowWarningToast(Content:ToastContent, options?: options) {
    return ShowToast(Content, WarningDangerTheme, DefaultLayout, options)
}