import { ResultWithError } from "packages/errors/errors";
import { put } from "packages/rest/api";

export type VerifyClientProjectRequest = {
    client_email:   string
    team_name:      string
}

export type VerifyDeveloperProjectRequest = {
    developers:     Array<{
        guid:  string
        email: string
        name:  string
    }>
}


export default async function VerifyProjectAPI(guid: string, body: VerifyClientProjectRequest | VerifyDeveloperProjectRequest):Promise<ResultWithError<null>> {
    const res = await put({ url: `projects/${guid}`, body });
    return res;
}