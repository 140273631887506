import { faCalendarCheck, faChevronLeft, faClock, faCog, faHandHoldingUsd, faReceipt, faRocket, faTasks, faThLarge, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { OpenPage } from "packages/history/history";
import useIsMobileView from "packages/hooks/useIsMobileView";
import { session } from "packages/session/session";
import { Fragment, useEffect, useRef } from "react";
import { PageID } from "src/runtime/pages";
import { bindOnPersonal, unbindOnPersonal } from "src/runtime/pubsub/personal";
import { Messages } from "src/runtime/pubsub/personal.list";
import { Dark, Primary, Danger, White, Gray } from "src/themes/colors";
import ProjectPicker from "./ProjectPicker.react";

export default function Sidebar({
    currentPage,
    closeSidebar,
}:{
    currentPage?:  PageID,
    closeSidebar?: () => void,
}) {
    const list = useRef<HTMLUListElement>(null);
    const ActiveProject = session.GetActiveProject();
    const ActiveProjectGUID = ActiveProject.project.guid;
    const IsMobileView = useIsMobileView()

    function close() {
        if (typeof closeSidebar === "function") {
            closeSidebar()
        }
    }

    const openPage = (path:string) => (e:React.MouseEvent) => {
        OpenPage(path)(e)
        if (IsMobileView) {
            close()
        }
    }

    function onChangeWorklogsCount(payload:Parameters<Messages["disapproved_worklogs_count_change"]>[0]) {
        session.ChangeDisapprovedWorklogsCount(payload.count)
    }

    useEffect(() => {
        const checkListHeight = () => {
            if (list.current) {
                const rect = list.current.getBoundingClientRect();
                list.current.style.height = window.innerHeight - rect.top + 'px';
            }
        }

        checkListHeight();
        window.addEventListener('resize', checkListHeight)
        return () => {
            window.removeEventListener('resize', checkListHeight)
        }
    }, [])

    useEffect(() => {
        bindOnPersonal("disapproved_worklogs_count_change", onChangeWorklogsCount)
        return () => {
            unbindOnPersonal("disapproved_worklogs_count_change", onChangeWorklogsCount)
        }
    }, [ ActiveProject.policies.worklogs.can_view ])

    return (
        <Fragment>
            {IsMobileView && 
                <div className={ css(Styles.closeSidebar) } onClick={close}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </div>
            }
            <div className={ css(Styles.picker) }>
                <ProjectPicker />
            </div>
            <div className={ css(Styles.badge) }>
                { ActiveProject.policies.can_choose_team ? "client" : "developer" }
            </div>
            <ul ref={list} className={css(Styles.list)}>
                <li {...{
                    className: css(Styles.item, currentPage === "project_landing" && Styles.active),
                    onClick:   openPage(`projects/${ActiveProjectGUID}`),
                }}>
                    <FontAwesomeIcon  size="lg" fixedWidth icon={faThLarge} />
                    <div className={css(Styles.label)}>Dashboard</div>
                </li>
                <li {...{
                    className: css(Styles.item, currentPage === "project_tasks" && Styles.active),
                    onClick:   openPage(`projects/${ActiveProjectGUID}/tasks`),
                }}>
                    <FontAwesomeIcon  size="lg" fixedWidth  icon={faTasks} />
                    <div className={css(Styles.label)}>Tasks</div>
                </li>
                {ActiveProject.policies.can_manage_budgets && 
                    <li {...{
                        className: css(Styles.item, currentPage === "project_budgets" && Styles.active),
                        onClick:   openPage(`projects/${ActiveProjectGUID}/budgets`),
                    }}>
                        <FontAwesomeIcon  size="lg" fixedWidth  icon={faHandHoldingUsd} />
                        <div className={css(Styles.label)}>Budgets</div>
                    </li>
                }
                {ActiveProject.policies.worklogs.can_view && 
                    <li {...{
                        className: css(Styles.item, currentPage === "project_worklogs" && Styles.active),
                        onClick:   openPage(`projects/${ActiveProjectGUID}/worklog`),
                    }}>
                        <FontAwesomeIcon  size="lg" fixedWidth  icon={faClock} />
                        <div className={css(Styles.label)}>Worklog</div>
                        {session.extra.disapproved_worklogs_count > 0 &&
                            <div className={css(Styles.count)}>{session.extra.disapproved_worklogs_count}</div>
                        }
                    </li>
                }
                <li {...{
                    className: css(Styles.item, currentPage === "project_deadlines" && Styles.active),
                    onClick:   openPage(`projects/${ActiveProjectGUID}/deadlines`),
                }}>
                    <FontAwesomeIcon  size="lg" fixedWidth  icon={faCalendarCheck} />
                    <div className={css(Styles.label)}>Deadlines</div>
                </li>
                <li {...{
                    className: css(Styles.item, currentPage === "project_releases" && Styles.active),
                    onClick:   openPage(`projects/${ActiveProjectGUID}/releases`),
                }}>
                    <FontAwesomeIcon  size="lg" fixedWidth  icon={faRocket} />
                    <div className={css(Styles.label)}>Releases</div>
                </li>
                <li {...{
                    className: css(Styles.item, currentPage === "project_invoices" && Styles.active),
                    onClick:   openPage(`projects/${ActiveProjectGUID}/invoices`),
                }}>
                    <FontAwesomeIcon  size="lg" fixedWidth  icon={faReceipt} />
                    <div className={css(Styles.label)}>Invoices</div>
                </li>
                <li {...{
                    className: css(Styles.item, currentPage === "project_members" && Styles.active),
                    onClick:   openPage(`projects/${ActiveProjectGUID}/members`),
                }}>
                    <FontAwesomeIcon  size="lg" fixedWidth  icon={faUsers} />
                    <div className={css(Styles.label)}>Members</div>
                </li>
                <li {...{
                    className: css(Styles.item, currentPage === "project_settings" && Styles.active),
                    onClick:   openPage(`projects/${ActiveProjectGUID}/settings`),
                }}>
                    <FontAwesomeIcon  size="lg" fixedWidth  icon={faCog} />
                    <div className={css(Styles.label)}>Settings</div>
                </li>
            </ul>
        </Fragment>
    );
}

const Styles = CreateScheet({
    picker: {
        margin:  "0 15px",
        padding: "0 16px",
        display: "flex",
        alignItems: "center",
    },
    closeSidebar: {
        color:    Dark.default,
        padding:  10,
        cursor:   "pointer",
        position: "absolute",
        right:    5,
        top:      5,
    },
    badge: {
        width:           100,
        height:          24,
        fontSize:        14,
        backgroundColor: `${Primary.active}2e`,
        border:          `1px solid ${Primary.active}`,
        borderRadius:    3,
        color:           Primary.active,
        fontWeight:      500,
        margin:          "30px auto",
        padding:         "0 16px",
        display:         "flex",
        justifyContent:  "center",
        alignItems:      "center",
    },
    count: {
        width: 23,
        height: 23,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 10,
        fontSize: 12,
        lineHeight: "0",
        fontWeight: 800,
        color: White.default,
        backgroundColor: Danger.default,
        borderRadius: "50%",
    },
    list: {
        listStyle:     "none",
        padding:       "0 16px 16px 16px",
        margin:        0,
        overflowX:     "hidden",
        overflowY:     "auto",
        "::-webkit-scrollbar": {
            width: 3
        },
        "::-webkit-scrollbar-track": {
            backgroundColor: Gray.default,
        },
        "::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        },
    },
    item: {
        height:       40,
        display:      "flex",
        alignItems:   "center",
        cursor:       "pointer",
        marginBottom: 10,
        paddingLeft:  24,
        transition:   "color .2s, background-color .2s",
        borderRadius: 4,
        fontWeight:   400,
        fontSize:     15,
        color:        Dark.default,
        ":hover":     {
            backgroundColor: Primary.hover,
            color:           "white",
        },
        ":nth-child(1n) svg": {
            marginRight: 10,
        },
    },
    label: {
        height: 15,
    },
    active: {
        backgroundColor: Primary.active,
        color:           "white",
    }
})