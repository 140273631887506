import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { ProjectWithPolicies, ProjectWithPoliciesRuntime } from "./model";

export async function LoadProjectAPI(guid: string):Promise<ResultWithError<ProjectWithPolicies>> {
    const res = await get({ url: `projects/${guid}` });
    return CheckResultRuntime(ProjectWithPoliciesRuntime, res);
}

export const useLoadProjectAPI = makeAPIHook(LoadProjectAPI);