import * as r from "runtypes";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { put } from "packages/rest/api";

export type ChangeUserData = {
    full_name: string
}

export default async function SaveUserData(body:ChangeUserData) {
    const res = await put<true>({ 
        url: "users", 
        body 
    });
    return CheckResultRuntime(r.Literal(true), res);
}