import { css, CreateScheetWithTheme, useTheme } from "packages/aphrodite/aphrodite";
import { history } from "packages/history/history";
import { LogoTheme } from "./Logo.theme";

export default function Logo({ theme }: { theme: LogoTheme }) {
    const Styles = useTheme(theme, StylesTheme)

    const OpenPage = (url: string) => (e?: React.MouseEvent) => {
        e && e.preventDefault();
        e && e.stopPropagation();

        history.push(`/${url}`);
    }

    return (
        <a {...{
            className:  css(Styles.logo),
            href:       "/",
            onClick:    OpenPage(""),
        }}>
            *8
        </a>
    )
}


const StylesTheme = CreateScheetWithTheme((theme?: LogoTheme) => { return {
    header: {
        display:    "flex",
        alignItems: "center",
        height:     "100%",
    },
    logo: {
        fontFamily:     ['Lexend Mega', "sans-serif"],
        fontSize:       32,
        color:          theme?.color,
        cursor:         "pointer",
        transition:     "color .2s",
        userSelect:     "none",
        textDecoration: "none",
        ":hover":   {
            color:      theme?.hoverColor,
        }
    },
    nav: {
        flexGrow:       1,
        display:        'flex',
        justifyContent: 'flex-end',
        marginRight:    100,
    }
}});