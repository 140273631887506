import { ResultWithError } from "../errors/errors";
import { patch } from "../rest/api";

export type PathSessionData = {
    active_project_guid?:  string
    active_team_id?:       number
    selected_range_start?: number
    selected_range_end?:   number
}

export async function PatchSessionAPI(body:PathSessionData):Promise<ResultWithError<null>> {
    return await patch({url: "session", body });
}