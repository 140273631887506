import { useRouteMatch } from "react-router-dom";
import StaticEmpty from "../layouts/StaticEmpty.react";
import Invites from './Invites.react';
import { LightOnboarding } from 'src/themes/onboarding';

export default function ProjectInvitesPage() {
    const match = useRouteMatch<{ guid: string, token: string }>();
    return (
        <StaticEmpty noHeader={true}>
            <Invites {...match.params} theme={ LightOnboarding } />
        </StaticEmpty>
    )
}