import { lazy, Suspense } from 'react';
import { css, CreateScheet } from 'aphrodite';
import PageWrapper from '../modules/PageWrapper.react';
const Deadline = lazy(() => import("./Deadline.react"));

export default function LazyProjetDeadlinesPage() {
    return (
        <PageWrapper {...{
            contentMinWidth: "auto",
        }}>
            <Suspense {...{
                fallback: (
                    <div className={ css(Styles.fallback) } />
                )
            }}>
                <Deadline />
            </Suspense>
        </PageWrapper>
    )
}

const Styles = CreateScheet({
    fallback: {
        flexGrow: 1,
        display: "flex",
    }
})