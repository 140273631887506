import * as runtypes from 'runtypes';
import CreatePolicy from 'packages/policies/CreatePolicy';
import CreateContentPolicy from 'packages/policies/models';

export enum AccessGroup {
    Admin = 1,
    Member,
    Manager,
}

export const ProjectTeamTimeTrackingSettingsRuntime = runtypes.Record({
    working_hours_per_day: runtypes.Number,
    working_days_per_week: runtypes.Number,
});
export type ProjectTeamTimeTrackingSettings = runtypes.Static<typeof ProjectTeamTimeTrackingSettingsRuntime>;

export const ProjectTeamRuntime = runtypes.Record({
    id: runtypes.Number,
    is_verified: runtypes.Boolean,
    title: runtypes.String,
    is_auto_approve_enabled: runtypes.Boolean,
    color: runtypes.String,
    atlassian_tracking_settings: ProjectTeamTimeTrackingSettingsRuntime,
});

export type ProjectTeam = runtypes.Static<typeof ProjectTeamRuntime>;

export const ProjectRuntime = runtypes.Record({
    id: runtypes.Number,
    guid: runtypes.String,
    title: runtypes.String,
    created_at: runtypes.Number,
    teams: runtypes.Array(ProjectTeamRuntime),
});

export type Project = runtypes.Static<typeof ProjectRuntime>;

export const ProjectPoliciesRuntime = runtypes.Record({
    can_invite_client: runtypes.Boolean,
    can_invite_developer: runtypes.Boolean,
    can_choose_team: runtypes.Boolean,
    can_manage_client_members: runtypes.Boolean,
    can_see_client_members: runtypes.Boolean,
    can_manage_developer_members: runtypes.Boolean,
    can_see_developer_members: runtypes.Boolean,
    can_manage_budgets: runtypes.Boolean,
    can_connect_atlassian: runtypes.Boolean,
    worklog_can_change_time: runtypes.Boolean,
    invoices: CreateContentPolicy('can_add', 'can_edit', 'can_delete', 'can_view'),
    releases: CreateContentPolicy('can_add', 'can_edit', 'can_delete', 'can_view'),
    deadlines: CreateContentPolicy('can_add', 'can_edit', 'can_delete', 'can_view'),
    worklogs: CreateContentPolicy('can_add', 'can_edit', 'can_delete', 'can_view'),
    budgets: CreateContentPolicy('can_add', 'can_edit', 'can_delete', 'can_view'),
    developer_team: CreateContentPolicy('can_add', 'can_edit', 'can_delete', 'can_view'),
});
export type ProjectPolicies = runtypes.Static<typeof ProjectPoliciesRuntime>;

export const ProjectInviteRuntime = runtypes.Record({
    email: runtypes.String,
    title: runtypes.String,
});
export type ProjectInvite = runtypes.Static<typeof ProjectInviteRuntime>;

export const ProjectInvitePoliciesRuntime = CreatePolicy(
    'can_confirm_client_invite',
    'can_confirm_developer_invite',
    'must_verify_user',
    'must_sign_in',
    'can_confirm_invite'
);
export type ProjectInvitePolicies = runtypes.Static<typeof ProjectInvitePoliciesRuntime>;

export const ProjectWithPoliciesRuntime = runtypes.Record({
    project: ProjectRuntime,
    policies: ProjectPoliciesRuntime,
});

export type ProjectWithPolicies = runtypes.Static<typeof ProjectWithPoliciesRuntime>;

export const ProjectDeveloperMemberRuntime = runtypes.Record({
    full_name: runtypes.String,
    role: runtypes.String,
    is_verified: runtypes.Boolean,
    has_account: runtypes.Boolean,
    access_group_id: runtypes.Number,
    email: runtypes.String.Or(runtypes.Null),
    hourly_rate: runtypes.Number,
    hash: runtypes.String,
    third_party_connected: runtypes.Boolean,
    approved_seconds: runtypes.Number,
});

export type ProjectDeveloperMember = Omit<runtypes.Static<typeof ProjectDeveloperMemberRuntime>, 'access_group_id'> & {
    access_group_id: AccessGroup;
};

export const ProjectClientMemberRuntime = runtypes.Record({
    member_id: runtypes.Number,
    full_name: runtypes.String,
    role: runtypes.String,
    is_verified: runtypes.Boolean,
    access_group_id: runtypes.Number,
    email: runtypes.String.Or(runtypes.Null),
});

export type ProjectClientMember = Omit<runtypes.Static<typeof ProjectClientMemberRuntime>, 'access_group_id'> & {
    access_group_id: AccessGroup;
};

export const TasksStatsRuntime = runtypes.Record({
    total: runtypes.Number,
    finished: runtypes.Number,
    in_progress: runtypes.Number,
    not_started: runtypes.Number,
});

export type TasksStats = runtypes.Static<typeof TasksStatsRuntime>;

export const ProjectMetaRuntime = runtypes.Record({
    spendings: runtypes.Record({
        spent: runtypes.Number,
        limit: runtypes.Number,
        time: runtypes.Number,
    }),
    tasks: TasksStatsRuntime,
    members: runtypes.Array(ProjectDeveloperMemberRuntime),
});

export type ProjectMeta = runtypes.Static<typeof ProjectMetaRuntime>;

// CHART
export const SpendingPointRuntime = runtypes.Record({
    date: runtypes.Number,
    value: runtypes.Number,
    original_value: runtypes.Number,
    task_id: runtypes.Number,
    task_title: runtypes.String,
    reporter_name: runtypes.String,
});

export type SpendingPoint = runtypes.Static<typeof SpendingPointRuntime>;

export const ChartDeadlineRuntime = runtypes.Record({
    date: runtypes.Number,
    title: runtypes.String,
});

export const ChartBudgetRuntime = runtypes.Record({
    start: runtypes.Number,
    end: runtypes.Number,
    title: runtypes.String,
    value: runtypes.Number,
    color: runtypes.String,
});

export const ProjectChartRuntime = runtypes.Record({
    spendings: runtypes.Array(SpendingPointRuntime),
    deadlines: runtypes.Array(ChartDeadlineRuntime),
    budgets: runtypes.Array(ChartBudgetRuntime),
});

export type ProjectChart = runtypes.Static<typeof ProjectChartRuntime>;
