import * as r from "runtypes";
import { NotificationBaseRuntype } from "./models";
import { UserObjectRuntype } from "./users";

export const DevTeamObectRuntype = r.Record({
    id:    r.Number,
    title: r.String,
})
export type DevTeamObject = r.Static<typeof DevTeamObectRuntype>


export const DevTeamInvitedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("dev_team_invited"),
    content: r.Record({
        team: DevTeamObectRuntype,
        user: UserObjectRuntype,
    }) 
})
export type DevTeamInvitedNotification = r.Static<typeof DevTeamInvitedNotificationRuntype>;


export const DevTeamChangedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("dev_team_changed"),
    content: r.Record({
        team: DevTeamObectRuntype,
        user: UserObjectRuntype,
    }) 
})
export type DevTeamChangedNotification = r.Static<typeof DevTeamChangedNotificationRuntype>;