import * as r from "runtypes";
import { ClientMemberAddedNotificationRuntype } from "./client_members";
import { DeadlineChangedNotificationRuntype, DeadlineCreatedNotificationRuntype, DeadlineDeletedNotificationRuntype } from "./deadlines";
import { ReleaseChangedNotificationRuntype, ReleaseCreatedNotificationRuntype, ReleaseDeletedNotificationRuntype } from "./releases";
import { InvoiceChangedNotificationRuntype, InvoiceCreatedNotificationRuntype, InvoiceDeletedNotificationRuntype } from "./invoices";
import { BudgetChangedNotificationRuntype, BudgetCreatedNotificationRuntype, BudgetDeletedNotificationRuntype } from "./budgets";
import { DeveloperMemberChangedNotificationRuntype } from "./developer_members";
import { WorklogApprovedNotificationRuntype, WorklogSubmittedNotificationRuntype } from "./worklogs";
import { DevTeamChangedNotificationRuntype, DevTeamInvitedNotificationRuntype } from "./dev_teams";

export const NotificationRuntype = r.Union(
    // deadlines
    DeadlineCreatedNotificationRuntype,
    DeadlineChangedNotificationRuntype,
    DeadlineDeletedNotificationRuntype,
    // releases
    ReleaseCreatedNotificationRuntype,
    ReleaseChangedNotificationRuntype,
    ReleaseDeletedNotificationRuntype,
    // invoices
    InvoiceCreatedNotificationRuntype,
    InvoiceChangedNotificationRuntype,
    InvoiceDeletedNotificationRuntype,
    // budgets
    BudgetCreatedNotificationRuntype,
    BudgetChangedNotificationRuntype,
    BudgetDeletedNotificationRuntype,
    // dev teams
    DevTeamInvitedNotificationRuntype,
    DevTeamChangedNotificationRuntype,
    // clients
    ClientMemberAddedNotificationRuntype,
    // developers
    DeveloperMemberChangedNotificationRuntype,
    // worklogs
    WorklogSubmittedNotificationRuntype,
    WorklogApprovedNotificationRuntype,
)

export type Notification = r.Static<typeof NotificationRuntype>