import moment from 'moment';
import { CreateScheet, css, StyleDeclarationValue } from 'packages/aphrodite/aphrodite';
import useOutsideClick from 'packages/hooks/useOutsideClick';
import { CreateTransitionAnimation } from 'packages/motion/CreateAnimation';
import { PropsWithChildren, ReactNode, useRef, useState } from 'react';
import { DateRangePicker, Range, RangeFocus, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { CSSTransition } from 'react-transition-group';
import { DefinedRanges } from './DefinedRanges';

export default function DatesPicker({
    startDate,
    endDate,
    onApply,
    children,
    ApplyElement,
    dropElementClass,
}:PropsWithChildren<{
    onApply?:          (start: moment.Moment, end: moment.Moment) => void,
    startDate?:        moment.Moment,
    endDate?:          moment.Moment,
    ApplyElement?:     ReactNode,
    dropElementClass?: StyleDeclarationValue,
}>) {
    const [ Start, SetStart ] = useState(startDate || moment());
    const [ End, SetEnd ] = useState(endDate || moment());
    const [ Show, SetShow ] = useState(false);
    const [ RangeFocus, SetRangeFocus ] = useState<RangeFocus>([0, 0]);
    const dropRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    function OnChange(value: RangeKeyDict) {
        let range:Range = {};
        if ('selection' in value) {
            range = value.selection
        } else if ('startDate' in value) {
            range = value;
        }

        if (range) {
            SetStart(moment(range.startDate));
            SetEnd(moment(range.endDate));
        }
    }

    function OnRangeFocusChange(rf: RangeFocus) {
        SetRangeFocus(rf);
    }

    function ApplyRange() {
        SetShow(false);
        onApply && onApply(Start, End);
    }

    useOutsideClick(wrapperRef, () => {
        if (Show) {
            SetShow(false)
        }
    })
    return (
        <div className={ css(Styles.picker) } ref={ wrapperRef }>
            <div className={ css(Styles.dates) } onClick={ () => { SetShow(v => !v) } }>
                { children }
            </div>
            <CSSTransition {...{
                classNames:    `sl-animation`,
                timeout:       300,
                in:            Show,
                mountOnEnter:  true,
                unmountOnExit: true,
                nodeRef:       dropRef,
            }}>
                <div {...{
                    className: `${css(CreateTransitionAnimation(["fade"], {animationDuration: 300}))} ${css(Styles.drop, dropElementClass && dropElementClass)}`,
                    ref: dropRef,
                }}>
                    <DateRangePicker {...{
                        onChange:                  OnChange,
                        focusedRange:              RangeFocus,
                        onRangeFocusChange:        OnRangeFocusChange,
                        showSelectionPreview:      true,
                        moveRangeOnFirstSelection: false,
                        showDateDisplay:           false,
                        inputRanges:               [],
                        staticRanges:              Object.values(DefinedRanges),
                        months:                    1,
                        ranges:                    [{
                            startDate: Start.toDate(),
                            endDate:   End.toDate(),
                            key:       "selection",
                        }],
                        direction:                 "horizontal",
                    }} />
                    <footer className={ css(Styles.footer) }>
                        <div className={ css(Styles.apply) } onClick={ ApplyRange }>
                            { ApplyElement ? ApplyElement : <button>apply</button> }
                        </div>
                    </footer>
                </div>
            </CSSTransition>
        </div>
    )
}

const Styles = CreateScheet({
    picker: {
        position: "relative",
        "& .rdrDateRangePickerWrapper": {
            flexDirection: "column-reverse",
            "@media (max-width: 430px)": {
                width: 300,
            },
        },
        "& .rdrInputRanges": {
            padding: 0,
        },
        "& .rdrDefinedRangesWrapper": {
            width: "100%",
        },
        "& .rdrStaticRanges": {
            flexDirection: "row",
        },
        "& .rdrStaticRangeLabel": {
            padding: 10,
        },
    },
    dates: {
        userSelect: "none",
        cursor:     "pointer",
    },
    drop: {
        position:  'absolute',
        right:     '0',
        top:       '100%',
        boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
    },
    footer: {
        display:         "flex",
        justifyContent:  "flex-end",
        alignItems:      "flex-end",
        backgroundColor: "white",
        padding:         10,
    },
    apply: {

    }
})