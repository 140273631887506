import { useEffect, useState, useCallback, useRef } from 'react';

type TimeoutFN = (...args: any[]) => void;

/**
 * An async-utility hook that accepts a callback function and a delay time (in milliseconds), then delays the
 * execution of the given function by the defined time.
 */
export function useTimeout(milliseconds: number): [ boolean, () => void, () => void ];
export function useTimeout(fn:TimeoutFN, milliseconds: number): [ boolean, () => void, () => void ];

export default function useTimeout(prmOne: TimeoutFN | number, prmTwo?: number):[ boolean, () => void, () => void ] {
    const fn = typeof prmOne === "function" ? prmOne : undefined;
    const milliseconds = typeof prmOne !== "function" ? prmOne : prmTwo;

    const timeout = useRef<NodeJS.Timeout>();
    const callback = useRef<TimeoutFN | undefined>(fn);
    const [ isCleared, setIsCleared ] = useState(false);

    // the clear method
    const clear = useCallback(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
            setIsCleared(true);
        }
    }, []);

    const start = useCallback(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        setIsCleared(false)
        if (typeof milliseconds === 'number') {
            timeout.current = setTimeout(() => {
                if (typeof callback.current === "function") {
                    callback.current();
                }
                clear();
            }, milliseconds);
        }
    }, [ milliseconds, clear ]);

    // if the provided function changes, change its reference
    useEffect(() => {
        if (typeof fn === 'function') {
            callback.current = fn;
        }
    }, [fn]);

    // when the milliseconds change, reset the timeout
    useEffect(() => {
        start();
        return clear;
    }, [ start, milliseconds, clear ]);

    // when component unmount clear the timeout
    useEffect(() => () => {
        clear();
    }, [ clear ]);

    return [isCleared, start, clear];
};