import {VariableSizeList as List} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import {StyleDeclarationValue} from 'aphrodite';
import {createContext, forwardRef, useCallback, useContext, useEffect, useRef} from 'react';
import {useWindowSize} from 'packages/hooks/useWindowResize';
import {css} from 'aphrodite';
import Spinner from 'packages/spinners/spinner.react';
import {Purple} from 'src/themes/spinner';
import {VirtualizedLayout} from './Virtualized.theme';
import {DefaultLayout} from 'src/themes/virtualized';

const VirtualizedContext = createContext<{
    setSize: (index: number, size: number) => void;
    windowWidth: number;
    layout: VirtualizedLayout;
}>({
    setSize: () => {},
    windowWidth: 0,
    layout: DefaultLayout,
});

export default function Virtualized({
    items,
    className,
    verticalPadding,
    hasMore,
    loadMore,
    isMoreLoading,
    layout,
    hideScrollbar,
}: {
    items: React.ReactNode[];
    className?: StyleDeclarationValue;
    verticalPadding?: number;
    hasMore: boolean;
    isMoreLoading: boolean;
    loadMore: (start: number, stop: number) => Promise<any>;
    layout?: VirtualizedLayout;
    hideScrollbar?: boolean;
}) {
    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = hasMore ? items.length + 1 : items.length;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = isMoreLoading ? async () => {} : loadMore;

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = (index: number) => !hasMore || index < items.length;
    const ListRef = useRef<List | null>();
    const sizeMap = useRef<Record<number, number>>({});
    const infiniteLoaderRef = useRef<InfiniteLoader>(null);

    const setSize = useCallback((index, size) => {
        sizeMap.current = {...sizeMap.current, [index]: size};
        ListRef.current?.resetAfterIndex(index);
        infiniteLoaderRef.current?.resetloadMoreItemsCache();
    }, []);

    const getSize = useCallback(index => {
        return sizeMap.current[index] || 50;
    }, []);

    const [windowWidth] = useWindowSize();

    return (
        <VirtualizedContext.Provider
            value={{
                setSize,
                windowWidth,
                layout: layout || DefaultLayout,
            }}
        >
            <AutoSizer>
                {(size: any) => (
                    <InfiniteLoader
                        {...{
                            isItemLoaded,
                            itemCount,
                            loadMoreItems,
                            ref: infiniteLoaderRef,
                        }}
                    >
                        {({onItemsRendered, ref}) => {
                            return (
                                <List
                                    {...{
                                        className: css(className),
                                        height: size.height || 0,
                                        itemCount: itemCount,
                                        itemSize: getSize,
                                        width: (size.width || 0) + (hideScrollbar ? 40 : 0),
                                        ref: (instance: List) => {
                                            // @ts-ignore
                                            ref(instance);
                                            ListRef.current = instance;
                                        },
                                        onItemsRendered,
                                        innerElementType,
                                    }}
                                >
                                    {({index, style}) => (
                                        <div
                                            {...{
                                                style: {
                                                    ...style,
                                                    top: `${
                                                        parseFloat(style.top?.toString() || '0') +
                                                        (layout?.paddingTop || 0)
                                                    }px`,
                                                    paddingRight: hideScrollbar ? 20 : 0,
                                                },
                                            }}
                                        >
                                            <VirtualizedRow
                                                {...{
                                                    index,
                                                    isLoaded: isItemLoaded(index),
                                                }}
                                            >
                                                {isItemLoaded(index) ? items[index] : null}
                                            </VirtualizedRow>
                                        </div>
                                    )}
                                </List>
                            );
                        }}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        </VirtualizedContext.Provider>
    );
}

const innerElementType = forwardRef(({style, ...rest}: {style: any}, ref: React.Ref<HTMLDivElement>) => {
    const {layout} = useContext(VirtualizedContext);

    return (
        <div
            {...{
                ref,
                style: {
                    ...style,
                    height: `${parseFloat(style.height) + (layout.paddingBottom + layout.paddingTop)}px`,
                },
                ...rest,
            }}
        />
    );
});

function VirtualizedRow({
    children,
    index,
    isLoaded,
}: React.PropsWithChildren<{
    index: number;
    isLoaded: boolean;
}>) {
    const {setSize, windowWidth} = useContext(VirtualizedContext);
    const root = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setSize(index, root.current?.getBoundingClientRect().height || 50);
    }, [windowWidth, index, setSize]);

    return <div ref={root}>{!isLoaded ? <Spinner {...{height: 20, theme: Purple}} /> : children}</div>;
}
