import { css, useTheme, CreateScheetWithTheme } from "packages/aphrodite/aphrodite";
import Block from "packages/blocks/block.react";
import { useForm } from "react-hook-form";
import { LightBlock } from "src/themes/block";
import { LoadButton } from "src/themes/button";
import { MakeInput } from "src/themes/input";
import { CreateProjectTheme } from "./CreateProject.theme";
import { ReactComponent as DevIcon } from 'src/icons/hammer-wrench.svg';
import { ReactComponent as ClientIcon } from 'src/icons/account.svg';
import { CreateData } from "./Onboarding.model";
import { history } from "packages/history/history";
import { useState } from "react";
import CreateProjectAPI from "packages/projects/CreateProjectAPI";
import { FormStyles } from "src/themes/form";
import SmoothLine from "packages/motion/SmoothLine.react";
import { session } from "packages/session/session";

export default function CreateProject({ theme }:{ theme: CreateProjectTheme }) {
    const Styles = useTheme(theme, StylesWithTheme);
    const [ Loading, SetLoading ] = useState(false);

    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, clearErrors } = useForm<CreateData>({
        defaultValues: {
            name:   "",
            type:   undefined,
        }
    });

    const SetType = (type: "dev" | "client") => () => {
        setValue("type", type);
        clearErrors();
    }

    const OnSubmitForm = handleSubmit(async data => {
        if (data.name.length === 0) {
            setError("name", { message: "Please provide a name" })
        } else if (data.type !== "dev" && data.type !== "client") {
            setError("type", { message: "Please choose account type" })
        } else {
            SetLoading(true);
            const res = await CreateProjectAPI({
                title:      data.name,
                is_client:  data.type === "client",
                is_dev:     data.type === "dev",
            })
            SetLoading(false);

            if (res[0] !== null) {
                session.SetActiveProject(res[0].guid, false)
                session.SetActiveTeam(res[0].teams && res[0].teams[0] && res[0].teams[0].id ? res[0].teams[0].id : 0)
                history.push(`/projects/${res[0].guid}/setup`)
            } else {
                setError("type", { message: res[1].text })
            }
        }
    });
    
    return (
        <div className={ css(FormStyles.body) }>
            <h1 className={ css(FormStyles.title) }>Create project</h1>
            <form {...{
                method:     "POST",
                className:  css(FormStyles.form),
                onSubmit:   OnSubmitForm,
            }}>
                <label {...{
                    htmlFor:    "name",
                    className:  css(FormStyles.label, Styles.label),
                }}>
                    Name your project
                    { errors.name && (
                        <span className={ css(FormStyles.label_error) }>
                            { errors.name.message }
                        </span>
                    ) }
                </label>
                <input {...{
                    ...register("name", {
                        required: true,
                    }),
                    id:             "name",
                    type:           "text",
                    autoFocus:      true,
                    autoComplete:   "off",
                    className:      css(MakeInput(theme.input, {
                        fontSize:       20,
                        borderRadius:   6,
                        padding:        "10px 12px",
                        error:          Boolean(errors.name),
                        width:          "100%",
                    }), Styles.input)
                }} />
                <input {...{
                    ...register("type"),
                    type: "hidden",
                }} />
                <SmoothLine {...{
                    className:  css(FormStyles.label, FormStyles.error),
                    motion:     ["fade"],
                }}>
                    { errors.type?.message }
                </SmoothLine>
                <div className={ css(Styles.picker) }>
                    <div className={ css(Styles.picker_devider) } />
                    <Block {...{
                        theme:      LightBlock,
                        layout:     { borderRadius: 10 },
                        className:  css(
                            Styles.picker_item,
                            watch().type === "client" && Styles.picker_item_active,
                        ),
                        onClick:    SetType("client"),
                    }}>
                        <ClientIcon width={ 60 } height={ 60 } />
                        I'm a client
                    </Block>
                    <div className={ css(Styles.picker_devider) } />
                    <Block {...{
                        theme:      LightBlock,
                        layout:     { borderRadius: 10 },
                        className:  css(
                            Styles.picker_item,
                            watch().type === "dev" && Styles.picker_item_active,
                        ),
                        onClick:    SetType("dev"),
                    }}>
                        <DevIcon width={ 60 } height={ 60 } />
                        I'm a contractor
                    </Block>
                    <div className={ css(Styles.picker_devider) } />
                </div>
                <LoadButton {...{
                    type:       "submit",
                    loading:    Loading,
                    className:  css(Styles.submit),
                    theme:      theme.action, 
                    layout:     {
                        width:          "100%",
                        fontSize:       16,
                        borderRadius:   6,
                        padding:        "10px 12px",
                    },
                }}>
                    Continue
                </LoadButton>
            </form>
        </div>
    )
}

const StylesWithTheme = CreateScheetWithTheme((theme?: CreateProjectTheme) => { return {
    label: {
        color:          theme?.label_color,
    },
    input: {
        display:        "block",
    },
    picker: {
        display:        "flex",
        justifyContent: "center",
        margin:         "30px 0",
    },
    picker_devider: {
        flexGrow:       1,
    },
    picker_item: {
        width:          120,
        height:         120,
        display:        'flex',
        flexDirection:  'column',
        justifyContent: 'center',
        alignItems:     'center',
        fontSize:       15,
        fontWeight:     500,
        cursor:         "pointer",
        color:          theme?.label_color,
        transition:     "color .1s, background-color .1s",
        userSelect:     "none",
        ":nth-child(1n) svg": {
            fill:           theme?.label_color,
            display:        "block",
            marginBottom:   5,
        },
        ":hover": {
            color:              theme?.action.plain.color,
            backgroundColor:    theme?.action.plain.background,
            ":nth-child(1n) svg": {
                fill:       theme?.action.plain.color,
            },
        }
    },
    picker_item_active: {
        color:              theme?.action.plain.color,
        backgroundColor:    theme?.action.plain.background,
        ":nth-child(1n) svg": {
            fill:       theme?.action.plain.color,
        },
    },
    submit: {
        display:        "block",
        marginTop:      20,
    }
}})