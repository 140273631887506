import React, { Fragment, useRef, useEffect } from 'react';
import ReactTooltip, { Place, Type, Effect } from 'react-tooltip';
import Portal from 'packages/portal/portal.react';
import './Tooltip.css';
import CreateGUID from 'packages/helpers/CreateGUID';
import { CreateScheetWithTheme, css, useTheme } from 'packages/aphrodite/aphrodite';
import { InternalTheme, TooltipLayout, TooltipTheme } from './Tooltip.theme';

export default function Tooltip({
    theme,
    layout,
    event,
    place,
    color,
    effect,
    children,
    content,
    disabled,
    clickable,
}:React.PropsWithChildren<{
    theme:      TooltipTheme,
    event?:     "hover" | "click"
    layout?:    TooltipLayout,
    effect?:    Effect,
    place?:     Place,
    color?:     Type,
    content:    JSX.Element | string,
    disabled?:  boolean,
    clickable?: boolean,
}>) {
    const Styles = useTheme({ theme, layout }, StylesWithTheme);

    const ID = useRef(CreateGUID());

    const availableTypes = [
        'span',
        'div',
        'svg',
        'button',
        '[object Object]',
    ]

    const childrenWithProps = React.Children.map(children, child => {
        if(React.isValidElement(child)){
            if (!availableTypes.includes(child.type.toString())) {
                throw new Error(`Child shoud be one of elements: ${availableTypes.join(", ")}`);
            }
            return React.cloneElement(child as React.ReactElement<any>, {
                "data-tip": true,
                "data-for": ID.current,
            });
        }
        return child;
    });

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    return (
        <Fragment>
            { childrenWithProps }
            {!disabled && 
                <Portal>
                    <ReactTooltip {...{
                        place,
                        clickable,
                        id:         ID.current,
                        type:       color ? color : "dark",
                        effect:     effect ? effect : "solid",
                        event:      event ? event : undefined,
                        className:  css(Styles.base),
                    }}>
                        { content }
                    </ReactTooltip>
                </Portal>
            }
        </Fragment>
    )
}

const StylesWithTheme = CreateScheetWithTheme((theme?: InternalTheme) => { return {
    base: {
        color:              theme?.theme.color,
        backgroundColor:    theme?.theme.backgroundColor,
        borderColor:        theme?.theme.borderColor,
        boxShadow:          theme?.theme.boxShadow,
        padding:            theme?.layout?.padding,
        borderRadius:       theme?.layout?.borderRadius,
        borderWidth:        theme?.layout?.borderWidth,
        ":after": {
            borderTopColor: theme?.theme.backgroundColor,
        }
    }
}})