import { lazy, Suspense } from 'react';
import { css, CreateScheet } from 'aphrodite';
const Invoice = lazy(() => import("./Invoice.react"));

export default function LazyProjetInvoicePage() {
    return (
        <Suspense {...{
            fallback: (
                <div className={ css(Styles.fallback) } />
            )
        }}>
            <Invoice />
        </Suspense>
    )
}

const Styles = CreateScheet({
    fallback: {
        flexGrow: 1,
        display: "flex",
    }
})