import { CreateScheet, css } from "aphrodite";
import { DevTeamChangedNotification } from "packages/feed/dev_teams";
import { session } from "packages/session/session";
import NotificationContainer from "../NotificaionContainer.react";

export default function DevTeamChanged({
    notification
}:{
    notification: DevTeamChangedNotification
}) {
    const content = notification.content;

    return (
        <NotificationContainer {...{
            notification,
            rest: (
                <span {...{
                    className: css(Styles.titleHref),
                }}>
                    {content.team.title}
                </span>
            )
        }}>
            <span>
                {content.user.guid === session.user.guid ? "You" : content.user.name} changed developers team
            </span>
        </NotificationContainer>
    )
}

const Styles = CreateScheet({
    titleHref: {
        color: "black",
        fontSize: 14,
    }
})