import { CircleProgressTheme } from 'packages/progress/Circle.react';
import { LineProgressTheme } from 'packages/progress/Line.react';
import { Danger, Primary } from './colors';

export const CircleRed:CircleProgressTheme = {
    color: Danger.default
}

export const CircleRedPurple:CircleProgressTheme = {
    color: [{
        offset: 0,
        color:  Primary.default,
    }, {
        offset: 100,
        color:  Danger.default,
    }]
}

export const LineRed:LineProgressTheme = {
    color: Danger.default
}

export const LineRedPurple:LineProgressTheme = {
    color: [{
        offset: 0,
        color:  Primary.default,
    }, {
        offset: 100,
        color:  Danger.default,
    }]
}