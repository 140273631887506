import * as r from "runtypes";
import { NotificationBaseRuntype } from "./models";
import { UserObjectRuntype } from "./users";

export const DeadlineObectRuntype = r.Record({
    id:    r.Number,
    guid:  r.String,
    title: r.String,
})
export type DeadlineObject = r.Static<typeof DeadlineObectRuntype>

export const DeadlineCreatedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("deadline_created"),
    content: r.Record({
        deadline: DeadlineObectRuntype,
        user:     UserObjectRuntype,
    }) 
})
export type DeadlineCreatedNotification = r.Static<typeof DeadlineCreatedNotificationRuntype>;

export const DeadlineChangedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("deadline_changed"),
    content: r.Record({
        deadline: DeadlineObectRuntype,
        user:     UserObjectRuntype,
    }) 
})
export type DeadlineChangedNotification = r.Static<typeof DeadlineChangedNotificationRuntype>;

export const DeadlineDeletedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("deadline_deleted"),
    content: r.Record({
        deadline: DeadlineObectRuntype,
        user:     UserObjectRuntype,
    }) 
})
export type DeadlineDeletedNotification = r.Static<typeof DeadlineDeletedNotificationRuntype>;