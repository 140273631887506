import { css, CreateScheetWithTheme, useTheme } from "aphrodite";
import { LoadButton } from "src/themes/button";
import { MakeInput } from "src/themes/input";
import { useForm } from "react-hook-form";
import { Primary } from "src/themes/colors";
import { Fragment, useState } from "react";
import { RequestResetPassword, SetNewPasswordFromReset } from "packages/session/session";
import { ResetPasswordTheme } from "./ResetPassword.theme";
import { RequestResetPasswordRequest } from "packages/session/RequestResetPasswordAPI";
import ReactCodeInput from "packages/forms/CodeInput.react";
import { DefaultLayout } from "src/themes/code_input";
import { OpenPage } from "packages/history/history";
import { SmoothSteper, SmoothSteperItem } from "packages/motion/SmoothStepper.react";
import { FormStyles } from "src/themes/form";
import SmoothLine from "packages/motion/SmoothLine.react";


export default function ResetPasswordReact({ theme }:{ theme: ResetPasswordTheme }) {
    const [ Email, SetEmail ] = useState("");
    const [ ActiveStep, SetActiveStep ] = useState(0); // 0 - enter email, 1 - enter code and password, 2 - success

    function OnSubmitReqeust(email: string) {
        SetEmail(email);
        SetActiveStep(1);
    }

    function OnSetPassword() {
        SetActiveStep(2);
    }

    return (
        <SmoothSteper {...{ active: ActiveStep, duration: 300, motion: ["fade"] }}>
            <SmoothSteperItem><EnterEmail {...{ theme, OnSubmit: OnSubmitReqeust }} /></SmoothSteperItem>
            <SmoothSteperItem><EnterCodeAndPassword {...{ theme, email: Email, OnSubmit: OnSetPassword }} /></SmoothSteperItem>
            <SmoothSteperItem><Success {...{ theme }} /></SmoothSteperItem>
        </SmoothSteper>
    );
}

function EnterEmail({ theme, OnSubmit }:{ theme: ResetPasswordTheme, OnSubmit: (email: string) => void }) {
    const Styles = useTheme(theme, StylesWithTheme);
    const [ Loading, SetLoading ] = useState(false);
    
    const { register, handleSubmit, watch, setError, formState: { errors } } = useForm<RequestResetPasswordRequest>({ 
        defaultValues: {
            email:      "",
        }
    });
    
    const OnSubmitForm = handleSubmit(async data => {
        SetLoading(true);
        const err = await RequestResetPassword(data);
        SetLoading(false);

        if (err !== null) {
            setError("email", { message: err.text });
        } else {
            OnSubmit(data.email);
        }
    });

    return (
        <form {...{
            method:     "POST",
            className:  css(FormStyles.form),
            onSubmit:   OnSubmitForm,
        }}>
            <label {...{
                htmlFor:    "email",
                className:  css(FormStyles.note, Styles.note),
            }}>
                Enter the email address associated with your account and we'll send you a verification code to reset your password.
            </label>
            <input {...register("email", {
                required: true,
            })} {...{
                id:             "res-email",
                name:           "email",
                type:           "text",
                autoFocus:      true,
                autoComplete:   "off",
                className:      css(MakeInput(theme.input, {
                    fontSize:       16,
                    width:          "100%",
                }), Styles.input)
            }} />
            <SmoothLine className={ css(FormStyles.label, FormStyles.error, Styles.error) }>
                { errors.email?.message }
            </SmoothLine>
            <LoadButton {...{
                type:       "submit",
                disabled:   watch("email").length === 0,
                loading:    Loading,
                className:  css(Styles.submit),
                theme:      theme.button, 
                layout:     {
                    width:          "100%",
                    fontSize:       16,
                    borderRadius:   6,
                    padding:        "10px 12px",
                    disabled:       watch("email").length === 0,
                },
            }}>
                Continue
            </LoadButton>
            <footer className={ css(Styles.footer) }>
                <a {...{
                    className:  css(Styles.footer_link),
                    href:       "/signin",
                    onClick:    OpenPage("signin")
                }}>Return to sign in</a>
            </footer>
        </form>
    );
}


function EnterCodeAndPassword({ theme, email, OnSubmit }:{ theme: ResetPasswordTheme, email: string, OnSubmit: () => void }) {
    const Styles = useTheme(theme, StylesWithTheme);
    const [ Code, SetCode ] = useState("");
    const [ Loading, SetLoading ] = useState(false);

    const { register, handleSubmit, watch, setError, formState: { errors } } = useForm<{ password: string, confirm_password: string }>({ 
        defaultValues: {
            password:           "",
            confirm_password:   "",
        }
    });

    async function OnChangeCode(code: string) {
        SetCode(code);
    }

    const OnSubmitForm = handleSubmit(async data => {
        if (data.password !== data.confirm_password) {
            setError("password", { message: "Passwords do not match" })
        } else {
            SetLoading(true);
            const err = await SetNewPasswordFromReset({ ...data, email, code: Code });
            SetLoading(false);
            if (err !== null) {
                setError("password", { message: err.text });
            } else {
                OnSubmit();
            }
        }
    });

    const isEmpty = Code.length === 0 || watch("password").length === 0 || watch("confirm_password").length === 0;

    return (
        <div {...{
            className:  css(FormStyles.form),
        }}>
            <label {...{
                htmlFor:    "email",
                className:  css(FormStyles.note, Styles.note, Styles.note_center),
            }}>
                verification code has been sent to <strong>{ email }</strong>
            </label>
            <div className={ css(Styles.code) }>
                <ReactCodeInput {...{
                    inputMode:      "numeric",
                    fields:         6,
                    inputLayout:    DefaultLayout,
                    inputTheme:     theme.input,
                    onChange:       OnChangeCode,
                    disabled:       Loading,
                }} />
            </div>
            <form {...{
                method:     "POST",
                className:  css(Styles.passwords),
                onSubmit:   OnSubmitForm,
            }}>
                <label {...{
                    htmlFor:    "email",
                    className:  css(FormStyles.label, Styles.label),
                }}>
                    New password
                </label>
                <input {...{
                    ...register("password", {
                        required: true,
                    }),
                    id:             "res-password",
                    type:           "password",
                    autoComplete:   "off",
                    className:      css(MakeInput(theme.input, {
                        fontSize:       16,
                        width:          "100%",
                    }), Styles.input)
                }} />
                <label {...{
                    htmlFor:    "email",
                    className:  css(FormStyles.label, Styles.label),
                }}>
                    Confirm password
                </label>
                <input {...{
                    ...register("confirm_password", {
                        required: true,
                    }),
                    id:             "confirm_password",
                    type:           "password",
                    autoComplete:   "off",
                    className:      css(MakeInput(theme.input, {
                        fontSize:       16,
                        width:          "100%",
                    }), Styles.input)
                }} />
                <SmoothLine className={ css(FormStyles.label, FormStyles.error) }>
                    { errors.password?.message }
                </SmoothLine>
                <LoadButton {...{
                    type:       "submit",
                    disabled:   isEmpty,
                    loading:    Loading,
                    className:  css(Styles.submit),
                    theme:      theme.button, 
                    layout:     {
                        width:          "100%",
                        fontSize:       16,
                        borderRadius:   6,
                        padding:        "10px 12px",
                        disabled:       isEmpty,
                    },
                }}>
                    Set new password
                </LoadButton>
            </form>
            <footer className={ css(Styles.footer) }>
                <a {...{
                    className:  css(Styles.footer_link),
                    href:       "/signin",
                    onClick:    OpenPage("signin")
                }}>Return to sign in</a>
            </footer>
        </div>
    );
}

export function Success({ theme }:{ theme: ResetPasswordTheme }) {
    const Styles = useTheme(theme, StylesWithTheme);

    return (
        <Fragment>
            <label {...{
                htmlFor:    "email",
                className:  css(FormStyles.note, Styles.note, Styles.success),
            }}>
                Your password has been changed. <a {...{
                    className:  css(Styles.link),
                    href:       "/signin",
                    onClick:    OpenPage("signin")
                }}>
                    Return to sign in
                </a>
            </label>
        </Fragment>
    )
}

const StylesWithTheme = CreateScheetWithTheme((theme?: ResetPasswordTheme) => { return {
    passwords: {
        width:          "100%",
    },
    label: {
        color:          theme?.label_color,
    },
    note: {
        color:          theme?.label_color,
        paddingBottom:  10,
        display:        "block",
    },
    note_center: {
        textAlign:      "center",
    },
    input: {
        display:        "block",
        marginBottom:   20,
    },
    error: {
        paddingTop:     10,
    },
    code: {
        display:        "flex",
        alignItems:     "center",
        justifyContent: "center",
        marginBottom:   20,
    },
    submit: {
        marginTop:      20,
        display:        "block",
    },
    footer: {
        paddingTop:     30,
        fontSize:       14,
        textAlign:      "center",
    },
    footer_link: {
        fontWeight:     500,
        textDecoration: "none",
        color:          Primary.default,
        display:        "block",
        marginTop:      5,
    },
    success: {
        fontSize:       14,
        marginTop:      7,
    },
    link: {
        fontWeight:     500,
        textDecoration: "none",
        color:          Primary.default,
        marginTop:      5,
    }
}})