import { useRouteMatch } from "react-router-dom";
import StaticEmpty from "../layouts/StaticEmpty.react";
import Onboadring from './Onboarding.react';
import { LightOnboarding } from 'src/themes/onboarding';

export default function OnboadringPage() {
    const match = useRouteMatch<{ guid: string }>();
    const path = match.url.split("/");
    return (
        <StaticEmpty noHeader={true}>
            <Onboadring step={ path[path.length - 1] } guid={ match.params.guid } theme={ LightOnboarding } />
        </StaticEmpty>
    )
}