import { CreateScheet, css } from "aphrodite";
import LoadButton from "packages/buttons/LoadButton.react";
import ReactCodeInput from "packages/forms/CodeInput.react";
import { Body, ComponentProps, Footer, Header, Lightbox } from "packages/lightbox/lightbox";
import SmoothLine from "packages/motion/SmoothLine.react";
import { session } from "packages/session/session";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { DarkTransparent, DefaultLayout, MakeButton, PrimaryFilled } from "src/themes/button";
import { DefaultLayout as DefaultCodeInput } from 'src/themes/code_input';
import { Danger, Secondary } from "src/themes/colors";
import { MakeInput, PrimaryInput } from "src/themes/input";
import { ShowDefaultToast } from "src/themes/toasts";
import ChangePassword from "./API/ChangePassword";
import RequestNewEmail from "./API/RequestNewEmail";
import SaveUserData from "./API/SaveUserData";
import VerifyRequestedEmail from "./API/VerifyRequestedEmail";

type MainInputs = {
    full_name: string,
}

type EmailInputs = {
    primary_email: string,
}

type PasswordInputs = {
    old_password: string,
    new_password: string,
}

type Part = "main" | "email" | "password"

export default function ProfileSettings({
    close,
    content,
}:ComponentProps) {
    const [ Part, SetPart ] = useState<Part>("main")

    function ChangePart(part:Part) {
        if (part !== Part) {
            SetPart(part)
        }
    }

    return(
        <Lightbox {...{ close }} styles={Styles.wrapper}>
            { Part === "main" && <MainData {...{ close, ChangePart }}/>}
            { Part === "email" && <EmailChange {...{ close, ChangePart }}/>}
            { Part === "password" && <PasswordChange {...{ close, ChangePart }}/>}
        </Lightbox>
    );
}

function MainData({
    close,
    ChangePart
}:{
    close:      () => void,
    ChangePart: (part:Part) => void,
}) {
    const [ Loading, SetLoading ] = useState(false)
    const [ APIError, SetAPIError ] = useState<string>()
    const { reset, register, handleSubmit, formState: { errors, isValid, dirtyFields } } = useForm<MainInputs>({
        defaultValues: {
            full_name: session.user.full_name,
        }
    })

    async function Save(data:MainInputs) {
        if (isValid && dirtyFields.full_name) {
            SetLoading(true)
            SetAPIError(undefined)
            const res = await SaveUserData(data)
            SetLoading(false)
            if (res[1] !== null) {
                SetAPIError(res[1].text)
            } else {
                session.UpdateUserFields({
                    full_name: data.full_name,
                })
                reset({full_name: data.full_name})
            }
        }
    }

    return(
        <Fragment>
            <Header>Profile settings</Header>
            <Body>
                <form id="profile-settings-form" onSubmit={handleSubmit(Save)}>
                    <div className={css(Styles.row)}>
                        <label className={css(Styles.label)}>Name</label>
                        <input {...{
                            ...register("full_name", {
                                required: true
                            }),
                            className: css(MakeInput(PrimaryInput, {
                                error: !!errors.full_name,
                            }))
                        }}/>
                    </div>
                    <div className={css(Styles.row, Styles.rowMargin)}>
                        <div className={css(Styles.link)} onClick={() => { ChangePart("email") }}>Change email</div>
                    </div>
                    <div className={css(Styles.row)}>
                        <div className={css(Styles.link)} onClick={() => { ChangePart("password") }}>Change password</div>
                    </div>
                    <SmoothLine className={css(Styles.errorLabel)}>
                        {APIError}
                    </SmoothLine>
                </form>
            </Body>
            <Footer styles={Styles.footer}>
                <button {...{
                    className: css(MakeButton(DarkTransparent)),
                    onClick: close,
                }}>
                    Close
                </button>
                <LoadButton {...{
                    theme:   PrimaryFilled,
                    layout:  DefaultLayout,
                    loading: Loading,
                    type:    "submit",
                    form:    "profile-settings-form"
                }}>
                    Save
                </LoadButton>
            </Footer>
        </Fragment>
    )
}

function EmailChange({
    close,
    ChangePart
}:{
    close:      () => void,
    ChangePart: (part:Part) => void,
}) {
    const [ Loading, SetLoading ] = useState(false)
    const [ IsRequested, SetIsRequested ] = useState(false)
    const [ APIError, SetAPIError ] = useState<string>()

    const { reset, register, getValues, handleSubmit, formState: { errors, isValid, dirtyFields } } = useForm<EmailInputs>({
        defaultValues: {
            primary_email: session.user.primary_email || "",
        }
    })

    async function Save(data:EmailInputs) {
        if (isValid && dirtyFields.primary_email) {
            SetLoading(true)
            SetAPIError(undefined)
            const res = await RequestNewEmail(data.primary_email)
            SetLoading(false)
            if (res[1] !== null) {
                SetAPIError(res[1].text)
            } else {
                SetIsRequested(true)
            }
        }
    }

    async function OnChangeCode(code:string) {
        if (code.length === 6) {
            SetAPIError(undefined)
            const res = await VerifyRequestedEmail(code)
            SetLoading(false)
            if (res[1] !== null) {
                SetAPIError(res[1].text)
            } else {
                ShowDefaultToast("Email was successfully changed")
                session.UpdateUserFields({
                    primary_email: getValues().primary_email,
                })
                reset({primary_email: getValues().primary_email})
                SetIsRequested(false)
            }
        }
    }

    return(
        <Fragment>
            <Header>Profile settings</Header>
            <Body>
                <form id="email-change-form" onSubmit={handleSubmit(Save)}>
                    {!IsRequested ?
                        <div className={css(Styles.row)}>
                            <label className={css(Styles.label)}>New email</label>
                            <input {...{
                                ...register("primary_email", {
                                    required: true
                                }),
                                className: css(MakeInput(PrimaryInput, {
                                    error: !!errors.primary_email,
                                }))
                            }}/>
                        </div>
                        :
                        <section className={css(Styles.verification)}>
                            <label className={css(Styles.verificationLabel)}>
                                verification code has been sent to <strong>{ getValues().primary_email }</strong>
                            </label>
                            <ReactCodeInput {...{
                                inputMode:      "numeric",
                                fields:         6,
                                inputLayout:    DefaultCodeInput,
                                inputTheme:     PrimaryInput,
                                onChange:       OnChangeCode,
                                disabled:       Loading,
                            }}/>
                        </section>
                    }
                    <SmoothLine className={css(Styles.errorLabel)}>
                        {APIError}
                    </SmoothLine>
                </form>
            </Body>
            <Footer styles={Styles.footer}>
                <button {...{
                    className: css(MakeButton(DarkTransparent)),
                    onClick: () => { ChangePart("main") },
                }}>
                    Back
                </button>
                <LoadButton {...{
                    theme:   PrimaryFilled,
                    layout:  DefaultLayout,
                    loading: Loading,
                    type:    "submit",
                    form:    "email-change-form"
                }}>
                    {IsRequested ? "Confirm" : "Save"}
                </LoadButton>
            </Footer>
        </Fragment>
    )
}

function PasswordChange({
    close,
    ChangePart
}:{
    close:      () => void,
    ChangePart: (part:Part) => void,
}) {
    const [ Loading, SetLoading ] = useState(false)
    const [ APIError, SetAPIError ] = useState<string>()

    const { register, handleSubmit, formState: { errors, isValid } } = useForm<PasswordInputs>({
        reValidateMode: 'onChange',
        mode: 'onChange',
    })

    async function Save(data:PasswordInputs) {
        if (isValid) {
            SetLoading(true)
            SetAPIError(undefined)
            const res = await ChangePassword(data)
            SetLoading(false)
            if (res[1] !== null) {
                SetAPIError(res[1].text)
            } else {
                ShowDefaultToast("Password was successfully changed")
                ChangePart("main")
            }
        }
    }

    return(
        <Fragment>
            <Header>Profile settings</Header>
            <Body>
                <form id="password-change-form" onSubmit={handleSubmit(Save)}>
                    <div className={css(Styles.row)}>
                        <label className={css(Styles.label)}>Current password</label>
                        <input {...{
                            ...register("old_password", {
                                required: true
                            }),
                            autoComplete: "off",
                            type: "password",
                            className: css(MakeInput(PrimaryInput, {
                                error: !!errors.old_password,
                            }))
                        }}/>
                    </div>
                    <div className={css(Styles.row)}>
                        <label className={css(Styles.label)}>New password</label>
                        <input {...{
                            ...register("new_password", {
                                required: true,
                            }),
                            autoComplete: "off",
                            type: "password",
                            className: css(MakeInput(PrimaryInput, {
                                error: !!errors.new_password,
                            }))
                        }}/>
                    </div>
                    <SmoothLine className={css(Styles.errorLabel)}>
                        {APIError}
                    </SmoothLine>
                </form>
            </Body>
            <Footer styles={Styles.footer}>
                <button {...{
                    className: css(MakeButton(DarkTransparent)),
                    onClick: () => { ChangePart("main") },
                }}>
                    Back
                </button>
                <LoadButton {...{
                    theme:   PrimaryFilled,
                    layout:  DefaultLayout,
                    loading: Loading,
                    type:    "submit",
                    form:    "password-change-form"
                }}>
                    Save
                </LoadButton>
            </Footer>
        </Fragment>
    )
}

const Styles = CreateScheet({
    row: {
        marginBottom: 20,
    },
    wrapper: {
        minHeight: 325,
    },
    label: {
        fontSize:     13,
        color:        Secondary.default,
        marginBottom: 10,
        display:      "block",
    },
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        ":nth-child(1n) button": {
            marginLeft: 10,
        }
    },
    link: {
        fontSize:       13,
        cursor:         "pointer",
        marginTop:      5,
        textDecoration: "underline",
    },
    errorLabel: {
        fontSize: 13,
        color: Danger.default,
        textAlign: "center",
        marginTop: 10,
    },
    verification: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    verificationLabel: {
        fontSize: 13,
        marginBottom: 20,
    },
    rowMargin: {
        marginTop: 50,
    }
})