import { css, CreateScheetWithTheme, useTheme } from "aphrodite";
import { LoadButton } from "src/themes/button";
import { MakeInput } from "src/themes/input";
import { SignInTheme } from './SignIn.theme';
import { useForm } from "react-hook-form";
import { SignInRequest } from "packages/session/SignInAPI";
import { useState } from "react";
import { SignIn } from "packages/session/session";
import { history, OpenPage } from "packages/history/history";
import SmoothLine from "packages/motion/SmoothLine.react";
import { FormStyles } from "src/themes/form";

export default function SignInReact({ theme, email, source_url, OnSignIn }:{ theme: SignInTheme, email?: string, OnSignIn?: () => void, source_url?: string }) {
    const Styles = useTheme(theme, StylesWithTheme);
    const [ Loading, SetLoading ] = useState(false);
    const { register, handleSubmit, setError, formState: { errors } } = useForm<SignInRequest>({ defaultValues: { email: email || "" } });
    
    const OnSubmitForm = handleSubmit(async data => {
        SetLoading(true);
        const err = await SignIn({...data, email: email || data.email});
        SetLoading(false);

        if (err !== null) {
            setError("password", { message: err.text });
        } else {
            if (typeof OnSignIn === "function") {
                OnSignIn()
            } else {
                history.push(source_url || "/");
            }
        }
    });

    return (
        <form {...{
            method:     "POST",
            className:  css(FormStyles.form),
            onSubmit:   OnSubmitForm,
        }}>
            <label {...{
                htmlFor:    "email",
                className:  css(FormStyles.label, Styles.label),
            }}>
                Email
            </label>
            <input {...{
                ...register("email", {
                    required: true
                }),
                id:             "email",
                type:           "text",
                autoFocus:      true,
                required:       true,
                autoComplete:   "off",
                disabled:       Boolean(email && email.length > 0),
                className:      css(MakeInput(theme.input, {
                    fontSize:       16,
                    width:          "100%",
                }), Styles.input)
            }} />
            <label {...{
                htmlFor:    "password",
                className:  css(FormStyles.label, Styles.label),
            }}>
                Password
                <a {...{
                    href:       "/password_reset",
                    className:  css(Styles.link),
                    onClick:    OpenPage("password_reset"),
                }}>
                    Forgot password?
                </a>
            </label>
            <input {...{
                ...register("password", {
                    required: true,
                }),
                id:             "password",
                type:           "password",
                required:       true,
                autoComplete:   "off",
                className:      css(MakeInput(theme.input, {
                    fontSize:       16,
                    width:          "100%",
                    error:          Boolean(errors.password),
                }), Styles.input)
            }} />
            <SmoothLine className={ css(FormStyles.label, FormStyles.error, Styles.error) } motion={[ "fade", "swipe-right" ]}>
                { errors.password?.message }
            </SmoothLine>
            <LoadButton {...{
                type:       "submit",
                loading:    Loading,
                className:  css(Styles.submit),
                theme:      theme.button, 
                layout:     {
                    width:          "100%",
                    fontSize:       16,
                    borderRadius:   6,
                    padding:        "10px 12px",
                },
            }}>
                Sign in
            </LoadButton>
        </form>
    );
}

const StylesWithTheme = CreateScheetWithTheme((theme?: SignInTheme) => { return {
    label: {
        color:          theme?.label_color,
    },
    link: {
        float:          'right',
        textDecoration: 'none',
        color:          theme?.reset_color,
        fontSize:       13,
    },
    error: {
        textAlign:      "center",
    },
    input: {
        display:        "block",
        marginBottom:   20,
    },
    submit: {
        display:        "block",
        marginTop:      20,
    }
}})