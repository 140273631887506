import { Fragment, lazy, Suspense } from 'react';
import { css, CreateScheet } from 'aphrodite';
import PageWrapper from '../modules/PageWrapper.react';
import { PageProps } from 'src/runtime/pages';
import ProjectPage from '../Project.page.react';
const Search = lazy(() => import("./Search.react"));

export default function LazyProjetSearchsPage(props:PageProps) {
    return (
        <Fragment>
            <ProjectPage {...props} {...{ IgnoreEmpty: true }} />
            <PageWrapper {...{
                contentMinWidth: "auto",
            }}>
                <Suspense {...{
                    fallback: (
                        <div className={ css(Styles.fallback) } />
                    )
                }}>
                    <Search />
                </Suspense>
            </PageWrapper>
        </Fragment>
    )
}

const Styles = CreateScheet({
    fallback: {
        flexGrow: 1,
        display: "flex",
    }
})