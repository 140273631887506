import { ErrorData } from "../errors/errors";
import { put } from "../rest/api";

export type SetNewPasswordFromResetRequest = {
    email:              string
    code:               string
    password:           string
    confirm_password:   string
}

export async function SetNewPasswordFromResetAPI(body:SetNewPasswordFromResetRequest):Promise<ErrorData | null> {
    const res = await put({url: "session/passwords", body });
    return res[1];
}