import { CSSProperties, CreateScheet, StyleDeclaration } from 'aphrodite';

export type Template = Array<Array<"empty" | string | number>>;

export type GridStyles = {
    gridRowGap:     CSSProperties["gridRowGap"],
    gridColumnGap:  CSSProperties["gridColumnGap"],
}

export type ResponsiveLayouts<R extends Template> = Record<number, R>;

function isStyles<R extends Template>(object?: ResponsiveLayouts<R> | GridStyles): object is GridStyles {
    return object ? (object as GridStyles).gridRowGap !== undefined : false;
}


export function CreateContainer<R extends Template>(layout: R, prm_one?: ResponsiveLayouts<R> | GridStyles, prm_two?: GridStyles) {
    let styles:GridStyles | undefined = undefined;
    let mediaLaylouts:StyleDeclaration<any> = {};

    if (prm_one && !isStyles(prm_one)) {
        Object.entries(prm_one).forEach(([ maxWidth, _layout ]) => {
            mediaLaylouts[`@media all and (max-width: ${maxWidth}px)`] = getCSSFromLayout(_layout);
        });
    }

    if (prm_two && isStyles(prm_two)) {
        styles = prm_two;
    } else if (isStyles(prm_one)) {
        styles = prm_one;
    }

    return CreateScheet({
        base: {
            ...getCSSFromLayout(layout),
            ...mediaLaylouts,
            display: "grid",
            gridRowGap:             styles ? styles.gridRowGap : undefined,
            gridColumnGap:          styles ? styles.gridColumnGap : undefined,
        },
    }).base;
}

function getCSSFromLayout<R extends Template>(layout: R) {
    let templateRows = "",
        templateColumns = "",
        templateAreas = "";

    layout.forEach((row, i) => {
        let rowLayout = "";
        row.forEach((item, j) => {
            if (i === 0 && j > 0) {
                templateColumns += `${typeof item === "number" ? `${item}px` : item} `
            } else if (i > 0 && j === 0) {
                templateRows += `${typeof item === "number" ? `${item}px` : item} `
            } else if (i > 0 && j > 0) {
                rowLayout += `${typeof item === "number" ? `${item}px` : item} `
            }
        });
        if (rowLayout.length > 0) {
            templateAreas += `"${rowLayout.substring(0, rowLayout.length - 1)}" `;
        }
    });

    return {
        "grid-template-areas":      templateAreas,
        "grid-template-rows":       templateRows,
        "grid-template-columns":    templateColumns,
    };
}


export function SetItem<R extends Template>(marker: string, mediaLaylouts?: ResponsiveLayouts<R>) {
    let mediaLimits:StyleDeclaration<any> = {};
    if (mediaLaylouts && !isStyles(mediaLaylouts)) {
        Object.entries(mediaLaylouts).forEach(([ maxWidth, _layout ]) => {
            if (!_layout.find(elm => elm.find(m => m === marker))) {
                mediaLimits[`@media all and (max-width: ${maxWidth}px)`] = {
                    display:    "none",
                };
            }
        })
    }
    
    return CreateScheet({
        base: {
            gridArea: typeof marker === "string" ? marker : "",
            ...mediaLimits,
        },
    }).base;
}