import { css } from "packages/aphrodite/aphrodite";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { CreateTransitionAnimation, Motion } from "./CreateAnimation";

export default function Animated({ children, motion, timeout, ...rest }: PropsWithChildren<{
    timeout?:       number
    motion?:        Motion,
    className?:     string,
    onClick?:       (e: React.MouseEvent) => void
}>) {
    const ref = useRef<HTMLDivElement>(null);
    const err = useRef(children);
    const [ status, setStatus ] = useState(false);
    const [ , setReload ] = useState(false);

    useEffect(() => {
        if (children !== undefined) {
            if (err.current !== undefined && err.current !== children) {
                err.current = children;
                setReload((re) => !re);
            } else {
                err.current = children;
            }
        }
        setStatus(children !== undefined);
    }, [ children ]);

    return (
        <CSSTransition {...{
            timeout:        timeout || 300,
            in:             status,
            unmountOnExit:  true,
            nodeRef:        ref,
            classNames:     `sl-animation`,
            onExited:       () => {
                err.current = undefined;
            }
        }}>
            <div {...rest} {...{
                ref,
                className:  `${css(CreateTransitionAnimation(motion, {animationDuration: timeout || 300}))} ${rest.className || ""}`
            }}>
                { err.current }
            </div>
        </CSSTransition>
    )
}