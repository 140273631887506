import { CreateScheetWithTheme, css, useTheme } from "packages/aphrodite/aphrodite";
import { CheckEmail } from "packages/helpers/CheckEmail";
import { history } from "packages/history/history";
import VerifyProjectAPI, { VerifyClientProjectRequest } from "packages/projects/VerifyProjectAPI";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { LoadButton } from "src/themes/button";
import { FormStyles } from "src/themes/form";
import { MakeInput } from "src/themes/input";
import { SetUpProjectTheme } from "./SetUpProject.theme";

export default function InviteClient({ theme, guid }:{ theme: SetUpProjectTheme, guid: string }) {
    const Styles = useTheme(theme, StylesWithTheme);

    const [ Loading, SetLoading ] = useState(false);
    const { register, handleSubmit, setError, formState: { errors }} = useForm<VerifyClientProjectRequest>({
        defaultValues: {
            client_email:   "",
            team_name:      "",
        }
    });

    const OnSubmitForm = handleSubmit(async data => {
        if (data.team_name.length === 0) {
            setError("team_name", { message: "Please provide team name" })
        } else if (data.client_email.length === 0) {
            setError("client_email", { message: "Please provide a client email" })
        } else if (!CheckEmail(data.client_email)) {
            setError("client_email", { message: "Client email is not correct" })
        } else {
            SetLoading(true);
            const [, err] = await VerifyProjectAPI(guid, data);
            
            SetLoading(false);
            if (err === null) {
                history.push(`/projects/${guid}`);
            } else if (err !== null) {
                setError("team_name", { message: err.text })
            }
        }
    });

    return (
        <form {...{
            method:     "POST",
            className:  css(FormStyles.form),
            onSubmit:   OnSubmitForm,
        }}>
            <label {...{
                htmlFor:    "name",
                className:  css(FormStyles.label, Styles.label),
            }}>
                Enter team name
                { errors.team_name && (
                    <span className={ css(FormStyles.label_error) }>
                        { errors.team_name.message }
                    </span>
                ) }
            </label>
            <input {...{
                ...register("team_name"),
                id:             "team_name",
                type:           "text",
                autoComplete:   "off",
                className:      css(MakeInput(theme.input, {
                    fontSize:       16,
                    borderRadius:   6,
                    padding:        "8px 12px",
                }), Styles.input)
            }} />
            <hr className={ css(FormStyles.divider) } />
            <label {...{
                htmlFor:    "name",
                className:  css(FormStyles.label, Styles.label),
            }}>
                Invite client
                { errors.client_email && (
                    <span className={ css(FormStyles.label_error) }>
                        { errors.client_email.message }
                    </span>
                ) }
            </label>
            <input {...{
                ...register("client_email"),
                id:             "client_email",
                type:           "text",
                autoComplete:   "off",
                placeholder:    "email address",
                className:      css(MakeInput(theme.input, {
                    fontSize:       16,
                    borderRadius:   6,
                    padding:        "8px 12px",
                }), Styles.input)
            }} />
            <LoadButton {...{
                type:       "submit",
                loading:    Loading,
                className:  css(Styles.submit),
                theme:      theme.action, 
                layout:     {
                    width:          "100%",
                    fontSize:       16,
                    borderRadius:   6,
                    padding:        "10px 12px",
                },
            }}>
                Continue
            </LoadButton>
        </form>
    )
}

const StylesWithTheme = CreateScheetWithTheme((theme?: SetUpProjectTheme) => { return {
    label: {
        color:          theme?.label_color,
    },
    input: {
        display:        "block",
        width:          "100%",
    },
    picker: {
        display:        "flex",
        margin:         "5px 0 30px 0",
    },
    x_devider: {
        flexGrow:       1,
    },
    submit: {
        display:        "block",
        marginTop:      20,
    }
}})