import { CreateScheet, css } from "aphrodite";
import { DeveloperMemberChangedNotification } from "packages/feed/developer_members";
import { AccessGroup } from "packages/projects/model";
import { Fragment } from "react";
import { Primary } from "src/themes/colors";
import NotificationContainer from "../NotificaionContainer.react";

export default function DeveloperMemberChanged({
    notification
}:{
    notification: DeveloperMemberChangedNotification
}) {
    const content = notification.content
    return (
        <NotificationContainer {...{
            notification,
            rest: (
                <Fragment>
                    {content.member.role !== content.old_member.role && 
                        <div className={css(Styles.changesRow)}>
                            <label className={css(Styles.label)}>Role:</label>
                            <span className={css(Styles.value)}>{content.old_member.role} → {content.member.role}</span>
                        </div>
                    }
                    {content.member.access_group_id !== content.old_member.access_group_id && 
                        <div className={css(Styles.changesRow)}>
                            <label className={css(Styles.label)}>Access group:</label>
                            <span className={css(Styles.value)}>{AccessGroup[content.old_member.access_group_id]} → {AccessGroup[content.member.access_group_id]}</span>
                        </div>
                    }
                    {content.member.hourly_rate !== content.old_member.hourly_rate && 
                        <div className={css(Styles.changesRow)}>
                            <label className={css(Styles.label)}>Houly rate:</label>
                            <span className={css(Styles.value)}>${content.old_member.hourly_rate} → ${content.member.hourly_rate}</span>
                        </div>
                    }
                </Fragment>
            )
        }}>
            {content.user.name} changed developer {content.member.name}
        </NotificationContainer>
    )
}

const Styles = CreateScheet({
    label: {
        marginRight: 10,
        fontWeight: 500,
        minWidth: 100,
    },
    changesRow: {
        display: "flex",
        alignItems: "center",
        marginBottom: 5,
    },
    value: {
        padding:         "3px 10px",
        fontSize:        14,
        backgroundColor: `${Primary.active}2e`,
        border:          `1px solid ${Primary.active}`,
        borderRadius:    3,
        color:           Primary.active,
        fontWeight:      500,
        display:         "flex",
        justifyContent:  "center",
        alignItems:      "center",
        textDecoration:  "none",
        transition:      "box-shadow 0.1s ease-in-out",
    }
})