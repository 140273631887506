import * as r from "runtypes";
import { NotificationBaseRuntype } from "./models";
import { UserObjectRuntype } from "./users";

export const ReleaseObectRuntype = r.Record({
    id:          r.Number,
    guid:        r.String,
    title:       r.String,
    description: r.String,
})
export type ReleaseObject = r.Static<typeof ReleaseObectRuntype>


export const ReleaseCreatedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("release_created"),
    content: r.Record({
        release: ReleaseObectRuntype,
        user:    UserObjectRuntype,
    }) 
})
export type ReleaseCreatedNotification = r.Static<typeof ReleaseCreatedNotificationRuntype>;

export const ReleaseChangedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("release_changed"),
    content: r.Record({
        release: ReleaseObectRuntype,
        user:    UserObjectRuntype,
    }) 
})
export type ReleaseChangedNotification = r.Static<typeof ReleaseChangedNotificationRuntype>;

export const ReleaseDeletedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("release_deleted"),
    content: r.Record({
        release: ReleaseObectRuntype,
        user:    UserObjectRuntype,
    }) 
})
export type ReleaseDeletedNotification = r.Static<typeof ReleaseDeletedNotificationRuntype>;
