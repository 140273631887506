import { AccessGroup } from "packages/projects/model";
import * as r from "runtypes";
import { NotificationBaseRuntype } from "./models";
import { UserObjectRuntype } from "./users";

export const DeveloperMemberObjectRuntype = r.Record({
	member_id:       r.Number,
	access_group_id: r.Number,
	role:            r.String,
	is_verified:     r.Boolean,
	name:            r.String,
	hourly_rate:     r.Number,
	user_guid:       r.String,
})
export type DeveloperMemberObject = Omit<r.Static<typeof DeveloperMemberObjectRuntype>, "access_group_id"> & {
    access_group_id: AccessGroup
};


export const DeveloperMemberChangedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("developer_member_changed"),
    content: r.Record({
        old_member: DeveloperMemberObjectRuntype,
        member:     DeveloperMemberObjectRuntype,
        user:       UserObjectRuntype,
    }) 
})
export type DeveloperMemberChangedNotification = r.Static<typeof DeveloperMemberChangedNotificationRuntype>;
