import useTimeout from "packages/hooks/useTimeout";
import { SmoothHeightWithIgnore } from "packages/motion/SmoothHeight.react";
import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import Spinner, { SpinnerProps } from "./spinner.react";
import { SpinnerTheme } from "./spinner.theme";
import { css, CreateScheet, StyleDeclarationValue } from 'aphrodite';

export type { SpinnerTheme };

const duration = 1000;

export default function WithSpinner({ 
    children, 
    grow, 
    suspense, 
    minDuration,
    showContentOnLoading,
    className,
    ...props 
}: PropsWithChildren<SpinnerProps & {
    suspense:               boolean,
    minDuration?:           number,
    grow?:                  boolean,
    showContentOnLoading?:  boolean,
    spinnerHeight?:         number | "100%",
    className?:             StyleDeclarationValue,
}>) {
    const [ IsPassed, start ] = useTimeout(minDuration || duration);
    const [ ShowSpinner, SetShowSpinner ] = useState(suspense);

    useEffect(() => {
        if (suspense && !ShowSpinner) {
            SetShowSpinner(true);
            start();
        } else if (!suspense && ShowSpinner && IsPassed) {
            SetShowSpinner(false);
        }
    }, [ start, IsPassed, ShowSpinner, suspense ] );

    return (
        <Fragment>
            { ShowSpinner && (
                <div className={ css(Styles.spinner, className && className) }>
                    <Spinner {...{ height: 100 }} {...props} />
                </div>
            ) }
            { grow ? (
                <SmoothHeightWithIgnore {...{
                    ignore:     false,
                    status:     true,
                    height:     ShowSpinner ? 0 : "auto",
                }} >
                    { (!ShowSpinner || showContentOnLoading) && children ? children : <div /> }
                </SmoothHeightWithIgnore>
            ) : (
                <Fragment>
                    { (!ShowSpinner || showContentOnLoading) && children ? children : <div /> }
                </Fragment>
            ) }
        </Fragment>
    )
}

const Styles = CreateScheet({
    spinner: {
        height:         200,
        display:        "flex",
        alignItems:     "center",
        justifyContent: "center",
    }
});