import { MakeAnimation } from "packages/aphrodite/aphrodite";

const slideLeft = MakeAnimation({
    '0%': {
        transform:  "translateX(0)"
    },
    '100%': {
        transform:  "translateX(-100%)"
    }
}, {
    '0%': {
        transform:  "translateX(-100%)"
    },
    '100%': {
        transform:  "translateX(0)"
    }
});

export default slideLeft;