import { createBrowserHistory, Location } from 'history';
//import { TrackPageView } from './tracking';
import config from 'src/config.json';
import ReactGA from "react-ga4";
import { Track } from 'src/runtime/pubsub/tracking';
export const history = createBrowserHistory();

const trackGA = (loc: any) => {
    if (config.env === config.envs.production) {
        if (loc.pathname.indexOf('/console') === -1) {
            ReactGA.send({ hitType: "pageview", page: loc.pathname + loc.search});
        }
    }
}

if (config.env === config.envs.production) {
    ReactGA.initialize('G-VZBJ336WZS');
}

trackGA(window.location);

history.listen((location) => {
    OnHistoryChange(location);
});

export function OnHistoryChange(location: Location, init?: boolean) {
    Track("page_views", { path: location.pathname });
    trackGA(location);
}

export const OpenPage = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(`/${path}`);
}