import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { post } from "packages/rest/api";
import { Project, ProjectRuntime } from "./model";

export type CreateProjectRequest = {
    title:      string
    is_client:  boolean
    is_dev:     boolean
}

export default async function CreateProjectAPI(body: CreateProjectRequest):Promise<ResultWithError<Project>> {
    const res = await post({ url: "projects", body });
    return CheckResultRuntime(ProjectRuntime, res);
}