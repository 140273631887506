import * as r from "runtypes";
import { DeveloperMemberObjectRuntype } from "./developer_members";
import { NotificationBaseRuntype } from "./models";
import { TaskObjectRuntype } from "./tasks";

export const WorklogObjectRuntype = r.Record({
    id:           r.Number,
    tracked_time: r.Number,
})

export type WorklogObject = r.Static<typeof WorklogObjectRuntype>

export const WorklogSubmittedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("worklog_submitted"),
    content: r.Record({
        member:  DeveloperMemberObjectRuntype,
        task:    TaskObjectRuntype,
        worklog: WorklogObjectRuntype,
    }) 
})
export type WorklogSubmittedNotification = r.Static<typeof WorklogSubmittedNotificationRuntype>;

export const WorklogApprovedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("worklog_approved"),
    content: r.Record({
        reporter: DeveloperMemberObjectRuntype,
        approver: DeveloperMemberObjectRuntype,
        task:     TaskObjectRuntype,
        worklog:  WorklogObjectRuntype,
    }) 
})
export type WorklogApprovedNotification = r.Static<typeof WorklogApprovedNotificationRuntype>;