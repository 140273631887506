import { ProjectTeamTimeTrackingSettings } from 'packages/projects/model';

// checks if string is a valid duration like 1w 2d 3h 5m 
export function IsValidDuration(str:string):boolean {
    if (str.length === 0) {
        return false
    }

    return /^(?=\d+[ywdhms])(( ?\d+y)?(?!\d))?(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?(( ?\d+h)?(?!\d))?(( ?\d+m)?(?!\d))?(( ?\d+s)?(?!\d))?( ?\d+ms)?$/g.test(str.trim().toLowerCase()) || str === '0'
}

export function StrDurationToSeconds(str:string, settings:ProjectTeamTimeTrackingSettings):number {
    if (!IsValidDuration(str)) {
        return 0
    }

    const regexp = new RegExp(/(\d{1,}w)|(\d{1,}d)|(\d{1,}h)|(\d{1,}m)/g)
    const matches = str.match(regexp)

    let duration = 0;
    matches?.forEach(match => {
        let multiplier = 0;
        switch (match[match.length - 1]) {
            case "y":
                multiplier = 365*24*3600
                break;
            case "w":
                multiplier = settings.working_days_per_week*24*3600
                break;
            case "d":
                multiplier = settings.working_hours_per_day*3600
                break;
            case "h":
                multiplier = 3600
                break;
            case "m":
                multiplier = 60
                break;
            case "s":
                multiplier = 1
                break;
            default:
                break;
        }

        duration += multiplier*parseInt(match)
    })

    return duration;
}

export function SecondsToStrDuration(seconds:number, settings:ProjectTeamTimeTrackingSettings, defaultZero?: boolean):string {
    if (seconds === 0) {
        return defaultZero ? "0" : "N/A"
    }

    const minute = 60;
    const hour   = 60 * minute;
    const day    = settings.working_hours_per_day * hour;
    const week   = settings.working_days_per_week * day;

    const weeksRemainder = seconds%week;
    const weeks = (seconds - weeksRemainder)/week;
    
    const daysRemainder = weeksRemainder%day;
    const days = (weeksRemainder - daysRemainder)/day;
    
    const hoursRemainder = daysRemainder%hour
    const hours = (daysRemainder - hoursRemainder)/hour;
    
    const minutesRemainder = hoursRemainder%60;
    const minutes = (hoursRemainder - minutesRemainder)/60;

    return [
        weeks   > 0 ? weeks+"w":   "",
        days    > 0 ? days+"d":    "",
        hours   > 0 ? hours+"h":   "",
        minutes > 0 ? minutes+"m": "",
    ].filter(s => s.length > 0).join(" ")
}