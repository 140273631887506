import { lazy, Suspense } from 'react';
import { css, CreateScheet } from 'aphrodite';
import PageWrapper from '../modules/PageWrapper.react';
const Budgets = lazy(() => import("./Budgets.react"));

export default function LazyProjetBudgetsPage() {
    return (
        <PageWrapper>
            <Suspense {...{
                fallback: (
                    <div className={ css(Styles.fallback) } />
                )
            }}>
                <Budgets />
            </Suspense>
        </PageWrapper>
    )
}

const Styles = CreateScheet({
    fallback: {
        flexGrow: 1,
        display: "flex",
    }
})