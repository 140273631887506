import { css, CreateScheet } from "packages/aphrodite/aphrodite";
import { BlockTheme, BlockLayout } from './block.theme';

type props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    theme:  BlockTheme,
    layout: BlockLayout,
}

export default function Block({ children, theme, layout, ...rest }: props) {
    return (
        <div {...rest} {...{
            className:  `${css(MakeBlock(theme, layout))} ${rest.className ? rest.className : ""}`
        }}>
            { children }
        </div>
    )
}

export const MakeBlock = (theme: BlockTheme, layout?: BlockLayout) => CreateScheet({
    base: {
        backgroundColor:    theme.backgroundColor,
        boxShadow:          theme.boxShadow,
        borderRadius:       layout?.borderRadius,
    }
}).base;