import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import useIsMobileView from "packages/hooks/useIsMobileView";
import useOutsideClick from "packages/hooks/useOutsideClick";
import useSwipe from "packages/hooks/useSwipe";
import { useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageProps, FindPage } from "src/runtime/pages";
import { DashboardSizes } from "src/themes/layouts";
import FeedSlider, { FeedSliderFunctions } from "./modules/FeedSlider.react";
import Header from "./modules/Header.react";
import Sidebar from "./modules/Sidebar.react";

export default function ProjectPage(props:PageProps & { IgnoreEmpty?: boolean }) {
    const location = useLocation()
    const IsMobileView = useIsMobileView()
    
    const SliderRef = useRef<FeedSliderFunctions>(null)
    const SidebarRef = useRef<HTMLDivElement>(null)

    const [ ShowSidebar, SetShowSidebar ] = useState(!IsMobileView)

    const toggleFeed = useCallback(() => {
        if (SliderRef.current) {
            SliderRef.current.toggle()
        }
    }, [ SliderRef ])

    useOutsideClick(SidebarRef, () => {
        if (IsMobileView && ShowSidebar) {
            SetShowSidebar(false)
        }
    })

    useSwipe((e) => {
        const scrollRight = document.body.scrollWidth - (document.body.scrollLeft + document.body.clientWidth) - 5
        if (e.dir === "Left") {
            if (ShowSidebar && IsMobileView) {
                SetShowSidebar(false)
            } else if (!SliderRef.current?.isOpened() &&  scrollRight<= 0) {
                toggleFeed()
            }
        } else if (e.dir === "Right") {
            if (SliderRef.current?.isOpened()) {
                toggleFeed()
            } else if (!ShowSidebar && IsMobileView  && document.body.scrollLeft === 0) {
                SetShowSidebar(true)
            }
        }
    })

    const Styles = MakeStyles(IsMobileView, ShowSidebar)

    const activePage = FindPage(props.childRoutes, location.pathname)
    if (!activePage && !props.IgnoreEmpty) {
        return null
    }
    return (
        <article className={ css(Styles.page) }>
            <div className={ css(Styles.sidebar) } ref={SidebarRef}>
                <Sidebar {...{
                    currentPage: activePage?.id,
                    closeSidebar: () => { SetShowSidebar(false) }
                }}/>
            </div>
            <div className={ css(Styles.header) }>
                <Header {...{
                    toggleFeed,
                    showSidebar: () => {
                        SetShowSidebar(true)
                    },
                    currentPage: activePage?.id,
                }}/>
            </div>
            <FeedSlider {...{
                ref: SliderRef,
            }}/>
        </article>
    )
}

const MakeStyles = (isMobView:boolean, showSidebar:boolean) => CreateScheet({
    page: {
        overflow: "hidden",
    },
    sidebar: {
        boxShadow:       "2px 0 12px 0 rgba(3,7,16,0.08)",
        padding:         `16px 0 0 0`,
        backgroundColor: "white",
        position:        "fixed",
        left:            0,
        top:             0,
        bottom:          0,
        zIndex:          10,
        width:           DashboardSizes.sidebar,
        transform:       `translateX(${!isMobView || showSidebar ? "0" : "-150%"})`,
        transition:      `transform 0.2s ${!isMobView || showSidebar ? "ease-out" : "ease-in"}`
    },
    header: {
        boxShadow:       "0 2px 8px 0 rgba(3,7,16,0.08)",
        padding:         isMobView ? 24 : "24px 40px",
        backgroundColor: "white",
        position:        "fixed",
        left:            isMobView ? 0 : DashboardSizes.sidebar,
        right:           0,
        top:             0,
        zIndex:          2,
        height:          DashboardSizes.header,
    },
})