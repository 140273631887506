import { CreateScheet, css } from "aphrodite";
import { history } from "packages/history/history";
import { GetQueryParam } from "packages/history/query_string";
import Animated from "packages/motion/Animated.react";
import { session } from "packages/session/session";
import { useEffect, useState } from "react";
import { Primary, White } from "src/themes/colors";
import { MakeInput, TransparentGray } from "src/themes/input";
import { DashboardSizes } from "src/themes/layouts";

export default function SearchInput() {
    const [ SearchKey, SetSearchKey ] = useState("");
    const [ HasFocus, SetHasFocus ] = useState(false);
    const [ Hovered, SetHovered ] = useState<"all" | "this">("this");

    const PerformSearch = (type: "this" | "all") => () => {
        let url = "/projects"
        if (type === "this") {
            url += `/${session.GetActiveProject().project.guid}`
        }

        url += `/search?q=${encodeURIComponent(SearchKey)}`
        history.push(url);
    }

    function OnKeyDown(e: React.KeyboardEvent) {
        if (e.key === "Enter") {
            PerformSearch("this")();
        }
    }

    useEffect(() => {
        const [ query ] = GetQueryParam("q");
        if (typeof query === "string" && query.length > 0) {
            SetSearchKey(query);
        }
    }, []);

    return (
        <div className={ css(Styles.root) }>
            <input {...{
                type:        "text",
                className:   css(MakeInput(TransparentGray, { width: "100%" })),
                placeholder: "Search",
                value:       SearchKey,
                onFocus:     () => { SetHasFocus(true) },
                onBlur:      () => { SetHasFocus(false) },
                onKeyDown:   OnKeyDown,
                onChange:    (e) => {
                    SetSearchKey(e.target.value);
                }
            }}/>
            <Animated motion={["fade", "swipe-down"]} timeout={150} className={css(Styles.picker)}>
                { HasFocus && SearchKey.length > 0 ? (
                    <ul className={ css(Styles.list) }>
                        <li {...{
                            className:    css(Styles.item, Hovered === "this" && Styles.active),
                            onMouseDown:  PerformSearch("this"),
                            onMouseEnter: () => { SetHovered("this") },
                        }}>Search this project</li>
                        <li {...{
                            className:    css(Styles.item, Hovered === "all" && Styles.active),
                            onMouseDown:  PerformSearch("all"),
                            onMouseEnter: () => { SetHovered("all") },
                        }}>Search across all projects</li>
                    </ul>
                ) : undefined } 
            </Animated>
        </div>
    )
}
const Styles = CreateScheet({
    root: {
        position: "relative",
        width:    "100%",
        maxWidth: 300,
    },
    picker: {
        position: "absolute",
        left:     0,
        right:    0,
    },
    list: {
        listStyle:    "none",
        padding:      "10px 0",
        margin:       0,
        background:   White.default,
        boxShadow:    'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
        borderRadius: "0 0 6px 6px",
        minWidth: 250,
    },
    item: {
        padding:  `10px ${DashboardSizes.contentPadding}px`,
        cursor:   "pointer",
        fontSize: 13,
    },
    active: {
        color:           White.default,
        backgroundColor: Primary.default,
    }
})