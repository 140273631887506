import { 
    StyleSheetServer, 
    CSSProperties, 
    CSSPropertiesComplete, 
    StyleDeclarationValue, 
    StyleSheet as OriginalScheet, 
    StyleDeclaration, 
    flushToStyleTag 
} from 'aphrodite-source/lib/index';
import _ from 'lodash';

const { StyleSheet, css } = OriginalScheet.extend([{
    //@ts-ignore
    selectorHandler: (selector, baseSelector, generateSubtreeStyles) => {
        const nestedTags:string[] = [];
        const selectors = selector.split(',');
        _.each(selectors, (subselector, key) => {
            if (selector[0] === '&') {
                const tag = key === 0 ? subselector.slice(1) : subselector;
                const nestedTag = generateSubtreeStyles(`${baseSelector}${tag}`.replace(/ +(?= )/g, ''));
                nestedTags.push(nestedTag);
            }
        });

        return _.isEmpty(nestedTags) ? null : _.flattenDeep(nestedTags);
    }
  }]);
  

type CreateWithThemeFunc<T, V> = (theme?: T) => StyleDeclaration<V>

export function useTheme<T, V>(theme: T | undefined, styles: CreateWithThemeFunc<T, V>) {
    //const ref = useRef(StyleSheet.create(styles(theme)));
    /* here we can check prev and current them and update styles if it will be needed later */
    return StyleSheet.create(styles(theme));
}

export function CreateScheetWithTheme<T, V>(func: CreateWithThemeFunc<T, V>) {
    return func;
}

export function CreateScheet<V>(styles: StyleDeclaration<V>) {
    return StyleSheet.create(styles);
}

export type Animation = {
    enter?: Record<string, CSSProperties>,
    exit?: Record<string, CSSProperties>,
}

export function MakeAnimation(enter?: Record<string, CSSProperties>, exit?:  Record<string, CSSProperties>) {
    return {
        enter,
        exit,
    }
}

export { css, flushToStyleTag, StyleSheetServer };
export type { CSSProperties, CSSPropertiesComplete, StyleDeclarationValue, StyleDeclaration };
