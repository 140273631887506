import { ClientMemberAddedNotification } from "packages/feed/client_members";
import NotificationContainer from "../NotificaionContainer.react";

export default function ClientMemberAdded({
    notification
}:{
    notification: ClientMemberAddedNotification
}) {
    return (
        <NotificationContainer {...{
            notification,
        }}>
            <span>
                New client member added: <b>{notification.content.member.email}</b> by <b>{notification.content.user.name}</b>
            </span>
        </NotificationContainer>
    )
}