import * as r from "runtypes";
import { NotificationBaseRuntype } from "./models";
import { UserObjectRuntype } from "./users";

export const BudgetObectRuntype = r.Record({
    id:          r.Number,
    guid:        r.String,
})
export type BudgetObject = r.Static<typeof BudgetObectRuntype>


export const BudgetCreatedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("budget_created"),
    content: r.Record({
        budget: BudgetObectRuntype,
        user:    UserObjectRuntype,
    }) 
})
export type BudgetCreatedNotification = r.Static<typeof BudgetCreatedNotificationRuntype>;

export const BudgetChangedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("budget_changed"),
    content: r.Record({
        budget: BudgetObectRuntype,
        user:    UserObjectRuntype,
    }) 
})
export type BudgetChangedNotification = r.Static<typeof BudgetChangedNotificationRuntype>;

export const BudgetDeletedNotificationRuntype = NotificationBaseRuntype.extend({
    marker:  r.Literal("budget_deleted"),
    content: r.Record({
        budget: BudgetObectRuntype,
        user:    UserObjectRuntype,
    }) 
})
export type BudgetDeletedNotification = r.Static<typeof BudgetDeletedNotificationRuntype>;
