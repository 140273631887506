import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { Dark, Gray } from "src/themes/colors";
import { ReactComponent as CrossIcon } from 'src/icons/cross.svg';

export default function Cross({ onClick }: { onClick: (e: React.MouseEvent) => void }) {
    return (
        <aside className={ css(Styles.cross) } onClick={ onClick }>
            <CrossIcon width={ 30 } height={ 30 } />
        </aside>
    );
}


const Styles = CreateScheet({
    cross: {
        position:           "fixed",
        top:                20,
        right:              20,
        width:              30,
        height:             30,
        display:            "flex",
        justifyContent:     "center",
        alignItems:         "center",
        borderRadius:       5,
        zIndex:             1,
        cursor:             "pointer",
        transition:         "all 0.1s",
        userSelect:         "none",
        ":nth-child(1n) svg": {
            fill:           Dark.default,
        },
        ":hover": {
            backgroundColor:    Gray.default,
        },
        '@media (max-width: 600px)': {
            top:                10,
            right:              10,
        }
    }
});