import { history } from "packages/history/history";

export default function Page404() {
    function OnClick(event: React.MouseEvent) {
        event.preventDefault();

        history.push("/");
    }

    return (
        <div>
            <h1>404 page</h1>
            <a href="/" onClick={ OnClick }>test</a>
        </div>
    )
}