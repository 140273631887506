import { MakeAnimation } from "packages/aphrodite/aphrodite";

const swipeInUp = MakeAnimation({
    '0%': {
        transform:  "translateY(100%)"
    },
    '100%': {
        transform:  "translateY(0)"
    }
}, {});

export default swipeInUp;