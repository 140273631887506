import { OnboardingTheme } from 'src/jsx/onboarding/Onboarding.react';
import { LightSignIn } from './authentication';
import { PrimaryFilled, PrimaryBordered, DangerTransparent } from "./button";
import { Dark } from "./colors";
import { PrimaryInput } from "./input";

export const LightOnboarding:OnboardingTheme = {
    input:          PrimaryInput,
    add_button:     PrimaryBordered,
    del_button:     DangerTransparent,
    action:         PrimaryFilled,
    label_color:    Dark.default,
    sign_in:        LightSignIn,
}