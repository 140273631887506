import {
    addDays,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
} from 'date-fns';
import { Range, StaticRange } from 'react-date-range';
  
const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
    range: () => ({
        startDate: new Date(),
        endDate: new Date(),
    }),
    isSelected(range:Range) {
        const definedRange:Range = this.range();
        return (
            isSameDay(range.startDate || 0, definedRange.startDate || 0) &&
            isSameDay(range.endDate || 0, definedRange.endDate || 0)
        );
    },
};

function createStaticRange(data:{
    label: string,
    range: () => { startDate: Date, endDate: Date }, 
}):StaticRange {
    return {...staticRangeHandler, ...data}
}

export const DefinedRanges = {
    "this_week": createStaticRange({
        label: 'This Week',
        range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfWeek,
        }),
    }),
    "last_week": createStaticRange({
        label: 'Last Week',
        range: () => ({
            startDate: defineds.startOfLastWeek,
            endDate: defineds.endOfLastWeek,
        }),
    }),
    "month": createStaticRange({
        label: 'This Month',
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth,
        }),
    }),
    "last_month": createStaticRange({
        label: 'Last Month',
        range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth,
        }),
    }),
}