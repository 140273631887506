import { Theme, Layout } from 'packages/dropdowns/dropdownSelect.react';
import { Dark, White, Secondary } from './colors';

export const DefaultLayout:Layout = {
    wrapper: {
        margin: undefined,
    },
    header: {
        padding:      undefined,
        borderRadius: undefined,
    },
    dropElement: {
        borderRadius: undefined,
        margin:       undefined,
        padding:      undefined,
        zIndex:       undefined,
    },
    dropItem: {
        padding:  undefined,
        minWidth: undefined,
    }
}

export const LightDropdown:Theme = {
    header: {
        border:         `1px solid ${Secondary.default}`,
        background:     White.default,
        color:          Dark.default,
        arrowIcon: {
            fill:       Dark.default,
        }
    },
    dropElement: {
        background:     White.default,
        border:         "none",
        boxShadow:      `7px 10px 18px -7px ${Secondary.default}`,
    },
    dropItem: {
        plain: {
            background: White.default,
            color:      Dark.default
        },
        hover: {
            background: "#eaeaea",
            color:      Dark.default,
        }
    },
}

export const DarkDropdown:Theme = {
    header: {
        border:         "none",
        background:     Dark.default,
        color:          White.default,
        arrowIcon: {
            fill:       White.default,
        }
    },
    dropElement: {
        background:     Dark.default,
        border:         "none",
        boxShadow:      `7px 10px 18px -7px ${Secondary.default}`,
    },
    dropItem: {
        plain: {
            background: Dark.default,
            color:      White.default
        },
        hover: {
            background: Dark.hover,
            color:      White.default,
        }
    },
}