import { CodeInputLayout } from "packages/forms/CodeInput.theme";

export const DefaultLayout:CodeInputLayout = {
    borderRadius:   6,
    border:         '1px solid',
    margin:         4,
    paddingLeft:    8,
    paddingRight:   0,
    width:          36,
    height:         42,
    fontSize:       32,
}