import Button, { ButtonTheme, ButtonLayout } from 'packages/buttons/button';
import LoadButtonReact from 'packages/buttons/LoadButton.react';
import { Primary, Danger, Dark, White, Secondary } from './colors';

export const DefaultLayout:ButtonLayout = {
    fontFamily:         "'Roboto', sans-serif",
    fontSize:           14,
    borderRadius:       6,
    padding:            "9px 21px",
    cursor:             "pointer",
    textAlign:          "center",
    transition:         "all .15s ease-in-out",
    fontWeight:         "bolder",
}

export const PrimaryFilled:ButtonTheme = {
    plain: {
        background:         Primary.default,
        color:              "white",
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    hover: {
        background:         undefined,
        color:              undefined,
        borderColor:        undefined,
        boxShadow:          `0 8px 25px -8px ${Primary.hover}`,
    },
    active: {
        background:         undefined,
        color:              undefined,
        borderColor:        undefined,
        boxShadow:          "none",
    }
}

export const PrimaryTransparent:ButtonTheme = {
    plain: {
        background:         "transparent",
        color:              Primary.default,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    hover: {
        background:         undefined,
        color:              Primary.hover,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    active: {
        background:         undefined,
        color:              Primary.active,
        borderColor:        undefined,
        boxShadow:          undefined,
    }
}

export const PrimaryBordered:ButtonTheme = {
    plain: {
        background:         "transparent",
        color:              Primary.default,
        borderColor:        Primary.default,
        boxShadow:          undefined,
    },
    hover: {
        background:         undefined,
        color:              Primary.hover,
        borderColor:        Primary.active,
        boxShadow:          undefined,
    },
    active: {
        background:         undefined,
        color:              Primary.active,
        borderColor:        Primary.active,
        boxShadow:          undefined,
    }
}

export const SecondaryBordered:ButtonTheme = {
    plain: {
        background:         "transparent",
        color:              Secondary.default,
        borderColor:        Secondary.default,
        boxShadow:          undefined,
    },
    hover: {
        background:         undefined,
        color:              Secondary.hover,
        borderColor:        Secondary.active,
        boxShadow:          undefined,
    },
    active: {
        background:         undefined,
        color:              Secondary.active,
        borderColor:        Secondary.active,
        boxShadow:          undefined,
    }
}

export const DangerFilled:ButtonTheme = {
    plain: {
        background:         Danger.default,
        color:              "white",
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    hover: {
        background:         undefined,
        color:              undefined,
        borderColor:        undefined,
        boxShadow:          `0 8px 25px -8px ${Danger.hover}`,
    },
    active: {
        background:         Danger.active,
        color:              undefined,
        borderColor:        undefined,
        boxShadow:          "none",
    }
}

export const DangerTransparent:ButtonTheme = {
    plain: {
        background:         "transparent",
        color:              Danger.default,
        borderColor:        Danger.default,
        boxShadow:          undefined,
    },
    hover: {
        background:         undefined,
        color:              Danger.hover,
        borderColor:        Danger.active,
        boxShadow:          undefined,
    },
    active: {
        background:         undefined,
        color:              Danger.active,
        borderColor:        Danger.active,
        boxShadow:          undefined,
    }
}

export const DangerBordered:ButtonTheme = {
    plain: {
        background:         "transparent",
        color:              Danger.default,
        borderColor:        Danger.default,
        boxShadow:          undefined,
    },
    hover: {
        background:         undefined,
        color:              Danger.hover,
        borderColor:        Danger.active,
        boxShadow:          undefined,
    },
    active: {
        background:         undefined,
        color:              Danger.active,
        borderColor:        Danger.active,
        boxShadow:          undefined,
    }
}

export const WhiteTransparent:ButtonTheme = {
    plain: {
        background:         "transparent",
        color:              White.default,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    hover: {
        background:         undefined,
        color:              `${White.hover}99`,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    active: {
        background:         undefined,
        color:              `${White.active}99`,
        borderColor:        undefined,
        boxShadow:          undefined,
    }
}

export const WhiteFilled:ButtonTheme = {
    plain: {
        background:         White.default,
        color:              Dark.default,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    hover: {
        background:         White.hover,
        color:              `${Dark.default}`,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    active: {
        background:         White.active,
        color:              `${Dark.default}`,
        borderColor:        undefined,
        boxShadow:          undefined,
    }
}

export const DarkTransparent:ButtonTheme = {
    plain: {
        background:         "transparent",
        color:              Dark.default,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    hover: {
        background:         undefined,
        color:              `${Dark.hover}99`,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    active: {
        background:         undefined,
        color:              `${Dark.active}99`,
        borderColor:        undefined,
        boxShadow:          undefined,
    }
}

export const GraySemiFilled:ButtonTheme = {
    plain: {
        background:         `${White.default}19`,
        color:              White.default,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    hover: {
        background:         `${White.default}4C`,
        color:              undefined,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    active: {
        background:         `${White.default}4C`,
        color:              undefined,
        borderColor:        undefined,
        boxShadow:          undefined,
    }
}

export function IsStyleTheme(pet: any | ButtonTheme): pet is ButtonTheme {
    return (pet as ButtonTheme).plain !== undefined;
}

export function MakeButton(theme: ButtonTheme, layout?: ButtonLayout, ) {
    return Button({...DefaultLayout, ...layout}, theme);
} 

type props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    loading:    boolean,
    theme:      ButtonTheme,
    layout?:    ButtonLayout,
}

export function LoadButton(props: props) {
    return <LoadButtonReact {...{
        ...props,
        layout: {...DefaultLayout, ...props.layout}
    }} />
}